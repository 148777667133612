export default {
    "zt": {
      "st_0": "En attente de validation",
      "st_1": "En cours",
      "st_2": "Racheté",
      "st_3": "Échec de la validation",
      "st_4": "Expiré"
    },
    "langName": "Français",
    "common": {
      "error": "Erreur système, veuillez réessayer !",
      "home": "Page d'accueil",
      "detail": "Détails",
      "market": "Marché",
      "trade": "Échanger",
      "options": "Options",
      "wallets": "Portefeuilles",
      "notMore": "Aucun enregistrement supplémentaire",
      "reconnect": "En train de se reconnecter",
      "noData": "Aucun contenu pour le moment",
      "loading": "Chargement...",
      "liveChat": "Chat en ligne",
      "help": "Aide",
      "lang": "Langue",
      "notify": [
        "Veuillez ouvrir ce lien dans votre portefeuille !",
        "Soumission réussie",
        "Vous avez déjà récupéré ce certificat !",
        "Impossible d'autoriser"
      ]
    },
    "header": {
      "voucher": "Obtenir le certificat",
      "connectWallet": "Se connecter",
      "receive": "Recevoir des instructions",
      "receiveInfo": "Vous devez payer des frais de minage pour obtenir le certificat, assurez-vous que votre portefeuille dispose de suffisamment d'ETH pour les frais de minage.",
      "receiveBtn": "Obtenir",
      "reward": "Vous avez gagné une récompense"
    },
    "share": {
      "shareFriend": "Partager avec des amis",
      "info": "Envoyez votre lien d'invitation, vos amis qui rejoignent le noeud via votre lien recevront des récompenses en tokens",
      "myShareLink": "Mon lien de partage",
      "copy": "Copier"
    },
    "ad": {
      "plan": "Épargne planifiée :",
      "regular": "Épargne régulière :"
    },
    "output": {
      "interest": "Intérêt",
      "desc": "Revenu d'intérêt flexible",
      "output": "Sortie",
      "address": "Adresse",
      "quantity": "Quantité"
    },
    "order": {
      "order": "Commande",
      "amount": "Montant",
      "available": "Disponible",
      "all": "Tout",
      "confirm": "Confirmer",
      "period": "Période",
      "requird": "Exigence du montant",
      "rate": "Taux (ETH)",
      "additional": "Récompenses supplémentaires",
      "less": "Pas moins de 1 USDC",
      "participants": "Participants",
      "total": "Montant total (USDC)"
    },
    "chart": {
      "loading": "Chargement",
      "min1": "1 minute",
      "min15": "15 minutes",
      "min5": "5 minutes",
      "min30": "30 minutes",
      "hour1": "1 heure",
      "hour4": "4 heures",
      "day1": "1 jour",
      "week1": "1 semaine",
      "mon1": "1 mois",
      "more": "Plus",
      "price": "Prix",
      "open": "Ouverture",
      "amount": "Quantité",
      "high": "Haut",
      "low": "Bas",
      "buy": "Acheter",
      "sell": "Vendre",
      "buyLong": "Acheter / Long",
      "sellShort": "Vendre / Court",
      "main": "Principal",
      "side": "Secondaire",
      "setting": "Paramètres",
      "history": "Historique"
    },
    "futureOrder": {
      "title": "Confirmer la commande",
      "name": "Nom",
      "direction": "Direction",
      "price": "Prix",
      "info": "Temps de livraison (glissez à gauche pour plus de profit)",
      "amount": "Quantité (USDC)",
      "available": "USDC disponible",
      "fee": "Frais",
      "second": "Seconde",
      "buy": "Acheter",
      "sell": "Vendre",
      "minAount": "Quantité minimale : ${minAmount}-${maxAmount}",
      "startPrice": "Prix de la commande",
      "expectedProfits": "Profits attendus",
      "finish": "Expiration et règlement terminé",
      "currentPrice": "Prix actuel",
      "completeResult": "Résultat terminé",
      "confirmOrder": "Confirmer",
      "continueBuy": "Continuer l'achat"
    },
    "futureHistory": {
      "title": "Historique des commandes",
      "direction": "Direction",
      "amount": "Quantité",
      "buyPrice": "Prix de la commande",
      "OrderPrice": "Prix final",
      "deliveryTime": "Type",
      "pl": "Profit et perte",
      "sellTime": "Temps de fin"
    },
    "market": {
      "currency24h": "Monnaie 24h",
      "volume": "Volume",
      "liquidity": "Liquidité"
    },
    "user": {
      "title": "Félicitations aux nouveaux utilisateurs rejoignant le pool de minage.",
      "nation": "Nationalité de l'utilisateur",
      "address": "Adresse publique",
      "Hashrate": "Taux de hachage du portefeuille"
    },
    "rule": {
      "daily": "Quotidien",
      "interest": "Intérêt",
      "rule": "Règles",
      "flexible": "Flexible",
      "payments": "Les intérêts sont payés toutes les 4 heures, 6 fois par jour.",
      "safety": "Sécurité des actifs",
      "less": "Environ 80% des actifs en USDC sont des obligations d'État américaines à court terme, dont la durée ne dépasse pas 3 mois.",
      "economy": "Ce sont considérés comme l'un des actifs les plus sûrs au monde, soutenus par la « pleine confiance et crédit » du gouvernement des États-Unis, le plus grand acteur économique mondial.",
      "fees": "Frais",
      "additional": "Pas de frais supplémentaires pour le retrait des intérêts."
    },
    "report": {
      "title": "Portefeuille Web3 compatible avec DApp",
      "subTitle": ["Mobile", "Matériel", "Contrat intelligent", "Autre"]
    },  
      "help": {
        "article": [
          {
            "title": "Possédez vos données",
            "details": [
              "En utilisant ce portefeuille cryptographique basé sur la blockchain Ethereum, vous êtes le seul à pouvoir posséder vos données et les stocker localement. Vous pouvez accéder à votre portefeuille via une extension de navigateur ou une application mobile."
            ]
          },
          {
            "title": "Quels sont les avantages du WEB3.0",
            "details": [
              "Web 3.0 repose sur des concepts fondamentaux de décentralisation, d'ouverture et de plus grande utilité pour les utilisateurs.",
              "Dans un système décentralisé, les informations peuvent être récupérées en fonction de leur contenu, ce qui permet de les stocker à plusieurs endroits, rendant ainsi le système décentralisé. Cela va déconstruire les vastes ensembles de données détenus actuellement par les géants de l'Internet, tout en les rendant évolutifs, sécurisés et accessibles."
            ]
          },
          {
            "title": "Qu'est-ce qu'une application décentralisée (dApp)",
            "details": [
              "Les applications décentralisées (dApps) sont des programmes qui fonctionnent sur la blockchain ou sur des nœuds d'un réseau pair-à-pair (P2P), plutôt que de dépendre d'un ordinateur centralisé unique.",
              "Les avantages des dApps incluent une plus grande efficacité financière, car elles peuvent fonctionner sans intermédiaire qui prélève une part des profits des transactions. Les utilisateurs doivent assumer la responsabilité légale et réglementaire de ce qu'ils partagent ou consomment."
            ]
          },
          {
            "title": "Contrats intelligents",
            "details": [
              "Les applications décentralisées (dApps) fonctionnent sur la blockchain ou des nœuds d'un réseau pair-à-pair (P2P), et non sur un ordinateur centralisé.",
              "Les dApps offrent des avantages financiers en permettant une fonctionnement sans intermédiaires, tout en laissant aux utilisateurs la responsabilité légale et réglementaire des contenus qu'ils partagent ou consomment."
            ]
          }
        ],
        "center": "Centre d'aide",
        "hope": "Nous espérons que cela vous sera utile"
      },
      "partner": {
        "title1": "Rapport d'audit",
        "detail1": "Nous avons un rapport d'audit sécurisé",
        "title2": "Partenaires",
        "detail2": "Nos partenaires commerciaux"
      },
      "trading": {
        "titles": ["Volume total des transactions", "Equité non réglée", "Nombre total d'utilisateurs"],
        "currency": "Monnaies populaires",
        "banners": ["Gagner des USDC avec l'épargne de liquidité", "Nos avantages"]
      },
      "poolIndex": {
        "tab1": "Données du pool de fonds",
        "tab2": "Plan",
        "tab3": "Impôts",
        "tab4": "Compte",
        "tab5": "Transfert",
        "transfer": "Transférer USDC du portefeuille vers le solde",
        "button": "Transférer"
      },
      "liquidity": {
        "type": "Type",
        "interest": "Intérêt",
        "period": "Période",
        "amountRequire": "Exigence de montant (USDC)",
        "rate": "Taux (ETH)",
        "additionalReward": "Récompense supplémentaire",
        "days": "Jours",
        "subscription": "Staking",
        "redeem": "Racheter",
        "record": "Enregistrement",
        "participants": "Participants",
        "totalAmount": "Montant total (USDC)",
        "interestRate": "Taux d'intérêt",
        "amount": "Montant (USDC)",
        "collectionAmount": "Montant collecté",
        "reward": "Récompense",
        "addReward": "Récompense supplémentaire",
        "endTime": "Heure de fin",
        "status": "Statut",
        "stakingAmount": "Montant du staking",
        "unCollected": "Montant non collecté"
      },
      "pool": {
        "total": "Données totales de la récompense",
        "participant": "Participant",
        "userRevenue": "Revenu utilisateur",
        "poolData": "Données du pool de fonds"
      },
      "message": {
        "title": "Messages",
        "ok": "OK"
      },
      "lottery": {
        "title": "Boîte surprise USDC",
        "btn": "Choisir au hasard",
        "stake": "Staking",
        "connectWallet": "Connecter le portefeuille",
        "voucher": "Obtenir le certificat",
        "tab1": "Données du pool de fonds",
        "tab2": "Plan",
        "tab3": "Impôts",
        "tab4": "Compte",
        "ok": "OK",
        "lotteryChance": "Félicitations, vous avez une chance de gagner un tirage au sort ETH !",
        "lotteryBtn": "Commencer le tirage au sort",
        "congratulations": "Félicitations",
        "experienceUsdt1": "Félicitations ! Vous avez gagné",
        "experienceUsdt2": "fonds d'expérience USDC. Le taux d'intérêt actuel est",
        "experienceUsdt3": "ETH. Le temps d'expiration est"
      },
      "my": {
        "myAccount": "Mon compte",
        "totalOutput": "Production totale",
        "walletBalance": "Solde du portefeuille",
        "exchangeable": "Échangeable",
        "exchange": "Échanger",
        "withdraw": "Retrait",
        "record": "Enregistrements"
      },
      "exchange": {
        "redeem": "Racheter tout",
        "exchange": "Échanger",
        "convert": "Convertir ETH en USDC"
      },
      "withdraw": {
        "total": "Solde total",
        "confirm": "Confirmer",
        "tip": "Votre retrait sera envoyé à votre adresse USDC dans les 24 heures"
      },
      "record": {
        "time": "Heure",
        "quantity": "Quantité",
        "status": "Statut",
        "output": "Production",
        "exchange": "Échange",
        "withdraw": "Retrait",
        "interest": "Intérêt",
        "rebate": "Rabais"
      },
      "loan": {
        "title": "Montant du prêt",
        "dueDate": "Date d'échéance",
        "tips": ["Prêt avec des documents simples", "fichier simple"],
        "form": "Formulaire de demande de prêt",
        "download": "Télécharger",
        "print": "Imprimer et remplir le formulaire",
        "upload": "Télécharger le formulaire",
        "btn": "Télécharger",
        "file": "Télécharger le fichier",
        "cancel": "Annuler",
        "uFile": "Télécharger le fichier",
        "tip": "Cliquez pour télécharger",
        "doc1": "Contrat de prêt personnel 1.doc",
        "doc2": "Contrat de prêt personnel 2.doc",
        "file1": "PERSONAL_LOAN_AGREEMENT-1.docx",
        "file2": "PERSONAL_LOAN_AGREEMENT-2.docx"
      },
      "nav": {
        "home": "Page d'accueil",
        "lang": "Langue",
        "loan": "Prêt",
        "paper": "Livre blanc",
        "pool": "Données du pool de fonds",
        "trading": "Échanger"
      },
      "whitepaper": {
        "title": "Livre blanc USDC",
        "view": "Voir",
        "eth": "Livre blanc Ethereum"
      },    
    "nft": {
      "vol": "Volume des transactions",
      "amount": "Montant des transactions",
      "qty": "Quantité de NFT",
      "users": "Utilisateurs gagnants",
      "quantity": "Quantité",
      "totalVol": "Volume total des transactions",
      "lastestTrans": "Dernier prix de transaction",
      "collQuantity": "Quantité collectée",
      "showMore": "Afficher plus",
      "pd": "Détails du projet",
      "sellPrice": "Prix de vente",
      "royalty": "Royalties",
      "royaltyTip": "Tous les royalties de cette transaction seront donnés à des œuvres de charité",
      "buy": "Acheter",
      "basic": "Informations de base",
      "contract": "Adresse du contrat",
      "network": "Réseau",
      "attribute": "Attribut",
      "trait": "Caractéristiques de ce NFT",
      "records": "Historique",
      "balance": "Solde",
      "sellNow": "Vendre maintenant",
      "cancel": "Annuler",
      "buyNow": "Acheter maintenant",
      "pay": "Montant à payer",
      "transPass": "Mot de passe de transaction",
      "transTip": "Attention : N'oubliez pas votre mot de passe de transaction. Si vous l'avez oublié, veuillez contacter le service client en ligne.",
      "newPass": "Nouveau mot de passe",
      "rePass": "Confirmer le mot de passe",
      "confirm": "Confirmer",
      "purchaseTime": "Heure d'achat",
      "purchaseAmount": "Montant de l'achat",
      "price": "Prix",
      "priceTip": "Veuillez entrer le prix de vente",
      "sell": "Vendre",
      "digitTip": "Veuillez entrer un nombre à 6 chiffres"
    },      
    info: {
      1: [
          "USDC est le jeton que les traders utilisent pour gagner des intérêts. Il existe plusieurs façons de le faire, ce qui est l'une des caractéristiques uniques des cryptomonnaies, car les rendements offerts par les banques traditionnelles sont très faibles. Comprendre comment utiliser le jeton USDC est précieux pour ceux qui échangent des cryptomonnaies.",
          "Table des matières",
          "1. Qu'est-ce que le dollar coin (USDC) ?",
          "2. Comment fonctionne l'intérêt de l'USDC ?",
          "3. Comment gagner des intérêts avec l'USDC ?",
          "4. Quelles sont les différentes façons de gagner des revenus avec l'USDC ?",
          "5. Est-ce que cela vaut la peine de gagner des intérêts sur l'USDC ?",
          "6. Foire aux questions : Questions fréquemment posées",
          "Qu'est-ce que le dollar coin (USDC) ?",
          "Le dollar coin, abrégé en 'USDC', est une cryptomonnaie appelée stablecoin. Il est émis par les deux plus grandes institutions financières réglementées des États-Unis, Circle et Coinbase, et entièrement soutenu par des actifs de réserve. En d'autres termes, il peut être échangé 1 pour 1 contre des dollars.",
          "Cela permet des transactions ancrées au dollar dans un portefeuille crypto. Le dollar coin est un jeton ERC-20 basé sur le réseau Ethereum, ce qui signifie qu'il peut être stocké dans tout portefeuille acceptant Ethereum. Cela le rend extrêmement flexible et accessible à la plupart des utilisateurs dans le domaine de la cryptomonnaie.",
          "Comment fonctionne l'intérêt sur l'USDC ?",
          "Les stablecoins sont des jetons dont la valeur est liée à un autre actif, ce qui en fait un choix idéal pour gagner des intérêts. En ce qui concerne l'USDC, sa valeur est d'un dollar. Les intérêts générés par ces pièces sont souvent beaucoup plus élevés que ceux des autres cryptomonnaies, car elles sont plus faciles à quantifier. Lorsque les actifs sont stables, il est plus facile de fournir des récompenses, car ils ne devraient pas fluctuer énormément. En permettant aux gens d'emprunter de l'USDC, les entreprises et les particuliers peuvent obtenir des prêts via la blockchain pour des investissements, des raisons personnelles ou du trading à levier. Ils acceptent de rembourser le prêt avec intérêts, comme pour un prêt bancaire traditionnel. En d'autres termes, si vous empruntez 10 000 USDC, c'est comme si vous empruntiez 10 000 dollars.",
          "Cryptomonnaies et intérêts des stablecoins",
          "Vous pouvez gagner des intérêts sur les stablecoins et les cryptomonnaies. Cependant, il existe des différences importantes, et les risques auxquels vous serez confronté refléteront cette distinction. Étant donné que les cryptomonnaies sont plus volatiles que les stablecoins, les plateformes de trading sont moins enclines à les prêter. Une cryptomonnaie peut perdre 30 % de sa valeur en une journée, tandis qu'un stablecoin est beaucoup plus stable.",
          "En raison de la volatilité extrême des cryptomonnaies, les plateformes de trading offrent des taux d'intérêt plus bas pour ceux qui déposent des cryptomonnaies sur leurs plateformes. Après tout, elles pourraient se retrouver en difficulté si elles offrent des rendements plus élevés. D'autre part, ceux qui déposent des cryptomonnaies pourraient obtenir des intérêts, mais perdre une grande partie de leur capital. Le risque est toujours présent.",
          "Les stablecoins comme l'USDC, qui ont une volatilité plus faible, sont donc considérés comme des moyens plus sûrs de stocker de la valeur en cryptomonnaie. C'est pourquoi les plateformes de trading et les blockchains sont prêtes à offrir des taux d'intérêt plus élevés. L'APR de l'USDC a tendance à être plus élevé principalement parce qu'il est 'stable'.",
          "Pourquoi devriez-vous gagner des intérêts sur l'USDC",
          "Il y a de nombreuses raisons pour lesquelles vous voudriez gagner des intérêts sur l'USDC. Voici quelques-uns des avantages, et plus encore :",
          "Alternative aux comptes d'épargne :",
          "Certaines personnes utilisent l'USDC pour gagner des intérêts comme alternative à un compte d'épargne. Au cours des dernières années, les comptes d'épargne ont offert des rendements très faibles, tandis que la cryptomonnaie s'efforce de combler cette lacune.",
          "Rendement plus élevé :",
          "Les rendements obtenus grâce à la mise en staking de cryptomonnaies, y compris l'USDC, sont généralement bien plus élevés que ceux offerts par les banques traditionnelles.",
          "Gagner pendant les périodes d'attente :",
          "Les marchés peuvent parfois être risqués et instables. Ainsi, certains traders choisissent de détenir de l'USDC en dehors des marchés. C'est un excellent moyen d'élargir votre portefeuille sans avoir à exposer votre capital à des risques immédiats.",
          "Comment gagner des intérêts avec l'USDC ?",
          "Pour profiter des avantages de l'USDC, vous devez d'abord en posséder. Conserver de l'USDC est facile et peut être effectué sur la plupart des plateformes d'échange. Vous devrez vous inscrire sur plusieurs plateformes d'échange. Nous vous conseillons de transférer vos fonds par virement bancaire vers votre plateforme d'échange. Lorsque votre virement arrive, vous devrez d'abord acheter de l'ETH sur la plateforme, puis transférer l'ETH sur votre portefeuille d'échange. Dans un portefeuille Web3, l'étape suivante consiste à échanger l'ETH contre de l'USDC. C'est un processus simple et rapide.",
          "Quelles sont les différentes façons de gagner des revenus avec l'USDC ?",
          "Il existe plusieurs façons et endroits où vous pouvez gagner des intérêts sur l'USDC. Cependant, il est important de comprendre les différences entre chaque type de plateforme ainsi que leurs avantages et inconvénients.",
          "Gagner des revenus en utilisant une plateforme d'échange",
          "Gagner des revenus via une plateforme d'échange peut être le processus le plus simple. La plupart des plateformes suivent un processus similaire, qui inclut la création d'un compte, le dépôt d'USDC et le choix de votre produit de revenus préféré. Cela peut impliquer, ou non, de verrouiller vos USDC pendant un certain temps. Si vous avez besoin de liquidité, cela pourrait poser problème.",
          "L'avantage le plus évident d'utiliser une plateforme d'échange centralisée est la simplicité, car vous n'êtes probablement pas obligé de laisser vos cryptomonnaies sur la plateforme ou au moins de les financer pour vos transactions de cryptomonnaies.",
          "Avantages :",
          "Inconvénients :",
          "Simple et pratique.",
          "Il se peut que vous soyez obligé ou non de verrouiller vos USDC pour une certaine période.",
          "Processus complet sur une même plateforme.",
          "Peut offrir des taux d'intérêt plus bas.",
          "La plupart des plateformes d'échange sont bien capitalisées.",
          "Les produits disponibles peuvent être limités.",
          "Web3 est l'un des leaders dans le domaine du staking de cryptomonnaies.",
          "Gagner des revenus via DeFi",
          "L'une des possibilités les plus excitantes dans l'univers de la cryptomonnaie est les plateformes DeFi, qui permettent aux utilisateurs d'accéder à des services financiers de base sans intermédiaires. Les utilisateurs peuvent déposer de la cryptomonnaie (dans ce cas, l'USDC) dans des protocoles de prêt décentralisés pour gagner des intérêts.",
          "Ce domaine de la finance décentralisée permet aux utilisateurs de prêter et d'investir dans de nouveaux produits financiers. Tout cela est rendu possible par les contrats intelligents et la technologie blockchain.",
          "Avantages :",
          "Inconvénients :",
          "Les contrats intelligents offrent une grande facilité d'utilisation.",
          "Les protocoles DeFi ne réversent pas les erreurs des utilisateurs.",
          "Pas d'intermédiaires.",
          "L'évolutivité peut être un problème pour certains protocoles.",
          "Il existe d'autres produits à explorer.",
          "Pour les protocoles plus petits, la liquidité peut poser un problème.",
          "Des exemples de protocoles DeFi incluent Uniswap et Bancor.",
          "Gagner des intérêts sur l'USDC en vaut-il la peine ?",
          "Gagner des intérêts sur l'USDC est un excellent moyen de gagner des intérêts sur des actifs cryptographiques. Gardez à l'esprit que les taux d'intérêt sont bien plus élevés que ceux des comptes d'épargne traditionnels, ce qui vous permet d'obtenir un rendement supérieur.",
          "Gagner des intérêts sur l'USDC est avantageux pour la plupart des traders.",
          "Il y a plusieurs raisons pour lesquelles vous pourriez choisir d'utiliser l'USDC pour commencer à gagner des intérêts. La plus évidente est que, en termes de taux d'intérêt, il surpasse de loin les banques traditionnelles. De plus, l'USDC est un excellent moyen de gagner des intérêts sur un actif qui pourrait se déprécier dans une vente massive de cryptomonnaies. Par exemple, vous pouvez vendre du Bitcoin, le convertir en USDC et gagner des intérêts pendant que vous attendez que le marché se redresse.",
          "Gardez à l'esprit qu'il n'y a pas de sécurité absolue, mais tant que vous choisissez une plateforme de trading ou un service de prêt réputé, vous devriez être protégé. Cela peut être un excellent moyen de diversifier votre portefeuille global, car gagner des intérêts sur l'USDC est similaire à investir dans des obligations à rendement élevé.",
          "La plupart des traders bénéficient en détenant de l'USDC et, bien sûr, essaieront de susciter de l'intérêt lorsque le marché est difficile à gérer. En intégrant ces actifs sûrs dans votre portefeuille, vous obtenez non seulement de la sécurité, mais aussi de la flexibilité, car ils peuvent être convertis en d'autres tokens ou utilisés comme un filet de sécurité. La plupart des traders professionnels possèdent au moins une partie de ces jetons à différents moments.",
          "FAQ : Questions fréquentes",
          "L'USDC paye-t-il des intérêts ?",
          "Le jeton lui-même ne paie pas d'intérêts, mais de nombreuses plateformes d'échange et prêteurs offrent des intérêts. C'est l'une des cryptomonnaies les plus courantes pour gagner des intérêts, donc il y a beaucoup d'options disponibles.",
          "L'USDC paye-t-il des intérêts mensuellement ?",
          "Cela dépend de l'endroit où vous le prêtez. Les périodes de dépôt peuvent varier de très courtes à un an ou plus.",
          "Est-ce que l'achat d'USDC peut être lucratif ?",
          "Pas nécessairement, bien que gagner des intérêts soit une façon de gagner de l'argent. L'USDC est indexé sur le dollar, donc il est essentiellement une transaction 1 pour 1.",
          "Pourquoi les intérêts sur l'USDC sont-ils si élevés ?",
          "Les taux d'intérêt de l'USDC sont plus élevés en raison de l'offre et de la demande. Selon les conditions du marché, ces taux augmentent et diminuent, surtout pendant des périodes comme l'hiver cryptographique. Les taux d'intérêt fluctuent en fonction de l'offre et de la demande.",
          "Perd-on de l'argent avec l'USDC ?",
          "Théoriquement, si l'indexation de l'USDC au dollar venait à être rompue, cela pourrait se produire. Cependant, il est l'un des jetons les plus sûrs dans l'univers cryptographique jusqu'à présent.",
          "Quelle est l'utilité de l'USDC ?",
          "L'USDC a plusieurs utilisations, la plus évidente étant de transférer des dollars sur la blockchain. Cependant, gagner des intérêts est un point important, car vous pouvez le déposer dans une plateforme d'échange, un service de prêt ou dans le domaine DeFi."
              
      ],
      2: [
        
          "Table des matières",
          "1. Qu'est-ce qu'un portefeuille Web3 ?",
          "1.1. Qu'est-ce que Web3 ?",
          "2. Pourquoi avons-nous besoin d'un portefeuille Web3 ?",
          "3. Alternatives au portefeuille Web3",
          "3.1. MetaMask",
          "3.2. Portefeuille Coinbase",
          "3.3. Argent",
          "3.4. Trust Wallet",
          "3.5. Rainbow",
          "4. Qu'est-ce qu'un portefeuille Web3 ?",
          "Avec l'augmentation de l'intérêt pour Web3, la participation à divers écosystèmes a également augmenté de manière significative. De plus, si vous n'êtes pas familier avec ce domaine, l'entrée semble compliquée et difficile à surmonter. Cependant, avec l'intérêt croissant pour les crypto-monnaies et le développement de Web3, ainsi que la simplification de l'industrie pour devenir plus conviviale, ces obstacles disparaissent progressivement. Avec une meilleure accessibilité, l'espace crypto évolue également avec l'intensification de la concurrence, ce qui signifie que de nouvelles dApps (applications décentralisées), tokens, NFT et portefeuilles Web3 sont constamment développés. Les portefeuilles sont essentiels pour entrer dans le domaine des cryptos, car ils remplissent de nombreuses fonctions fondamentales dans l'écosystème Web3. Dans cet article, nous allons examiner plus en détail le domaine des crypto-monnaies et répondre à la question « Qu'est-ce qu'un portefeuille Web3 ? »",
          "En apprenant davantage sur ce qu'est un portefeuille Web3, nous allons également explorer en profondeur certaines des alternatives les plus populaires sur le marché. De plus, si vous êtes intéressé par le développement Web3 et souhaitez créer votre propre portefeuille crypto, nous vous indiquerons le bon chemin, un chemin qui commence avec WEB3.0.",
          "Avec WEB3, vous pouvez développer rapidement et efficacement votre propre portefeuille Web3, car vous pouvez accéder à l'infrastructure sous-jacente du système d'exploitation. Web3.0 est le premier système d'exploitation pour le développement Web3, en plus de l'infrastructure backend, les utilisateurs ont accès à une vaste boîte à outils. Parmi ces outils, vous trouverez des solutions telles que Web3.0 Speedy Nodes, Web3.0 SDK, Price API, support natif IPFS, etc.",
          "Ainsi, si vous souhaitez devenir un développeur blockchain ou un détenteur d'actifs, la première étape consiste à vous inscrire à Web3.0. Cela ne prend que quelques minutes et l'inscription est terminée.",
          "Qu'est-ce qu'un portefeuille Web3 ?",
          "Si vous envisagez de vous lancer dans le domaine des crypto-monnaies, la première étape consiste à obtenir un portefeuille Web3. Un portefeuille Web3 est essentiel pour accéder à l'espace Web3, à la DeFi et aux crypto-monnaies. Vous avez peut-être entendu parler de termes comme « Web3 » ou « portefeuille crypto ». Si vous êtes nouveau dans ce domaine, permettez-nous de répondre à cette question : « Qu'est-ce qu'un portefeuille Web3 ? »",
          "Tout d'abord, un portefeuille Web3 est essentiellement un portefeuille numérique. Il a la capacité de stocker des actifs numériques, allant des tokens fongibles aux tokens non fongibles (NFT). Ensuite, un portefeuille Web3 ouvre également la porte du domaine des cryptos, vous permettant d'interagir avec diverses dApps sur différentes blockchains. En retour, un portefeuille vous permet d'accéder à un large écosystème dApps.",
          "Les portefeuilles crypto sont généralement non custodiaux, ce qui signifie qu'en tant que propriétaire du portefeuille, vous pouvez stocker vos actifs numériques sans avoir besoin d'un intermédiaire ou d'un tiers. Cela signifie que vous avez un contrôle total sur vos actifs, car personne d'autre ne peut accéder à vos tokens. Cependant, avec un accès exclusif, toute la responsabilité repose sur vous, ce qui signifie que vous devez gérer votre clé privée vous-même.",
          "En plus de la capacité de stocker des actifs numériques, les portefeuilles offrent souvent d'autres fonctionnalités. Par exemple, cela permet d'envoyer et d'échanger des tokens avec un portefeuille Web3. Ainsi, un portefeuille crypto peut être utilisé pour gérer l'intégralité de vos actifs, y compris les moyens d'acquérir des tokens supplémentaires.",
          "Il existe une variété de portefeuilles sur le marché, chacun ayant ses avantages. Certains des plus populaires sont MetaMask, Coinbase Wallet, TrustWallet, Argent, Atoken, Hyperpay, Bitkeep, etc. Un autre sujet intéressant à explorer est WalletConnect, car il est étroitement lié aux portefeuilles Web3.",
          "Qu'est-ce que Web3 ?",
          "Avec l'émergence de l'industrie de la blockchain, il est probable que vous ayez entendu parler de « Web3 ». Cependant, pour les néophytes, il s'agit d'un terme qui peut sembler étrange et un peu déroutant. Dans cette section, nous allons prendre le temps d'expliquer et de résumer ce qu'est Web3.",
          "En général, Web3 fait référence à la « dernière génération » ou au « dernier stade » de l'internet. Vous avez probablement deviné que les générations précédentes sont les stades Web1 et Web2 que vous connaissez mieux. Ces trois ères du web n'ont pas commencé à un moment précis ni été initiées par une entité unique modifiant radicalement le web. Cependant, chaque étape a ses caractéristiques propres : Web1 est statique, Web2 est dynamique, et Web3 est décentralisé.",
          "La décentralisation est le concept central de la dernière étape du web, dominée par des données décentralisées. Contrairement à Web2, il n'existe pas d'entité centralisée détenant les données ; au contraire, les données sont dispersées et partagées. De plus, Web3 résout finalement le problème où les entreprises possèdent une grande quantité d'informations personnelles, car les utilisateurs contrôlent leurs propres données.",
          "Dans l'écosystème Web3, nous trouvons également un autre élément important : les dApps (applications décentralisées). Ce sont des applications décentralisées, généralement basées sur la blockchain, et le plus grand écosystème de dApps est actuellement hébergé sur la blockchain Ethereum. Grâce à la décentralisation des dApps, il est possible de créer des applications puissantes pour éliminer divers problèmes liés à la centralisation, y compris les points de défaillance uniques.",
          "Cependant, si vous souhaitez en savoir plus sur la dernière étape de l'internet, nous vous recommandons de lire notre article expliquant Web3 plus en détail.",
          "Pourquoi avons-nous besoin d'un portefeuille Web3 ?",
          "Dans la section « Qu'est-ce qu'un portefeuille Web3 ? », nous avons brièvement abordé les raisons pour lesquelles les gens ont besoin de ce type de portefeuille. Cependant, dans cette partie de l'article, nous allons détailler ces points. Alors, pourquoi avons-nous besoin d'un portefeuille Web3 ?",
          "Le premier cas d'utilisation d'un portefeuille Web3 est la gestion complète de vos actifs crypto. Pour illustrer cela, examinons de plus près le plus grand portefeuille crypto du marché, MetaMask. Si vous avez un compte MetaMask, vous êtes probablement déjà familier avec le fonctionnement du portefeuille. L'interface est la suivante :",
          "Comme vous pouvez le voir, vous avez deux onglets différents : un pour les « Actifs » et un pour les « Activités ». L'onglet Activités affiche uniquement votre historique de transactions, que vous pouvez explorer indépendamment. De plus, sous l'onglet Actifs, vous pouvez voir tous vos actifs. Il y a également trois boutons d'action : Acheter, Envoyer, Échanger. Ces boutons sont évidents, donc nous n'entrerons pas dans les détails.",
          "Cela montre que, en tant que portefeuille crypto, vous pouvez gérer entièrement tous vos actifs directement via MetaMask. De plus, ce n'est pas unique, car la plupart des autres portefeuilles vous permettent de gérer vos actifs de manière similaire.",
          "La deuxième raison pour laquelle vous avez besoin d'un portefeuille Web3 est l'accessibilité. Les portefeuilles crypto sont des passerelles vers diverses blockchains. Ainsi, un portefeuille Web3 permet aux utilisateurs d'accéder facilement aux dApps, car les actifs crypto sont nécessaires pour interagir avec la plupart des applications. Par conséquent, les utilisateurs peuvent utiliser leur portefeuille pour valider leur identité et s'assurer qu'ils sont en mesure de payer les frais de transaction.",
          "Si vous êtes intéressé par la validation des utilisateurs, consultez notre article sur la façon d'utiliser MetaMask pour l'authentification et comment connecter les utilisateurs avec WalletConnect.",
          "Alternatives aux portefeuilles Web3",
          "Précédemment, nous avons mentionné qu'avec de plus en plus de personnes entrant dans le domaine des crypto-monnaies, le nombre de portefeuilles Web3 augmente. Cela résulte d'un simple problème d'« offre et demande », car les entreprises ont vu l'opportunité de croissance dans ce marché émergent. Cependant, lorsque les utilisateurs essaient de faire un choix parmi de nombreuses alternatives, cela peut devenir déroutant.",
          "Par conséquent, nous allons nous concentrer sur cinq portefeuilles alternatifs parmi lesquels vous pouvez choisir. La plupart d'entre eux partagent le même objectif ; cependant, chacun est unique à sa manière. Ces cinq portefeuilles Web3 sont :",
          "MetaMask",
          "Coinbase Wallet",
          "Argent",
          "Trust Wallet",
          "Rainbow",
          "Alors, sans plus tarder, examinons de plus près MetaMask !",
          "MetaMask",
          "Le premier portefeuille Web3 de notre liste est MetaMask, l'un des portefeuilles les plus connus sur le marché, avec plus de 21 millions d'utilisateurs dans le monde. Ce portefeuille existe sous deux formes différentes : une application mobile ou une extension de navigateur. Nous avons mentionné MetaMask précédemment lorsqu'il s'agissait de souligner pourquoi les gens ont besoin de portefeuilles crypto. Nous avons noté qu'il est possible de gérer complètement vos actifs crypto de manière décentralisée via MetaMask.",
          "Cependant, nous n'avons pas mentionné que MetaMask est la porte d'entrée vers l'écosystème dApps Ethereum. De plus, Ethereum est la blockchain de développement la plus importante, ce qui signifie qu'il y a des milliers d'applications disponibles pour les utilisateurs de MetaMask. Par conséquent, avec la croissance d'Ethereum, posséder un portefeuille MetaMask sera très bénéfique. Pour un guide plus détaillé sur MetaMask, consultez notre article explicatif sur MetaMask.",
          "Coinbase Wallet",
          "Si vous vous intéressez aux crypto-monnaies, vous avez probablement entendu parler de Coinbase. Coinbase est l'une des plus grandes plateformes de crypto-monnaies au monde, et en plus de sa plateforme conviviale, Coinbase propose maintenant un portefeuille Web3 sous forme de Coinbase Wallet.",
          "Le portefeuille Coinbase est un portefeuille Web3 non custodial, indépendant de la plateforme centralisée Coinbase. Cela offre aux utilisateurs un moyen de découvrir le monde des dApps et des crypto-monnaies de manière autonome, sans contrôle centralisé de Coinbase. De plus, une fonctionnalité unique de Coinbase Wallet est la possibilité d'envoyer et de recevoir des actifs crypto en utilisant un nom d'utilisateur, au lieu d'envoyer des actifs via des adresses longues et complexes.",
          "En outre, si vous souhaitez en savoir plus sur l'envoi de tokens, consultez notre article sur l'envoi de tokens ERC-20. Cela montre à quel point il est facile d'envoyer des tokens ERC-20 lorsqu'on utilise Web3.0.",
          "Le troisième portefeuille est Argent, qui est relativement nouveau sur le marché des portefeuilles Web3. Argent cible les utilisateurs d'Ethereum, car il est uniquement compatible avec les tokens Ethereum. De plus, ce portefeuille se consacre à offrir une expérience utilisateur conviviale lors de l'utilisation de dApps et de la DeFi (finance décentralisée). Ils y parviennent en intégrant nativement les dApps et les protocoles dans l'application Argent. Ainsi, les utilisateurs peuvent directement emprunter et prêter des actifs via l'application, évitant ainsi l'utilisation d'un navigateur intégré.",
          "Si vous souhaitez en savoir plus sur DeFi, nous vous suggérons de consulter l'article « Qu'est-ce que la DeFi ? » de WEB3. Si vous souhaitez développer vos compétences en DeFi, vous pouvez également consulter les cours Blockchain DeFi 101 et DeFi 201.",
          "Cependant, l'un des principaux inconvénients d'Argent est que le portefeuille ne prend en charge que le réseau Ethereum. Par exemple, il n'est pas possible de stocker des tokens natifs d'autres chaînes (comme ceux de la blockchain Bitcoin).",
          "Trust Wallet",
          "Le quatrième choix est Trust Wallet, qui, contrairement à Argent, a la capacité de stocker presque tous les types de tokens. Ce portefeuille est dit blockchain-agnostique, ce qui signifie qu'il prend en charge des actifs provenant de différentes chaînes. En outre, Trust Wallet compte plus de 10 millions d'utilisateurs dans le monde et a bien établi sa place sur le marché. Puisque Trust Wallet prend en charge plusieurs actifs et blockchains, il devient une alternative logique pour profiter pleinement des fonctionnalités offertes par les réseaux décentralisés.",
          "Rainbow",
          "Le dernier choix est Rainbow, un portefeuille non custodial compatible avec le réseau Ethereum. De plus, Rainbow, comme Argent, prend en charge nativement les dApps du réseau Ethereum. Un exemple ici est Uniswap V2, utilisé pour effectuer des échanges dans l'application. Une autre fonctionnalité astucieuse du portefeuille Rainbow est son support NFT. Grâce à cela, le portefeuille peut afficher proprement tous vos actifs numériques.",
          "Ce ne sont là que cinq des nombreuses alternatives disponibles, et vous avez probablement remarqué dans les descriptions succinctes que ces portefeuilles sont à la fois similaires et différents les uns des autres. Ainsi, vous pouvez trouver celui qui convient le mieux à vos besoins spécifiques.",
          "Qu'est-ce qu'un portefeuille Web3 ? - Résumé",
          "Si vous souhaitez entrer dans le monde des crypto-monnaies, la première chose dont vous avez besoin est un portefeuille Web3. Les portefeuilles crypto vous permettent de gérer tous vos actifs numériques tout en offrant un accès à l'écosystème décentralisé dApps. De plus, tout cela est possible tout en maintenant un contrôle total sur vos actifs, car la décentralisation est la caractéristique fondamentale de Web3. Ainsi, aucun intermédiaire n'est nécessaire pour effectuer des transactions, prêter des actifs, etc.",
          "Avec l'intérêt croissant pour les réseaux décentralisés, nous avons vu une augmentation significative du nombre d'alternatives aux portefeuilles. Cependant, les cinq options les plus populaires sont MetaMask, Coinbase Wallet, Argent, Trust Wallet et Rainbow. Bien que ces alternatives partagent de nombreuses similitudes, certaines sont plus adaptées que d'autres en fonction de vos besoins et de votre situation.",
          "De plus, grâce à la puissance de WEB3, il est facile et rapide de développer un portefeuille avec des fonctionnalités spécifiques. Cela est possible car les utilisateurs de Moralis peuvent utiliser l'infrastructure backend du système d'exploitation, ce qui permet de créer un portefeuille Web3 entièrement fonctionnel en quelques minutes.",
          "Cependant, les limitations de Moralis ne s'arrêtent pas là. En fait, il est possible de réduire considérablement le temps de développement de tous vos futurs projets blockchain. Si vous avez encore besoin de plus de persuasion, consultez les articles de blog qui montrent combien il est facile de créer une dApp Ethereum, développer votre propre marché NFT ou créer un token BSC en cinq étapes.",
          "Ainsi, si vous aspirez à améliorer vos compétences en développement Web3, inscrivez-vous immédiatement à WEB3. Cela vous donnera accès à l'infrastructure backend et aux outils de Moralis !"
          
      ],
      3: [
          "Web3 au Vietnam UEL pour promouvoir le concept de Web3 et offrir des bourses",
          "Web3 est heureux d'annoncer la fin réussie de son dernier événement dédié aux étudiants, intitulé 'Gate Web3 - Empowering GenZ in Web3'. Cet événement éducatif et caritatif s'est déroulé le 28 octobre 2023 de 13h00 à 16h30 à l'Université d'Économie et de Droit de Ho Chi Minh-Ville, au Vietnam.",
          "L'objectif de l'événement était de fournir des connaissances et des opportunités aux jeunes générations, en particulier la génération Z, dans le monde en rapide évolution des technologies Web3. Les participants ont eu un aperçu précieux des concepts révolutionnaires de Web3, ainsi que des raisons pour lesquelles cette technologie est considérée comme un leader potentiel pour l'avenir. Des experts ont également donné des conseils sur les compétences de base, par où commencer et comment bâtir une carrière réussie dans ce domaine dynamique.",
          "Web3 s'engage à soutenir les étudiants défavorisés en offrant des bourses pour aider à couvrir les frais de scolarité et les frais de subsistance des étudiants dans le besoin. La conférencière Mme Mai Ngo",
          "L'événement a également inclus une table ronde avec des experts de l'industrie, dont Mme Mai Ngo de BSCS, M. Tuan Anh et M. Dao Tien Phong, avocat principal chez Investpush Legal, qui ont partagé des perspectives précieuses sur les opportunités de carrière dans le domaine de la technologie blockchain. Les participants ont eu l'occasion d'échanger de manière significative avec ces experts et d'obtenir des connaissances et des conseils précieux.",
          "En plus des sessions enrichissantes, Web3 et Gate Charity ont distribué des bourses pendant l'événement. Un total de 15 bourses a été attribué à des étudiants pauvres ayant d'excellentes performances académiques, chaque bourse d'une valeur de 1 million de VND.",
          "L'événement a attiré environ 100 étudiants et enseignants de l'université FTC. Une large diffusion en ligne était également prévue, avec une audience en ligne estimée à 50 000 personnes.",
          "Web3 est ravi d'avoir organisé cet événement qui a réuni des experts, des étudiants et des défenseurs de la technologie Web3 pour promouvoir l'éducation, la sensibilisation et les opportunités dans ce domaine de transformation.",
          "À propos de Gate Charity",
          "Gate Charity est une organisation caritative mondiale à but non lucratif dédiée à la promotion de la charité par la blockchain. Gate Charity a pour mission 'd'encourager l'équilibre mondial' en unissant la communauté des crypto-monnaies, en utilisant des réseaux blockchain et des crypto-monnaies innovants et fiables pour réduire les inégalités de pauvreté, améliorer la vie des groupes vulnérables, maximiser l'égalité d'éducation et soutenir les initiatives de développement durable environnemental.",
          "Avertissement :",
          "Veuillez noter que toutes les informations relatives à cet événement sont fournies à des fins caritatives uniquement. L'utilisation ou l'achat de tout service ou produit associé à cet événement n'est pas autorisé. Pour toute question, veuillez contacter Gate Charity à l'adresse support@gatecharity.org.",
          "Effectuez vos CryptoTrades de manière sécurisée, rapide et facile sur Web3 avec plus de 1 700 cryptomonnaies disponibles.",
          "Inscrivez-vous sur Web3 et obtenez 40% de commission de parrainage, obtenez immédiatement un espace de stockage Web3.",
          "Contactez-nous !",
          "Équipe 23 octobre 2023"      
      ],
      4: [
          "Accès à la Blockchain",
          "Changer des vies",
          "Créer un monde où tout le monde peut accéder à des services financiers et à de l'aide à tout moment et en tout lieu grâce aux solutions blockchain.",
          "Total des dons",
          "(en USD)",
          "Total des bénéficiaires",
          "Régions bénéficiaires",
          "Favoriser le développement équilibré mondial",
          "Éliminer la pauvreté",
          "Nous croyons que la blockchain et les crypto-monnaies peuvent jouer un rôle dans la création d'opportunités égales en matière d'éducation et d'emploi, aider les personnes touchées par la pauvreté, et permettre à des millions de personnes de sortir de la pauvreté.",
          "Autonomiser les populations vulnérables",
          "Éliminer les barrières d'accès aux ressources clés et aux systèmes de soutien, en se concentrant sur l'aide aux femmes, aux personnes handicapées, aux enfants pauvres, aux personnes âgées et aux familles vulnérables dans les zones de pauvreté, pour réaliser l'inclusion, l'accessibilité et la capacité de prospérer et de se développer.",
          "Sauver notre planète",
          "Il n'y a qu'une seule Terre, et nous agissons toujours pour réduire les destructions causées par l'humanité sur le climat et l'environnement naturel. Un environnement sain a un impact universel et positif sur les générations futures.",
          "Fournir de l'aide humanitaire",
          "Fournir de l'aide en temps opportun aux personnes touchées par des catastrophes et des crises, y compris le soutien aux personnes affectées par les conflits, la reconstruction dans les zones sinistrées, l'aide aux déplacés et l'aide aux régions médicalement défavorisées.",
          "Transparence et traçabilité des collectes de fonds",
          "Utiliser la technologie blockchain pour garantir des actions caritatives transparentes et fiables, qui aident réellement les populations vulnérables.",
          "Levée de fonds par des œuvres d'art NFT",
          "Grâce aux enchères NFT, nous pouvons efficacement réaliser nos objectifs caritatifs tout en diffusant la valeur des œuvres d'art.",
          "Projets de jeux caritatifs en Metavers",
          "Des solutions Web3.0 diversifiées, offrant une manière éducative et ludique de semer les graines de la charité dans le cœur de chacun.",
          "Utiliser la technologie blockchain pour le bien des gens",
          "Technologie blockchain de premier plan mondial",
          "Grâce à une technologie blockchain innovante et sécurisée, nous assurons des dons caritatifs transparents, sûrs et fiables. Chaque personne ayant besoin de soutien économique est garantie de recevoir une aide de toutes sortes.",
          "Réservoir de ressources numériques largement soutenu",
          "Nous sommes fiers de soutenir le réservoir d'actifs numériques le plus large du monde, ce qui signifie que vous pouvez utiliser tous les actifs numériques que vous possédez pour participer à ce grand mouvement caritatif.",
          "Obtenez une éducation blockchain",
          "Nous croyons que l'éducation est la base de tout. Offrir une éducation de base sur la blockchain apportera des résultats fructueux à ceux que nous aidons, qui deviendront une partie indispensable de la construction de la blockchain de demain.",
          "Coopération multilatérale en matière de charité",
          "Nous nous engageons à maximiser l'efficacité de la blockchain en unissant les meilleures organisations, projets et experts de l'industrie, et à comprendre que toute forme d'intégration finira par servir la charité elle-même.",
          "Classement",
          "Le 21 août 2010, de 8 h à 16 h (GMT -3), Web3 Charity a organisé un événement spécial pour la Journée des Enfants au célèbre stade Brahma au Brésil, en collaboration avec A.A. Garotos de Ouro et Gate.io. Cette rencontre extraordinaire a attiré environ 150 enfants de communautés voisines, tous remplis d'enthousiasme et d'excitation.",
          "L'organisation caritative mondiale Web3 Charity a organisé avec succès un événement sur le thème 'Protéger les océans' le 6 octobre 2023 à Baclaran, aux Philippines.",
          "Nos initiatives",
          "Soutien à la crise alimentaire en Somalie",
          "Rejoignez Gate Charity et World Vision pour soutenir les victimes de la famine en Somalie",
          "Contributions",
          "Faire un don maintenant",
          "Causes soutenues",
          "Dans au moins 45 pays à travers le monde, près de 50 millions de personnes sont au bord de la famine. Nous n'avons jamais connu une telle crise de faim dans l'histoire récente. Des pays d'Afrique, d'Amérique latine, du Moyen-Orient et d'Asie sont tous touchés. En Somalie et dans d'autres pays, des millions de personnes souffrent de la faim. Les effets du changement climatique, tels que les sécheresses, les inondations et d'autres conditions climatiques extrêmes, ont détruit les cultures et les revenus, provoquant même des invasions de criquets dans certains pays, ravageant les récoltes. Les conséquences de la pandémie de COVID-19 ont entraîné une augmentation des prix mondiaux des denrées alimentaires et d'autres produits, rendant l'achat des aliments de base nécessaires à la survie impossible pour les familles. En même temps, les conflits dans le monde entier forcent des millions de personnes à fuir, détruisant foyers, vies et moyens de subsistance.",
          "L'accord avec le Programme alimentaire mondial des Nations Unies signifie qu'un don de 1 USDC sera multiplié par 27.",
          "Les conflits multiples, y compris la guerre en Ukraine, ont provoqué une tempête parfaite pour les enfants les plus vulnérables, notamment dans les régions productrices de blé dans le monde.",
          "En même temps, plusieurs conflits à travers le monde forcent des millions de personnes à fuir, détruisant foyers, vies et moyens de subsistance.",
          "Les conséquences de la pandémie de COVID-19 ont entraîné une hausse des prix des denrées alimentaires et d'autres produits dans le monde entier.",
          "Les effets du changement climatique, tels que la sécheresse, les inondations, les tempêtes extrêmes et des conditions météorologiques imprévisibles, ont détruit les récoltes et les revenus, et dans certains pays, des invasions de criquets ont ravagé les récoltes.",
          "Unir nos forces contre la famine",
          "Pour faire face à la crise de la faim sans précédent qui touche près de 50 millions de personnes dans 45 pays, Web3 Charity s'associe à World Vision. Ce partenariat vise à fournir une aide alimentaire essentielle aux personnes dans le besoin, en particulier dans les régions touchées par la famine comme la Somalie. L'initiative vise à résoudre les graves problèmes de faim en Afrique, Amérique latine, au Moyen-Orient et en Asie.",
          "Maximiser l'impact du Programme alimentaire mondial",
          "Web3 Charity, en partenariat avec World Vision, utilise les dons individuels avec l'aide du Programme alimentaire mondial des Nations Unies (PAM). Chaque don de 1 USDC est multiplié par 27, transformant un don de 1 000 USDC en 27 000 USDC. Ces ressources sont cruciales pour fournir une aide alimentaire de base aux familles des régions touchées par la famine, en particulier les enfants qui dépendent de l'aide humanitaire pour survivre. L'initiative comprend la distribution de colis alimentaires et la fourniture de nutrition thérapeutique aux enfants pour répondre à leurs besoins nutritionnels immédiats pendant cette crise.",
          "Nos partenaires",
          "World Vision est une organisation chrétienne non gouvernementale dédiée à l'élimination de la pauvreté et à la promotion du développement durable des communautés, familles et enfants vulnérables dans le monde entier. L'organisation fournit chaque minute de l'eau, de la nourriture et de l'autonomisation aux personnes dans le besoin. World Vision travaille dans près de 100 pays et répond rapidement aux urgences, crises et catastrophes naturelles. En 2021, l'organisation a aidé plus de 30 millions de personnes dans 52 pays touchés par des situations d'urgence. Les comptes de World Vision sont audités chaque année pour garantir la transparence.",
          "Soutenir l'école pour enfants handicapés SLB Sri Soedewi en Indonésie",
          "Rejoignez Gate Charity et World Vision pour soutenir les victimes de la famine en Somalie",
          "Contributions",
          "Faire un don maintenant",
          "Causes soutenues",
          "L'école pour enfants handicapés SLB Sri Soedewi a été fondée en 1982 et accueille plus de cent élèves, offrant des cours pour diverses déficiences, y compris des troubles de la vision, de l'audition, de l'intelligence, des handicaps physiques et de l'autisme. Elle accueille des élèves du primaire au lycée. L'école se concentre sur l'utilisation du potentiel et des intérêts uniques de chaque élève pour développer ses capacités académiques et diverses compétences, garantissant ainsi leur épanouissement global. L'objectif ultime est de permettre aux élèves d'acquérir des compétences de vie pour vivre de manière indépendante dans la société. Web3 Charity et l'école SLB Sri Soedewi pour enfants handicapés sont engagées dans la promotion de l'inclusion et la fourniture d'une plateforme sûre et de soutien pour chaque enfant.",
          "Chaque don a un impact significatif sur la vie des élèves, et vous pouvez faire partie de cette action.",
          "Fournir un soutien et une vie académique de qualité pour les élèves malvoyants peut les aider à développer leur estime de soi, créer des amitiés importantes et leur faire croire que l'avenir est là pour être exploré.",
          "Un environnement scolaire adapté est une réponse à long terme aux besoins sociaux et culturels des enfants. SLB Sri Soedewi offre des opportunités pour développer des systèmes de soutien émotionnel, linguistique et académique qui permettent aux élèves de briller.",
          "Le handicap ne signifie pas un obstacle à la vie, ni un obstacle à leur accès et leur participation à certaines activités. Ce sont simplement des façons différentes de faire ce qu'ils ne peuvent pas faire, car ils sont tous très spéciaux.",
          "L'esprit de solidarité est l'un des facteurs essentiels pour cultiver l'inclusion, afficher de larges sourires et forger des liens solides."      
      ],
      5: [
          "Circle publie son premier rapport annuel sur la « situation économique de l'USDC »",
          "Voici l'introduction du rapport intitulé « Situation économique de l'USDC — Bienvenue dans l'ère de la valeur pratique de la monnaie numérique ». ",
          "Téléchargez le rapport complet sur la situation économique de l'USDC et consultez notre agenda du Forum économique mondial #WEF23 à l'adresse Circle.com/Davos",
          "La monnaie numérique du dollar stimule la prochaine évolution des monnaies, des paiements et de la finance",
          "Il y a dix ans, j'ai cofondé Circle avec d'autres, avec pour vision de rendre les monnaies traditionnelles disponibles sous forme de monnaie numérique sur Internet, sur un réseau mondial ouvert et interopérable. Nous avons créé un monde d'échanges de valeur sans friction, à l'image de l'échange d'informations et de communications sans friction des premières décennies de l'Internet, et depuis ce moment-là, le travail de Circle n'a cessé de prendre vie.",
          "En 2013, la maturité technologique des monnaies numériques et des technologies blockchain, la compréhension du secteur et la prise de conscience réglementaire étaient quasi inexistantes. En réalité, les conditions nécessaires pour établir un protocole pour le « dollar sur Internet » n'étaient même pas encore en place. Nous savions que cela prendrait au moins dix à vingt ans, et que l'impact des monnaies numériques sur un réseau ouvert serait encore plus profond que celui des réseaux d'information.",
          "Dix ans plus tard, la monnaie numérique du dollar la plus réglementée au monde, le dollar numérique (USDC), entre dans le mainstream. Sur presque tous les indicateurs et tendances, l'économie de l'USDC connaît une croissance saine et devient une force majeure pour favoriser les activités économiques à l'échelle de l'Internet — ce que les banques, en s'appuyant sur des infrastructures fixes ou des services bancaires, ne peuvent pas couvrir en termes de populations et de marchés.",
          "Alors que l'ère de la spéculation sur le bitcoin et autres actifs numériques a traversé une année tumultueuse, des monnaies numériques comme l'USDC annoncent la nécessaire transition vers l'ère de la valeur pratique de la blockchain et des monnaies programmables, composables et toujours en ligne. Dans l'ère du réseau, promouvoir ces innovations responsables n'est pas destiné à concurrencer le système financier traditionnel, mais à achever le travail inachevé.",
          "Abordons cette opportunité de la bonne manière : des monnaies numériques comme l'USDC pourraient commencer à absorber une grande partie des plus de 2 trillions de dollars de revenus du secteur des paiements et devenir une nouvelle expression numérique de la masse monétaire M2 de 21 trillions de dollars.",
          "Prenons un exemple : les commerçants comprennent ce que cette évolution signifie. Plus de 85 % privilégient l'activation des paiements en monnaie numérique. Bien que les paiements en USDC soient encore émergents, ils commencent à se faire sentir. De nombreux prestataires de paiement traditionnels (y compris Checkout.com, Mastercard, Plaid, Stripe, Visa, FIS Worldpay, etc.) collaborent avec l'USDC pour permettre aux commerçants de l'accepter aussi facilement que possible.",
          "L'infrastructure blockchain publique subit une mise à niveau vers un « haut débit » qui facilite les transactions quasi instantanées, hautement sécurisées, programmables et à faible coût. À mesure que de plus en plus de startups, d'entreprises et de sociétés traditionnelles de paiements et de services financiers cherchent à se connecter à l'Internet de la valeur, les créateurs et développeurs du Web2 se tournent vers le Web3.",
          "En parallèle, les décideurs politiques mondiaux renforcent leur attention en introduisant des mesures réglementaires nécessaires pour superviser comment les régulateurs bancaires et des paiements supervisent de manière cohérente et coordonnée les monnaies numériques de confiance.",
          "Depuis sa création, Circle croit fermement qu'un nouveau système économique mondial peut être construit sur une infrastructure Internet native — ouverte, mondiale et interopérable, utilisée pour le stockage et le transfert de valeur, et, finalement, pour une plus large médiation du capital. Cette vision est en train de devenir une réalité, et 2023 sera une année cruciale pour la prochaine évolution de la monnaie, des paiements et de la finance.",
          "Comme nous l'avons souligné dans notre premier bilan annuel de l'économie de l'USDC, la germination des valeurs des services publics mondiaux est en train de se produire à travers le monde, catalysant une vague d'innovations dans les services financiers ouverts et responsables, capables d'améliorer la voie vers la prospérité tout en protégeant l'intégrité du système financier mondial."      
      ],
      6: [
          "Nouveaux éléments dans la situation économique de l'USDC pour 2024",
          "Un nouveau système financier en ligne est en train d'émerger. Grâce à l'USDC, aux réseaux blockchain et à certaines des meilleures entreprises de développement et technologiques au monde, le système financier est en train de se mettre à jour. Il devient plus rapide, plus facile et plus sûr, permettant à tout le monde de générer de la valeur.",
          "Introduction",
          "Au cours de la dernière décennie, l'émergence de la technologie blockchain a propulsé l'intégration d'Internet et de la finance mondiale. Cette intégration apporte une mise à jour logicielle révolutionnaire au système financier.",
          "C'est exactement ce que Circle a fondé en 2013. Notre mission est d'exploiter l'ouverture et l'échelle d'Internet pour favoriser la prospérité économique mondiale à travers des échanges de valeur sans friction — en créant ce que nous considérons comme un nouveau système financier en ligne émergent.",
          "Les logiciels sont sur le point de s'emparer du monde financier, tout comme ils ont fait dans de nombreux secteurs traditionnels au cours de la génération précédente. En rendant la communication, le divertissement et d'autres activités commerciales plus rapides, moins chères et plus accessibles, les coûts baissent, les obstacles disparaissent, et l'innovation prospère. Nous pensons que la migration accélérée des entreprises vers la blockchain est la prochaine vague de la transformation numérique qui s'étend sur plusieurs décennies, dont les impacts sociaux et économiques pourraient être encore plus grands que ceux libérés par l'Internet dans les années 90 et 2000.",
          "En considérant que de 2000 à 2020, l'utilisation d'Internet a augmenté de moins de 7 % de la population mondiale à 60 %, il est probable que nous assistions à une trajectoire de croissance similaire.",
          "Dans les prochaines années, nous prévoyons que des millions d'entreprises et des milliards de personnes commenceront à utiliser ce nouveau Web3 ouvert pour traiter des valeurs de plusieurs trillions de dollars.",
          "La monnaie ouverte est une grande idée, comprenant des principes économiques, technologiques et philosophiques enracinés dans l'ouverture et l'interopérabilité du réseau. En réduisant à zéro le coût marginal de la diffusion des données, Internet permet à chacun d'accéder à la somme totale des connaissances humaines. En permettant aux développeurs et aux créateurs de construire facilement, il a permis la création d'un écosystème riche de sites et d'applications.",
          "Cependant, les projets d'Internet sont encore incomplets, car bien que les données puissent être transmises de manière sécurisée, instantanée et gratuite à travers le monde, l'autoroute monétaire d'Internet est encore en construction.",
          "Tout ce que nous faisons aujourd'hui est destiné à construire et à accélérer ce système financier en ligne.",
          "Comme détaillé dans ce rapport, tout a commencé lorsque nous avons cherché à faire de l'USDC le plus grand et le plus utilisé des réseaux de stablecoins au monde. Grâce à l'USDC, nous avons permis à presque tout le monde, partout, d'accéder et d'utiliser des dollars numériques sur Internet. Nous transformons l'USDC en une plateforme ouverte où les entreprises et les développeurs peuvent créer une nouvelle vague d'applications capables de rivaliser avec les plateformes dominantes d'Internet et de la finance d'aujourd'hui.",
          "L'année dernière a marqué un tournant majeur pour l'avenir de la finance native d'Internet basée sur la blockchain. Les acteurs malveillants ont commencé à supporter la pression de leurs actions malfaisantes, les fraudes ont été exposées, mais la technologie sous-jacente a mûri, et les entreprises traditionnelles ont approfondi leur engagement avec des technologies comme l'USDC.",
          "Les principales entreprises de paiement et de règlement commercial utilisent désormais l'USDC pour résoudre des problèmes concrets. Les marchés du crédit basés sur la blockchain utilisent l'USDC pour soutenir les activités économiques réelles à travers le monde. Les fintechs et néobanques, nées d'Internet, se tournent vers l'USDC comme moyen de transférer des dollars à la vitesse du commerce en ligne. Les entreprises de transferts de fonds et les ONG collaborent avec l'USDC pour résoudre le problème de l'envoi rapide et équitable de valeur aux personnes qui en ont besoin. Les banques traditionnelles, les entreprises des marchés de capitaux et les dépositaires soutiennent l'USDC comme un moyen de développer leurs affaires.",
          "C'est cette dynamique qui explique pourquoi les décideurs mondiaux ont fait d'importants progrès en publiant des règles et des lignes directrices sur la manière de participer de manière responsable aux stablecoins de paiement et aux blockchains ouvertes. Ils reconnaissent l'ampleur de l'opportunité et sont déterminés à tirer parti de celle-ci pour apporter des bénéfices à l'économie et à la société. Innovateurs et décideurs redéfinissent ensemble l'art de la monnaie numérique sur Internet.",
          "Bien qu'il soit encore trop tôt pour tirer des conclusions définitives, des signes clairs montrent que l'USDC et l'écosystème plus large sont prêts à atteindre une vitesse d'évasion — libérant ainsi la puissance des monnaies ouvertes à travers le monde.",
          "J'espère que vous apprécierez la version 2024 de la situation économique de l'USDC. Mon équipe et moi sommes impatients de discuter des résultats de notre étude avec vous.",
          "Sincèrement,",
          "Co-fondateur, Président et Directeur Général",
          "Des dollars pour tout le monde, partout",
          "Un réseau blockchain ouvert propulse une nouvelle forme de monnaie et redéfinit la manière dont les individus et les entreprises échangent de la valeur à l'échelle mondiale. Ces réseaux représentent une nouvelle dimension des fintechs, utilisant Internet pour réduire les coûts et frictions profondément ancrés, augmenter la vitesse et repenser les possibilités d'échange de valeur, que ce soit pour envoyer des dollars numériques à l'international ou interagir avec des versions tokenisées d'autres actifs traditionnels.",
          "Cette possibilité a poussé Circle à lancer l'USDC en 2018.",
          "Sous l'impulsion de la position du dollar dans les centres financiers mondiaux, des opportunités massives ont récemment émergé et commencent à avoir un impact considérable. En Amérique latine, plus de 90 % du commerce est libellé en dollars, en Asie-Pacifique, 74 %, et dans les autres régions du monde en dehors de l'Europe, 79 %. Un tiers des billets de 100 dollars sont détenus en dehors des États-Unis.",
          "La position mondiale du dollar signifie que la grande majorité des activités de stablecoins aujourd'hui sont libellées en dollars. L'USDC permet à presque tout le monde, partout, d'accéder, détenir et échanger des dollars numériques via Internet. L'USDC est lancé dans plus de 190 pays et est conservé dans près de 3 millions de portefeuilles blockchain.",
          "Plateforme de monnaie ouverte sur Internet",
          "Au cours des dernières années, l'USDC, en tant que réseau de stablecoins, a connu une croissance exponentielle. Depuis son lancement en 2018, l'USDC a été utilisé pour régler environ 12 trillions de dollars de transactions blockchain. De plus en plus de personnes dans davantage de pays expérimentent les avantages de déverrouiller des dollars numériques sur la blockchain. Cela stimule les développeurs à créer de nouvelles applications, renforce l'écosystème de services autour de l'USDC, et facilite son accès, son envoi et son stockage.",
          "L'augmentation des cas d'utilisation de la technologie blockchain rappelle l'adoption du cloud computing. À ses débuts, le cloud était perçu comme une niche et risqué, mais aujourd'hui, ses avantages inhérents aux entreprises sont évidents. Actuellement, 60 % des données des entreprises sont stockées dans le cloud, et neuf grandes entreprises sur dix adoptent une architecture multi-cloud. Une étape du processus. Le système financier en ligne que Circle est en train de construire n'est pas destiné à concurrencer le cloud, mais à le compléter. En effet, les réseaux blockchain apportent des données, des transactions et des capacités de calcul fiables à l'Internet public.",
          "À mesure que de plus en plus d'entreprises adoptent Web3 sur des blockchains de niveau entreprise, nous sommes à l'aube de la prochaine étape de cette évolution.",
          "API de la monnaie numérique USDC : L'USDC est essentiellement une API de dollars sur un réseau public. C'est un composant open-source bien régulé, facilement intégrable dans d'autres projets fintechs, bancaires et de monnaies numériques, permettant des transactions quasi instantanées et presque gratuites libellées dans la monnaie la plus utilisée au monde. À une époque où les piles fintech se développent rapidement et où les API deviennent omniprésentes, cela ouvre des opportunités immenses.",
          "Conçu pour l'interopérabilité blockchain, l'USDC est disponible sur 15 réseaux blockchain. Avec le temps, notre objectif est de rendre l'USDC disponible partout où les développeurs sont actifs et prennent les mesures de sécurité appropriées, afin qu'il puisse circuler facilement et en toute sécurité au sein de l'écosystème blockchain.",
          "En parallèle, nous travaillons à rendre la complexité de la blockchain invisible pour les utilisateurs. Le protocole de transfert inter-chaînes (CCTP) récemment lancé aide à réduire les frictions, améliorer la sécurité et diminuer les coûts lors du transfert de l'USDC d'une blockchain à une autre. Dans un avenir proche, notre objectif est de supporter le CCTP sur chaque blockchain où l'USDC est déployé, permettant ainsi d'envoyer des dollars sans couture à travers Internet, sans les défis liés aux transferts entre blockchains.",
          "Faciliter la blockchain pour les développeurs",
          "Des milliers de développeurs sont déjà actifs sur les réseaux blockchain, et nous prévoyons que ce chiffre augmentera rapidement dans les prochaines années. Circle propose des portefeuilles programmables, des modèles de contrats intelligents et d'autres services pour soutenir l'écosystème et simplifier le processus de création de nouvelles applications sur les réseaux blockchain. Les entreprises qui ont déjà atteint l'échelle peuvent facilement intégrer l'USDC et d'autres actifs numériques dans leur expérience client existante, sans avoir à reconstruire à partir de zéro."      
      ],
      7: [
          "Téléchargez vos dollars",
          "USDC est un dollar numérique, également appelé stablecoin, disponible 24/7 et se déplaçant à la vitesse de l'internet. USDC existe sur internet et fonctionne sur de nombreuses des blockchains les plus avancées au monde. Des milliards de dollars d'USDC sont échangés chaque jour1, chaque dollar USDC étant échangé à un taux de 1:1 contre des dollars.",
          "Toujours ouvert. Toujours disponible.",
          "USDC transcende les frontières et les horaires bancaires. En tant que dollar numérique ayant un impact mondial, USDC peut être utilisé partout et à tout moment, là où vous en avez besoin. Il est facile d'envoyer de l'USDC partout dans le monde, de payer pour des biens et services ou d'épargner pour l'avenir. Toute personne connectée à Internet peut envoyer, recevoir et conserver de l'USDC.",
          "Flux de trésorerie instantané. Toute la journée. Tous les jours.",
          "Fini les attentes pour terminer une transaction et retirer des fonds. USDC rend le concept de temps de règlement obsolète, et les paiements peuvent faire le tour du monde et arriver sur votre compte aussi rapidement qu'un e-mail. Imaginez que des transactions de change, quelle que soit leur taille, puissent être réglées en quelques secondes – c'est la puissance de l'USDC.",
          "Transfert d'argent économique",
          "Utiliser de l'argent ne devrait pas coûter de l'argent. Dites adieu aux frais de 50 $ pour un virement bancaire – et bonjour aux centimes. Les commerçants peuvent éviter de payer des frais de carte de crédit de 2,9 % et bénéficier d'une trésorerie instantanée, tout en répercutant les économies réalisées sur leurs clients.",
          "Réinventer la finance",
          "USDC élève la capacité de l'argent à un nouveau niveau. Avec USDC, accédez aux opportunités de trading, de prêt et de levée de fonds dans les marchés de capitaux cryptographiques à l'échelle mondiale. Les possibilités viennent juste de commencer.",
          "Plateforme monétaire ouverte",
          "Utilisez l'API uber-money, ouverte, interopérable et gratuite, pour construire votre produit. Les développeurs font confiance à USDC comme élément central pour leurs applications, offrant des paiements instantanés, des transactions et des services financiers. Réalisez de grandes ambitions avec USDC.",
          "USDC transforme le secteur financier",
          "USDC fait partie d'un écosystème mondial qui couvre à la fois les entreprises traditionnelles et cryptographiques. USDC, créé par la fintech régulée Circle, est un dollar numérique fiable, largement accepté et très liquide.",
          "Stabilité de confiance",
          "USDC peut toujours être échangé 1:1 contre des dollars. Les réserves d'USDC sont gérées et conservées par des institutions financières de premier plan aux États-Unis telles que BlackRock et BNY Mellon.",
          "Consultez les certifications mensuelles, garantissant que les réserves d'USDC sont égales ou supérieures à la circulation mensuelle, selon les normes de certification établies par l'AICPA (American Institute of Certified Public Accountants).",
          "Tout comme PayPal, Stripe et Apple Pay, Circle est une institution de transmission de monnaie autorisée par les lois des États-Unis. Les états financiers de Circle sont audités chaque année.",
          "USDC est omniprésent",
          "Choisissez votre parcours d'entrée dans le monde du dollar numérique. Découvrez toutes les manières dont les entreprises, les utilisateurs et les développeurs utilisent l'USDC – ou obtenez de l'USDC dès maintenant.",
          "Entreprises",
          "Fournissez un flux de trésorerie instantané grâce aux paiements USDC ultrarapides et accélérez votre croissance mondiale. Accédez à la liquidité USDC pour effectuer des transactions, prêter et investir sur les marchés de capitaux cryptographiques.",
          "Écosystème",
          "Vivez la monnaie de la prochaine génération avec USDC. Envoyez, consommez, conservez et échangez des dollars numériques 24/7 à la vitesse du web avec n'importe qui, partout dans le monde.",
          "Développeurs",
          "Intégrez dès maintenant USDC dans vos applications – USDC est un dollar numérique programmable, open-source et composable que tout le monde peut utiliser pour construire.",
          "Pourquoi les leaders choisissent USDC",
          "Que ce soit pour la transparence ou la programmabilité, USDC est conçu pour l'avenir.",
          "Découvrez pourquoi les innovateurs du Consensus 2023 utilisent Circle pour leurs constructions",
          "Nous avons demandé à nos partenaires pourquoi ils ont choisi Circle et USDC, et la réponse forte est que Circle et USDC sont construits différemment. Circle rend les choses rapides, faciles à utiliser et à mettre en œuvre, tandis que ses réserves font ressortir USDC dans le secteur des stablecoins.",
          "Découvrez comment les fondateurs de Circle Ventures utilisent USDC",
          "Bien que la confiance et la transparence semblent être des paris, c'est la raison principale pour laquelle les fondateurs des entreprises du portefeuille de Circle Ventures choisissent USDC. D'autres raisons incluent sa liquidité programmable, ses vitesses de règlement presque instantanées, son efficacité, etc.",
          "Établir des normes législatives pour les stablecoins",
          "USDC est souvent considéré comme un modèle pour la conception de monnaies numériques régulées. Au plus haut niveau, Circle collabore étroitement avec les leaders du Congrès américain pour combiner les valeurs ouvertes de Web3 avec le système financier traditionnel, contribuant à créer notre avenir harmonieux et interconnecté.",
          "USDC est-il une cryptomonnaie ?",
          "USDC est un stablecoin entièrement adossé, un type de cryptomonnaie ou d'actif numérique. Contrairement aux cryptomonnaies volatiles, USDC est conçu pour maintenir un équivalent stable à 1 USD. USDC est un moyen stable de stocker de la valeur, bénéficiant de la vitesse et de la sécurité de la technologie blockchain.",
          "Comment puis-je être sûr qu'USDC peut toujours être échangé 1:1 contre des dollars ?",
          "USDC est un dollar numérique soutenu à 100 % par des liquidités et des actifs équivalents en liquidités et peut toujours être échangé 1:1 contre des dollars. Une partie des réserves d'USDC est investie dans le Circle Reserve Fund (USDXX), un fonds monétaire régulé par la SEC et géré par BlackRock. Les rapports indépendants quotidiens sur le portefeuille sont publics. Consultez notre page de transparence pour plus d'informations.",
          "Que signifie « frapper » ou « brûler » des USDC ?",
          "Les entreprises peuvent ouvrir un compte Circle Mint pour échanger des dollars contre de l'USDC. Lorsqu'une entreprise dépose des dollars sur son compte Circle Mint, Circle émet l'USDC équivalent à l'entreprise. Ce processus est appelé « frappe ». Il crée de l'USDC qui entre en circulation.",
          "De même, lorsqu'une entreprise souhaite échanger son USDC contre des dollars, elle peut déposer son USDC sur son compte Circle Mint et demander à recevoir des dollars gratuitement. Le processus de rachat des USDC est appelé « combustion ». Ce processus retire l'USDC de la circulation.",
          "Que faut-il pour accéder à USDC ?",
          "Un moyen rapide et facile pour les entreprises d'accéder à USDC est de demander un compte Circle Mint.",
          "Les entreprises peuvent également accéder rapidement à USDC via des plateformes d'échange d'actifs numériques telles que Coinbase, Crypto.com et Kraken.",
          "Les développeurs peuvent commencer à construire sur USDC dès aujourd'hui en accédant à notre dépôt GitHub. Notre page développeur USDC propose d'autres ressources.",
          "Sur quelles blockchains USDC peut-il être utilisé ?",
          "USDC est disponible sur 15 blockchains : Algorand, Arbitrum, Avalanche, Base, Ethereum, Flow, Hedera, NEAR, Noble, OP Mainnet, Polkadot, Polygon PoS, Solana, Stellar et TRON, et il y en aura d'autres cette année et dans le futur.",
          "USDC est également accessible sur de nombreuses nouvelles blockchains via des ponts tiers, créant ainsi des versions de l'USDC, telles que USDC.e.",
          "Pour plus de détails, consultez notre page développeur USDC.",
          "En quoi USDC diffère-t-il d'une CBDC (monnaie numérique de banque centrale) ?",
          "USDC est émis par la société privée Circle, tandis que les CBDC seront émises par des gouvernements. Bien que la plupart des CBDC soient encore en phase de recherche, USDC existe déjà et est largement utilisé par des millions de personnes dans le monde entier. La technologie développée par Circle permet à l'USDC de fonctionner sur 11 blockchains publiques, et l'innovation des marchés privés et open-source fait avancer rapidement le modèle des monnaies numériques basées sur le dollar.",
          "Découvrez les perspectives de Circle sur les discussions concernant les CBDC de la Réserve fédérale."
      ],
      8: [
          "Qu'est-ce que la pièce de monnaie en dollars (USDC) ?",
          "Un contrat intelligent est un protocole numérique auto-exécutable qui permet à deux parties ou plus d'échanger de l'argent, des biens, des actions ou toute autre chose de valeur de manière transparente et sans conflit, tout en évitant l'intermédiaire.",
          "De manière simple, vous pouvez considérer un contrat intelligent comme un distributeur automatique pour des transactions complexes.",
          "Le fonctionnement d'un contrat intelligent repose sur des instructions simples sous forme de « Si/Quand... alors... », qui sont écrites dans le code sur la blockchain. Lorsqu'une condition prédéfinie est remplie et vérifiée, le réseau informatique exécute l'action. Ces actions peuvent inclure la distribution de fonds aux parties appropriées, l'enregistrement de véhicules, l'envoi de notifications ou la délivrance d'amendes. Lorsque la transaction est terminée, la blockchain est mise à jour. Cela signifie que la transaction ne peut pas être modifiée et que seuls les participants autorisés peuvent voir les résultats.",
          "Grâce aux contrats intelligents, vous pouvez simplement insérer des bitcoins dans un distributeur automatique (c'est-à-dire un registre) et tout le reste, comme la gestion des fonds, des contrats ou tout autre élément nécessaire, se fait automatiquement dans votre compte. Le contrat intelligent se charge de vérifier si les conditions sont remplies.",
          "Un contrat intelligent définit les règles et les sanctions d'un accord de la même manière qu'un contrat traditionnel et exécute automatiquement ces obligations.",
          "Comment les contrats intelligents génèrent-ils des revenus ?",
          "Les développeurs de contrats intelligents ont principalement deux méthodes en chaîne pour monétiser leur travail : activer des fonctionnalités de double profit nécessaires à l'utilisation du protocole et envoyer 90 % des récompenses reçues par la blockchain aux utilisateurs qui signent le contrat intelligent. Les contrats intelligents prélèvent des commissions doubles.",
          "Par exemple : Un contrat intelligent fonctionne de manière similaire à un dépôt bancaire à taux d'intérêt doublé.",
          "Les contrats intelligents comportent-ils des risques ?",
          "Les contrats intelligents sont des programmes exécutés automatiquement sur une plateforme blockchain, révolutionnant les secteurs en automatisant les processus et permettant des transactions sans avoir besoin de confiance. Jusqu'à présent, nous n'avons pas rencontré de cas de risques associés aux contrats intelligents.",
          "D'accord, mais où se trouvent les contrats intelligents ? Où sont-ils exécutés ? Comment fonctionnent-ils ?",
          "Ce que vous devez savoir",
          "Un contrat intelligent est une séquence de codes auto-exécutables qui vérifie et exécute automatiquement les termes d'un accord entre un acheteur et un vendeur via un réseau informatique.",
          "Nick Szabo, un scientifique américain de l'informatique, a inventé une monnaie virtuelle appelée « Bit Gold » en 1998, et a défini les contrats intelligents comme des accords commerciaux automatisés qui exécutent les termes d’un contrat.",
          "Les contrats intelligents déployés sur la blockchain rendent les transactions traçables, transparentes et irréversibles.",
          "Les avantages des contrats intelligents : précision, rapidité et efficacité",
          "Lorsque les conditions sont remplies, le contrat s'exécute immédiatement.",
          "Étant numérisés et automatisés, les contrats intelligents ne nécessitent aucune paperasse.",
          "Il n'est pas nécessaire de passer du temps à corriger les erreurs possibles dues à des documents remplis manuellement.",
          "Confiance et transparence",
          "Aucun risque de falsification d'informations pour des gains personnels, car aucun intermédiaire n'est impliqué et les participants échangent des journaux de transactions cryptés.",
          "Les contrats intelligents sont vérifiés, exécutés et appliqués par des programmes informatiques fonctionnant sur un réseau blockchain. Lorsque les deux parties conviennent des termes, le programme s'exécute automatiquement, éliminant le besoin d'un tiers, car le contrat est validé et exécuté par le réseau blockchain.",
          "Les contrats intelligents, étant exécutés par des codes et non par des humains, éliminent la possibilité d'erreurs humaines et peuvent automatiser de nombreuses tâches qui nécessiteraient autrement une interaction humaine.",
          "L'un des meilleurs aspects de la blockchain est qu'il s'agit d'un système décentralisé, réparti entre toutes les parties autorisées, ce qui élimine le besoin de payer des intermédiaires, économisant ainsi du temps et réduisant les conflits.",
          "Bien sûr, la blockchain a ses problèmes, mais il est indéniable qu'elle est plus rapide, moins coûteuse et plus sécurisée que les systèmes traditionnels. C'est pourquoi nous voyons de plus en plus de contrats intelligents s'exécuter sur différentes blockchains, notamment Ethereum, Solana, Tezos, Hyperledger, etc.",
          "Rechargez votre portefeuille",
          "Créez des expériences innovantes avec NFT, protocoles DeFi, USDC, etc. En intégrant n'importe quel contrat intelligent, ajoutez plus de fonctionnalités à votre portefeuille.",
          "Améliorez la fidélité et l'engagement des clients",
          "Créez des expériences nouvelles et uniques pour interagir avec vos clients et accroître la fidélité, telles que des distributions NFT, des points de fidélité ou des jetons échangeables contre des expériences réelles.",
          "Échangez, stakez ou bridgez",
          "En intégrant des contrats intelligents, intégrez de manière transparente des protocoles DeFi dans votre application pour combler l'écart avec DeFi.",
          "Évoluez avec USDC",
          "Avec les contrats intelligents, activez et gérez facilement des transactions programmées en USDC, comme vos fiches de paie, abonnements ou paiements.",
          "Améliorez rapidement les interactions Web3 dans votre application",
          "Vitalik Buterin parle des contrats intelligents",
          "Comme l'explique Vitalik Buterin, le créateur d'Ethereum, lors du sommet blockchain à Washington DC, « Dans l'approche des contrats intelligents, des actifs ou de l'argent sont transférés dans un programme qui exécute ce code, et lorsqu'une condition est remplie, il détermine automatiquement si l'actif doit aller à une personne ou être retourné à une autre, ou encore être retourné immédiatement à la personne qui a envoyé l'actif, ou une combinaison des deux.",
          "En même temps, le livre décentralisé conserve et duplique les documents, ce qui lui confère une certaine sécurité et immutabilité.",
          "Exemple : Dépôt séquestre",
          "Supposons que vous louiez un appartement chez moi.",
          "Vous pouvez le faire via blockchain avec des paiements en cryptomonnaie. Vous recevrez un reçu conservé dans notre contrat virtuel ; je vous fournirai une clé numérique qui arrivera avant la date spécifiée. Si la clé n'arrive pas à temps, la blockchain procédera à un remboursement.",
          "Si j'envoie la clé avant la date de location, la fonction la retiendra et libérera les frais et la clé à vous et à moi lorsque la date de location arrivera.",
          "Le système fonctionne selon le principe du « Si... alors » et est observé par des centaines de personnes, vous pouvez donc vous attendre à une livraison parfaite.",
          "Si je vous donne la clé, je serai payé. Si vous envoyez un certain montant de bitcoins, vous recevrez la clé. Le document sera annulé après l'heure spécifiée, et aucun de nous ne pourra interférer avec le code sans que l'autre ne le sache, car tous les participants recevront une alerte simultanément.",
          "Identifier des cas d'utilisation réels des contrats intelligents",
          "Jerry Cuomo, vice-président de la blockchain chez IBM, estime que les contrats intelligents peuvent être utilisés tout au long de la chaîne, des services financiers à la santé en passant par l'assurance. Voici quelques exemples.",
          "Applications de finance décentralisée",
          "Alors que nous voyons les applications DeFi devenir courantes, notre façon de gérer les fonds subit un changement radical.",
          "La finance décentralisée est l'abandon des services et réglementations bancaires traditionnels. C'est un changement subtil, mais il semble inévitable.",
          "Nous avons des données à l'appui : en 2020, le volume annuel des transactions DeFi a été multiplié par 14, et en 2021, il a été multiplié par quatre, atteignant 112,07 milliards de dollars, avec une valeur totale verrouillée dans les contrats intelligents DeFi dépassant les 20 milliards de dollars.",
          "Les contrats intelligents DeFi facilitent l'échange de biens, de services, de données, de fonds, etc. Les utilisateurs des institutions financières centralisées comme les banques et les coopératives de crédit dépendent d'intermédiaires pour exécuter des transactions. Les DApp, elles, utilisent des contrats intelligents pour garantir que chaque opération est authentique, transparente et sans erreur humaine.",
          "En seulement quelques années, les NFT ont explosé sur le marché, atteignant une valeur de marché stupéfiante de 40,9 milliards de dollars en 2021, devenant ainsi l'un des cas d'utilisation les plus réussis des contrats intelligents.",
          "Les NFT sont créés lors du processus de frappe, nécessitant le déploiement de contrats intelligents sur la blockchain.",
          "Un contrat intelligent permet d'exécuter l'accord de vente entre le propriétaire d'un NFT et l'acheteur. Le contrat contient des informations sur le NFT, y compris son auteur, son prix, sa provenance et d'autres détails pertinents.",
          "En savoir plus sur les NFT et les contrats intelligents",
          "Assurance",
          "Un exemple intéressant d'application des contrats intelligents dans le secteur de l'assurance est l'utilisation de contrats pour l'activation de primes en cas de conditions météorologiques extrêmes.",
          "Si un contrat intelligent est activé sur la blockchain, il peut se déclencher de manière automatique en cas de conditions spécifiques, telles qu'une température maximale ou un nombre spécifique de jours sans pluie, pour activer un paiement.",
          "Assurance santé : En intégrant des contrats intelligents, les assurés et les assureurs peuvent faciliter le remboursement des frais médicaux en validant automatiquement les demandes de remboursement en fonction des termes définis dans le contrat, tout en réduisant la fraude.",
          "Il en va de même pour l'assurance des biens et des entreprises. Un exemple serait l'automatisation de l'indemnisation en cas de sinistre grâce à un contrat intelligent.",
          "Suivi de la chaîne d'approvisionnement",
          "Les contrats intelligents peuvent être utilisés pour améliorer le suivi des chaînes d'approvisionnement.",
          "Ils permettent de garantir que les produits sont achetés et vendus conformément aux termes spécifiés et aident à surveiller les conditions d'expédition et de stockage, en vérifiant que les conditions de température et d'humidité sont respectées, par exemple.",
          "Les contrats intelligents apportent la transparence et la sécurité nécessaires pour empêcher les violations de contrats tout au long de la chaîne d'approvisionnement.",
          "Événements et gestion de billets",
          "Les contrats intelligents peuvent être utilisés dans la gestion des billets pour des événements. Par exemple, les organisateurs peuvent créer des billets numérisés qui sont associés à un contrat intelligent. Lorsqu'un billet est acheté, le contrat intelligent valide automatiquement la transaction et assure que l'événement se déroulera comme prévu.",
          "Les participants peuvent accéder aux billets via une application ou un portefeuille numérique, et la blockchain garantit que le billet est valide et authentique.",
          "Si des problèmes surviennent (par exemple, un événement annulé), le contrat peut être exécuté pour effectuer un remboursement ou apporter une autre solution en fonction des termes de l'accord."
      ],
      9: [
          "Les NFT transforment l'art numérique, les objets de collection sportifs, les médias, etc.",
          "Cet écosystème est en train de changer radicalement la manière de créer, distribuer et collectionner les biens numériques.",
          "Le domaine des NFT se développe rapidement :",
          "Blockchain",
          "Le lieu où les NFT sont hébergés et frappés.",
          "Marché",
          "L'endroit où les NFT sont listés et échangés.",
          "Projets NFT",
          "Des œuvres génératives à l'immobilier virtuel, etc.",
          "Mais avant d'explorer cet écosystème, définissons d'abord ce que sont les NFT.",
          "Calculer la non-fongibilité de Qut",
          "Que sont les NFT ?",
          "Les NFT sont des tokens protégés par cryptographie dont l'historique de propriété et le propriétaire actuel sont enregistrés sur la blockchain.",
          "Ils offrent un marché de premier et de second niveau puissant pour les produits numériques, éliminant les intermédiaires et permettant aux créateurs de maximiser la valeur de leurs œuvres.",
          "Fongible vs Non-fongible",
          "Non-fongible",
          "La fongibilité fait référence à la capacité de remplacer une unité d'un bien par une autre, avec une valeur indiscernable.",
          "Les devises comme le dollar sont fongibles. Chaque dollar peut être échangé et a la même valeur qu'un autre dollar.",
          "Les tokens non-fongibles (NFT) ou les biens ont des caractéristiques uniques qui leur attribuent une valeur différente des autres tokens ou biens similaires.",
          "La valeur d'une maison avec terrasse est différente et ne peut pas être échangée contre une maison sans terrasse.",
          "Une terrasse peut être l'un des nombreux attributs différents qui rendent la maison non-fongible et lui donnent une valeur différente.",
          "De même, un NFT aura des caractéristiques artistiques ou d'autres attributs uniques qui le rendent différent des autres tokens, lui attribuant ainsi une valeur unique.",
          "Cryptopunks est une collection de 10 000 NFT représentant des punks en pixel art, aucun n'étant identique.",
          "Les caractéristiques de rareté et les différentes propriétés rendent certains punks plus rares et plus précieux.",
          "Bien que la rareté des attributs détermine souvent l'évaluation, l'esthétique et les préférences subjectives des investisseurs et des collectionneurs influencent également cette évaluation.",
          "Il y a 78 punks avec l'attribut 'crocs', tandis qu'il y a 259 punks avec un 'sweat à capuche', et les punks avec un 'sweat à capuche' valent trois fois plus que ceux avec 'crocs'.",
          "Source : Larya Labs",
          "Valeur en USD basée sur un prix ETH de 4 430 USD",
          "Valeur des punks au 29 novembre 2021",
          "Des plates-formes de frappe puissantes",
          "Les plateformes blockchain qui hébergent les NFT.",
          "Capitalisation boursière",
          "La plupart de ces blockchains utilisent un consensus de preuve d'enjeu, consommant beaucoup moins d'énergie que la preuve de travail.",
          "Ethereum passera à la preuve d'enjeu quelque part l'année prochaine.",
          "Tezos possède une communauté NFT dédiée et est l'une des premières plateformes NFT non basées sur Ethereum.",
          "Source : Messari.io",
          "Au 29 novembre 2021",
          "Explorer les standards des tokens Ethereum",
          "Ethereum est la plus grande plateforme pour les NFT, les NFT représentant une grande partie du trafic et de l'activité du réseau.",
          "Avec le token ERC-721 :",
          "Le standard de contrat ERC-20 est utilisé pour créer des tokens fongibles et est le plus couramment utilisé sur tout le réseau Ethereum.",
          "Le standard ERC-1155, créé par Enjin, permet de créer des actifs fongibles et non-fongibles.",
          "ERC-998 est une extension de l'ERC-721 qui permet aux NFT de posséder d'autres NFT et des tokens ERC-20.",
          "Les standards de tokens NFT d'Ethereum peuvent interagir avec des dapps (applications décentralisées) également basées sur la blockchain Ethereum, permettant une intégration fluide.",
          "Les NFT Ethereum Name Service changent le nom de votre portefeuille Ethereum en une adresse '.eth' choisie, offrant un nom d'adresse unique et plus facile à retenir.",
          "Ces NFT sont achetés sur une base annuelle, semblables à des noms de domaine Internet.",
          "Un vaste marché secondaire",
          "Il existe actuellement de nombreux marchés secondaires pour acheter et vendre des NFT.",
          "OpenSea est devenu la plateforme dominante. En novembre 2021, le volume des transactions s'élevait à 1,9 milliard USD.",
          "L'état actuel des NFT",
          "Art numérique, objets de collection, jeux, etc.",
          "Les NFT ne sont pas seulement une preuve numérique de propriété, ils peuvent également être liés à des actifs ou des avantages physiques.",
          "Le dernier album de Kings of Leon, 'When You See Yourself', a été publié avec une série de NFT limités, liés à des actifs physiques et des avantages.",
          "Un NFT à 50 AUD (35,70 GBP) permet au propriétaire d'accéder au téléchargement numérique de l'album, au vinyle physique et à des articles numériques.",
          "Six NFT 'Golden Tickets' permettent aux détenteurs d'accéder à quatre places en avant-première lors de n'importe quel concert du groupe à vie.",
          "La série NFT 'The Currency' de l'artiste britannique Damien Hirst explore comment les gens évaluent les NFT et l'art physique.",
          "La 'Currency' est un ensemble de 10 000 NFT uniques, chacun ayant une œuvre physique correspondante créée en 2016.",
          "Les œuvres physiques sont stockées dans un coffre au Royaume-Uni, et les acheteurs des NFT ont un an (jusqu'au 27 juillet 2022) pour choisir de conserver l'un ou l'autre : le NFT ou la version physique. Une fois la décision prise, l'autre version sera détruite.",
          "Actuellement, il y a trois grands secteurs dans le domaine des NFT, chacun contribuant à son propre type d'innovation :",
          "Des collections d'avatars aux œuvres d'art génératives et aux projets musicaux collaboratifs, les NFT favorisent l'épanouissement de l'art numérique. Ce ne sont pas seulement des NFT d'art visuel, mais aussi de musique et de sport.",
          "Le secteur des jeux NFT est innovant, avec divers projets explorant la propriété des actifs en jeu et les modèles de 'play-to-earn'.",
          "Les NFT redéfinissent la propriété, l'identité et l'espace numériques, en tokenisant des terres virtuelles dans des environnements en réalité virtuelle et des avatars 3D représentant leurs propriétaires.",
          "Au-delà du numérique.",
          "Bien que les NFT aient connu une explosion de popularité, leur prospérité est loin d'être terminée.",
          "En dehors de ces secteurs, les NFT continuent d'être explorés comme un moyen de tokeniser et de transférer des actifs réels (comme des maisons ou des œuvres d'art physiques) de manière numérique.",
          "Presque n'importe quel actif ou bien physique peut être tokenisé pour faciliter la preuve et l'échange de la propriété, tout en ouvrant des possibilités telles que la fractionnement, la mise en gage et l'utilité des contrats intelligents.",
          "Dresser l'écosystème NFT",
          "Les NFT étaient le sujet le plus chaud de 2021 et le marché le plus spéculatif, avec des ventes augmentant de 100 fois, devenant même un sujet de discussion dans les talk-shows du soir.",
          "Les crypto-monnaies ont mis près de dix ans pour s'implanter dans le grand public, mais les NFT ont attiré l'attention du public en quelques années seulement. Avec des marques comme Budweiser, Visa et Adidas entrant dans cet espace, il est clair que les NFT ne sont pas seulement une tendance passagère.",
          "Ce graphique sponsorisé par Next Decentrum définit les NFT et explore l'écosystème en plein essor qui se développe autour des NFT. Découvrez ce que signifie la non-fongibilité, où frapper et échanger des NFT et l'avenir de cette classe d'actifs.",
          "Que sont les NFT, et qu'est-ce que la fongibilité ?",
          "Les NFT sont des tokens non-fongibles, dont l'historique de propriété et la propriété actuelle sont enregistrés de manière cryptographique sur la blockchain. Ces tokens peuvent représenter n'importe quoi, qu'il s'agisse d'une œuvre d'art numérique en jpeg ou d'une chanson en fichier mp3.",
          "En stockant les transactions de ces tokens sur la blockchain, nous pouvons avoir la propriété de ces biens numériques et une preuve numérique du marché, sans nous soucier des doubles dépenses ou de la falsification des transactions et de la propriété.",
          "Comprendre la fongibilité",
          "Cela ressemble beaucoup aux crypto-monnaies, alors qu'est-ce qui rend les NFT si spéciaux ? Leur non-fongibilité. Contrairement aux crypto-monnaies comme le Bitcoin ou l'Ethereum, les tokens non-fongibles représentent des biens ou des actifs avec des caractéristiques uniques, même s'ils appartiennent à la même collection, ce qui leur permet d'avoir une valeur distincte.",
          "Fongible : des biens avec des unités interchangeables et une valeur indiscernable. Exemples : dollar, Bitcoin, tokens de bornes d'arcade.",
          "Non-fongible : des biens avec des caractéristiques uniques, ayant une valeur distincte par rapport à des biens similaires. Exemples : immobilier, peintures, NFT.",
          "La collection NFT la plus populaire, Cryptopunks, est composée de 10 000 punks en pixel art, chacun ayant des caractéristiques uniques comme des chapeaux, des lunettes, des coiffures, etc. Les combinaisons aléatoires d'attributs rares rendent chaque punk unique en valeur.",
          "La rareté et les préférences esthétiques subjectives influencent la valeur des CryptoPunks et d'autres NFT. D'autres facteurs, comme leur signification historique ou même la blockchain sur laquelle ils sont hébergés, peuvent aussi avoir un impact sur leur valeur.",
          "Comparaison des blockchains qui supportent les NFT",
          "Il existe de nombreuses blockchains différentes qui peuvent frapper et héberger des NFT, Ethereum étant actuellement la blockchain la plus utilisée en termes de capitalisation et de volume de transactions.",
          "Art numérique, jeux, métavers et l'avenir des NFT",
          "Les NFT ont fait une grande impression en 2021, offrant aux créateurs un réseau décentralisé et numérisé pour héberger et échanger leurs œuvres.",
          "Actuellement, les cas d'utilisation numériques dominent le développement des NFT, avec la propriété des actifs et biens en jeu étant l'un des cas d'utilisation principaux. Cependant, les NFT peuvent aussi tokeniser des actifs réels comme l'immobilier et les œuvres d'art, ouvrant presque des possibilités infinies pour leurs applications.",
          "Des cas d'utilisation réels commencent tout juste à être explorés, allant de l'élimination de la paperasse et des frictions bureaucratiques dans les transactions immobilières à la facilitation du fractionnement de la propriété des actifs, les NFT en sont encore à leurs débuts."      
      ],
      10: [
          "Que vous détestiez ou aimiez Web3, il est désormais très populaire, car des influenceurs technologiques tels que le capital-risqueur Marc Andreesen, l'ancien PDG de Twitter Jack Dorsey et le PDG de Tesla Elon Musk en débattent actuellement. Les défenseurs de Web3 croient également qu'il est en train de remodeler l'économie mondiale. Gartner prévoit qu'en 2030, Web3 ajoutera 1,76 trillion de dollars au PIB mondial. Cela a également ouvert de nombreuses opportunités pour les avocats spécialisés en technologie et les cabinets d'avocats dans ce domaine juridique en rapide évolution.",
          "Qu'est-ce que Web3 ?",
          "Web3 est une nouvelle plateforme basée sur la décentralisation et la technologie blockchain, possédée par les constructeurs et les utilisateurs, où les gens peuvent lier des cryptomonnaies, des NFT et le métavers à une nouvelle plateforme. Sur Web3, les individus peuvent offrir des services directement à chaque utilisateur et échanger des objets de valeur inhérents au système. Des exemples d'applications/websites/plateformes Web3 pourraient inclure un métavers fonctionnant sur une blockchain décentralisée (un univers en ligne en 3D qui combine plusieurs espaces virtuels, où les utilisateurs peuvent collaborer, se rencontrer, jouer et socialiser), plutôt que d'utiliser une banque.",
          "Web3 a-t-il besoin de protections juridiques ?",
          "Il existe une idée fausse selon laquelle les plateformes décentralisées ne sont pas régulées. De plus, les actifs numériques connaissent une croissance rapide dans de nombreuses régions du monde, et les régulateurs locaux ont mis en place des lois et des règlements pour gérer les transactions. Par exemple, le réseau de lutte contre les crimes financiers du département du Trésor américain (FinCEN) élabore des règles pour suivre les transactions en cryptomonnaies et signaler les activités suspectes. En outre, l'Australie et le Canada considèrent le Bitcoin comme un actif financier avec une valeur taxable. En Asie, le Parlement japonais a adopté une législation en juin pour réglementer les stablecoins ou les cryptomonnaies afin de protéger les investisseurs.",
          "Avec la croissance rapide du monde virtuel dans le monde entier, les préoccupations juridiques vont augmenter. En général, de nombreux utilisateurs estiment que les NFT offrent une preuve indiscutable de propriété. Cependant, les choses peuvent être plus complexes que ce que nous imaginons. Par exemple, João Marinotti, professeur de droit à l'Université de l'Indiana, affirme que la propriété des terres virtuelles relève du droit des contrats plutôt que du droit de la propriété. De plus, l'achat d'un NFT ne confère pas à l'utilisateur la propriété de l'actif numérique en question.",
          "Bien que Web3 soit un domaine nouveau et complexe, lorsqu'il y a des problèmes, une nouvelle forme de protection juridique est nécessaire. Les litiges liés aux cryptomonnaies sont en forte augmentation. Les NFT vont donner lieu à des controverses concernant la licence d'actifs et la copropriété. Aujourd'hui, les entreprises utilisent le métavers dans le cadre de leur stratégie commerciale et marketing, ce qui soulève de nombreuses questions juridiques liées à la gouvernance, à la protection de la propriété intellectuelle et aux violations de droits.",
          "Les opportunités dans Web3",
          "Si un domaine technologique veut exploiter pleinement son potentiel, il doit être intégré dans un cadre juridique approprié, incluant des droits et des obligations établis pour protéger les intérêts de toutes les parties. Les questions juridiques à résoudre incluent la fiscalité, la gouvernance virtuelle, les défis réglementaires, les licences et la propriété, le suivi et le reporting des transactions, la confidentialité des données et la protection de la propriété intellectuelle.",
          "Pour éviter de prendre du retard, les cabinets d'avocats traditionnels, les avocats et les étudiants en droit doivent comprendre l'évolution de la technologie Web3 ainsi que les lois et régulations en constante évolution liées à Web3 et aux gouvernements locaux."
      ],
      11: [
          "Comment fonctionnent les cartes de débit en cryptomonnaie ?",
          "Les cartes de débit en cryptomonnaie sont utilisées par des entreprises spécialisées dans les services financiers de cryptomonnaie, telles que Coin. Ces entreprises traitent avec des réseaux de paiement comme Visa et Mastercard, permettant aux utilisateurs d'utiliser des cryptomonnaies dans des endroits acceptant des systèmes de paiement traditionnels.",
          "Ce processus garantit que vos transactions sont sécurisées et transparentes. De plus, il offre des taux de conversion rapides, sans avoir à attendre des heures ou des jours pour finaliser la conversion.",
          "Choisir la bonne carte de débit en cryptomonnaie",
          "Dans cette section, je vais vous montrer comment obtenir une carte de débit en cryptomonnaie pour des transactions quotidiennes sans accroc. Vous pourrez convertir vos cryptomonnaies en monnaie fiduciaire et faire des achats n'importe où acceptant les cartes de débit. Je vais également vous décrire les facteurs à prendre en compte pour obtenir la meilleure carte de débit en cryptomonnaie parmi les options du marché :",
          "• Cryptomonnaies prises en charge : Pour obtenir la meilleure carte financière en cryptomonnaie, vous devez vous assurer que la carte prend en charge vos cryptomonnaies.",
          "• Frais : Les cartes de débit en cryptomonnaie facturent généralement divers frais, tels que des frais de recharge, des frais de transaction et des frais de retrait au guichet automatique. Comparez les frais de différentes cartes avant de faire votre choix.",
          "• Récompenses : Choisissez une carte de débit en cryptomonnaie avec un programme de cashback pour maximiser vos dépenses. Recherchez, comparez et choisissez la carte offrant le meilleur cashback.",
          "• Sécurité : Les cartes de débit en cryptomonnaie utilisent diverses fonctions de sécurité pour protéger vos cryptomonnaies. Choisissez la meilleure carte financière en cryptomonnaie avec des fonctionnalités de sécurité robustes.",
          "• Support client : Un des points à considérer pour obtenir la meilleure carte financière en cryptomonnaie est la qualité du support. Si le support ne peut pas vous aider lorsque vous avez un problème, vous perdrez de l'argent et du temps. Assurez-vous de choisir un support efficace.",
          "Ce sont des solutions innovantes qui vous permettent d'utiliser vos actifs numériques dans des transactions du monde réel. Grâce à la technologie blockchain, ces cartes facilitent des transactions sécurisées et transparentes, utilisables dans n'importe quel endroit acceptant les principales cartes de débit.",
          "L'un des plus grands échanges de cryptomonnaie aux États-Unis a déclaré vendredi qu'il travaillait en 'étroite collaboration' avec les processus de paiement mondiaux pour assurer un transfert numérique d'actifs sans couture et une adoption accrue.",
          "L'échange de cryptomonnaie basé à San Francisco, Coinbase, connu pour son approche réglementée des cryptomonnaies, a annoncé aujourd'hui qu'il 'était fier de collaborer avec des innovateurs comme Visa' pour promouvoir l'adoption des monnaies numériques :",
          "L'échange a déclaré que des entreprises comme Visa aident à combler le fossé entre les cryptomonnaies et les finances traditionnelles. Cela crée un environnement mature pour connecter les cryptomonnaies à leur réseau mondial tout en rassemblant des leaders des domaines traditionnels et cryptographiques.",
          "Visa élabore un programme pour les monnaies numériques",
          "Le géant des paiements Visa, d'une valorisation de 420 milliards de dollars, dont le réseau effectue plus de 25 000 transactions par seconde, a détaillé son plan cryptomonnaie dans un billet de blog plus tôt cette semaine.",
          "L'entreprise, appelée 'Faire progresser notre approche des monnaies numériques', place les actifs numériques au cœur de sa nouvelle stratégie numérique afin d'explorer de nouveaux marchés et d'améliorer l'inclusion financière mondiale.",
          "Visa a souligné qu'elle travaillait activement avec des plateformes de monnaies numériques régulées et autorisées, telles que Coinbase, pour établir un pont entre les monnaies numériques et notre réseau mondial de 61 millions de commerçants.",
          "Elle a précisé qu'il existe actuellement plus de 25 portefeuilles de monnaies numériques connectés au réseau Visa, 'offrant aux utilisateurs une méthode simple pour dépenser à partir de leurs soldes de monnaies numériques avec une carte de débit Visa ou un certificat prépayé.'",
          "D'autres développements incluent des innovations comme Visa Direct — un pont instantané entre les monnaies fiduciaires et les cryptomonnaies, permettant de convertir rapidement les monnaies numériques des consommateurs et d'ajouter ces fonds à leurs certificats Visa.",
          "Visa a déclaré que ces efforts font de la marque le choix idéal pour l'adoption des portefeuilles de monnaies numériques, qui 'cherchent à approfondir leur valeur pour les utilisateurs en facilitant l'utilisation des monnaies numériques dans le monde entier, de manière plus rapide et plus facile.'",
          "Les stablecoins, un élément clé de l'économie future",
          "Visa, dans sa stratégie plus large de lancement de cryptomonnaies dans le secteur public, a indiqué qu'elle était en contact avec les législateurs et les régulateurs pour informer les entreprises des avantages des monnaies numériques.",
          "Utiliser une carte de débit en cryptomonnaie pour effectuer des transactions",
          "Utiliser un portefeuille cryptographique avec une carte de débit est une méthode simple d'utilisation des cryptomonnaies, sans conversion et sans attendre que le processus se termine. Votre fournisseur convertira automatiquement vos cryptomonnaies en monnaie fiduciaire et effectuera le transfert.",
          "Effectuer des transactions quotidiennes avec une carte de débit en cryptomonnaie",
          "Le fonctionnement de ces cartes est similaire à celui des cartes de débit traditionnelles, vous permettant de les utiliser dans n'importe quel endroit qui accepte Visa ou Mastercard. Elles facilitent les transactions quotidiennes et offrent des avantages par rapport aux cartes traditionnelles, comme le cashback ou les programmes de récompenses en cryptomonnaies.",
          "Les caractéristiques de sécurité des cartes de débit en cryptomonnaie",
          "Les cartes financières en cryptomonnaie utilisent le cryptage, l'authentification à deux facteurs, la protection contre la fraude, l'assurance FDIC, et suivent les mêmes réglementations de sécurité que les cartes traditionnelles pour protéger vos informations personnelles et cryptographiques.",
          "Avantages de l'utilisation d'une carte de débit en cryptomonnaie",
          "Si vous obtenez une carte de débit en cryptomonnaie, vous bénéficierez de nombreux avantages, comme la commodité, les récompenses, et la pleine maîtrise de vos actifs grâce à la décentralisation."      
      ],
      12: [
          "Compte d'épargne en USDC (Stablecoin) : Gagner des intérêts sur les cryptomonnaies",
          "Web3 propose une large gamme d'options de comptes d'épargne en cryptomonnaie en constante croissance. Ces comptes d'épargne à faible risque et rendement élevé permettent aux utilisateurs de gagner jusqu'à 200 % d'intérêts en cryptomonnaies chaque mois, avec un effet composé. Le dollar coin (USDC) est l'un des stablecoins les plus utilisés dans le monde. Voici quelques informations à son sujet ainsi que des faits sur la manière de gagner facilement des intérêts en cryptomonnaies sur YouHodler.",
          "Qu'est-ce que le dollar coin (USDC) ? Comment gagner des cryptomonnaies",
          "Contrairement à de nombreuses autres cryptomonnaies, l'USDC est un type unique de cryptomonnaie appelé 'stablecoin'. L'USDC est soutenu par le dollar à un taux de 1:1. Ainsi, vous pouvez toujours échanger 1 USDC contre 1,00 USD. Cela en fait un actif plus stable que le Bitcoin ou d'autres cryptomonnaies, en faisant un endroit sûr pour stocker des actifs.",
          "L'USDC est un token basé sur Ethereum, ce qui signifie que vous pouvez le stocker dans un portefeuille compatible avec Ethereum, comme le portefeuille USDC de YouHodler. L'équipe derrière l'USDC a conçu ce stablecoin pour permettre aux utilisateurs de transférer facilement des dollars de leur portefeuille crypto vers des entreprises, des bourses et d'autres personnes à travers le monde. YouHodler l'a ajouté à notre offre en tant que superbe option pour gagner des intérêts en cryptomonnaie sur la plateforme.",
          "Compte d'épargne USDC : Pourquoi gagner des intérêts en cryptomonnaie via Web3",
          "Web3 propose de nombreuses façons de profiter des actifs numériques, et avec des comptes d'épargne en cryptomonnaie, les utilisateurs peuvent débloquer des outils d'investissement à rendement élevé et faible risque. Voici quelques raisons de gagner des intérêts en cryptomonnaies via Web3 :",
          "Gagner des intérêts sur l'USDC jusqu'à 2,6 % et 10 % : Sur Web3, vous pouvez gagner des intérêts quotidiens sur l'USDC de 2,6 % et 10 %. Cela dépasse largement les taux des comptes d'épargne bancaires traditionnels 'à haut rendement' et d'autres investissements. Web3 offre les meilleurs taux du marché sur l'USDC.",
          "Sécuriser vos actifs : Tous les fonds en cryptomonnaies sur Web3 sont protégés selon les meilleures pratiques de l'industrie. Les fonds sont toujours stockés à 100 % dans des portefeuilles chauds. Nous utilisons une méthode de stockage mixte avec des portefeuilles chauds et froids pour garantir la meilleure protection possible des fonds des utilisateurs.",
          "Les intérêts sur les cryptomonnaies sont versés toutes les 4 heures, sans frais : À la fin de chaque période de règlement de 4 heures, tous les intérêts en cryptomonnaies gagnés sont crédités sur le compte d'épargne de l'utilisateur. Les utilisateurs peuvent choisir de retirer les fonds de leur compte d'épargne vers leur portefeuille ou de laisser les fonds dans leur compte pour accumuler plus d'intérêts en cryptomonnaie dans le cycle suivant. (Notez que le nombre de retraits par jour est limité à 3, et chaque retrait doit être supérieur à 1 USDC.)",
          "Explorer d'autres façons de gagner des intérêts en cryptomonnaie via Web3",
          "Les intérêts sur l'USDC ne sont qu'une option pour gagner des intérêts en cryptomonnaies via Web3. Visitez notre page 'Gagner des intérêts' pour explorer divers stablecoins et cryptomonnaies disponibles. Vous pouvez également gagner des intérêts sur Bitcoin, Binance Coin, etc. Découvrez comment gagner des intérêts via des comptes d'épargne en cryptomonnaies et explorez des méthodes plus efficaces de détention à long terme.",
          "Application de portefeuille d'épargne payant USDC",
          "L'application de portefeuille Web3 est la seule application qui vous permet de stocker des dollars en coin (USDC) comme un portefeuille ordinaire et de gagner des intérêts sur votre solde d'USDC toutes les 4 heures.",
          "Comment fonctionnent les intérêts USDC",
          "Vous pouvez gagner des intérêts sur l'USDC de la même manière que sur un compte d'épargne bancaire traditionnel, mais avec une différence clé. Nous vous offrons des taux allant jusqu'à 10 % sur l'ETH, au lieu des taux proches de zéro des banques.",
          "Comment gagner des USDC gratuits",
          "C'est facile ! Il vous suffit de déposer des USDC dans votre portefeuille Web3 et vous commencerez à gagner de l'ETH gratuit toutes les 4 heures.",
          "Pourquoi les intérêts gagnés sont en ETH ?",
          "Lorsque vous déposez des USDC dans votre portefeuille, les intérêts que vous gagnez toutes les 4 heures sont en ETH, une cryptomonnaie différente. Cependant, l'ETH que vous gagnez sur la blockchain ne peut pas être directement retiré vers votre portefeuille. Vous devez vous connecter à Web3, et l'ETH gagné dans votre compte doit être échangé contre de l'USDC avant de pouvoir être retiré vers votre portefeuille. Voici comment échanger l'ETH gagné dans votre compte Web3 contre de l'USDC.",
          "Avant de retirer les intérêts, vous devez d'abord accéder à votre compte Web3, puis cliquer sur 'Échanger', puis cliquer sur 'Tout convertir' pour effectuer la conversion totale.",
          "L'étape suivante consiste à retirer l'USDC que vous avez échangé depuis votre compte Web3 vers votre portefeuille. Voici un guide détaillé sur le retrait d'USDC.",
          "Maintenant, vous devez cliquer sur 'Retirer', puis sur 'Solde total', et enfin cliquer sur 'Confirmer'. Votre retrait de profits est terminé. Les retraits USDC sont généralement traités dans les 10 minutes. En cas de congestion du réseau, des retards peuvent survenir.",
          "Accumuler des intérêts sur l'USDC et l'ETH",
          "Oui, si vous détenez à la fois de l'USDC et de l'ETH dans votre compte Web3, vous bénéficierez d'intérêts composés. Nous calculons les intérêts composés toutes les 4 heures.",
          "Taux de rendement journalier de l'USDC et rendements",
          "Comparé à d'autres plateformes, nous offrons des rendements quotidiens plutôt que des rendements annuels. Le taux journalier vous paie des intérêts sans l'effet de la capitalisation. Ainsi, en raison des intérêts composés, vous gagnerez plus d'USDC à la fin du mois sur Web3.",
          "Calculateur d'épargne USDC",
          "Utilisez simplement notre calculateur d'épargne USDC pour voir quels revenus vous obtiendrez après 3, 6 ou 12 mois de détention sur Web3."      
      ],
      13: [
          "Les 3 000 000 ETH de cette période proviennent des accords de prêt DeFi signés entre les mineurs et la blockchain. Selon l'accord DeFi, lorsque l'accord de prêt DeFi signé entre les mineurs et la blockchain atteint 3 000 000 ETH, la blockchain a le droit de rendre ces 3 000 000 ETH liquides sous forme de projets blockchain, afin de préserver les données de la blockchain et de refléter la valeur de l'ETH lui-même. Ainsi, la blockchain paiera des intérêts sur le capital USDC stocké par les utilisateurs, et les utilisateurs qui stockent des USDC recevront des intérêts en ETH.",
          "D'où viennent les intérêts sur 1 ETH ?",
          "Les mineurs empruntent de l'ETH sur la blockchain via un protocole DeFi. Actuellement, la blockchain a reçu 3 millions d'ETH de la part des mineurs via des accords de prêt DeFi, ce qui a permis de lancer un projet DeFi pour stocker des USDC et générer des bénéfices.",
          "Par exemple, si l'accord de prêt DeFi entre les mineurs et la blockchain atteint 3 millions d'ETH, la blockchain a le droit de rendre l'ETH mis en gage par les mineurs liquide en le combinant avec des portefeuilles Web3, afin de refléter la valeur de l'ETH lui-même.",
          "Il est également important de noter que, lorsque vous devenez un utilisateur VIP actuel de la blockchain, vous aurez la possibilité de rejoindre un prochain nœud de stockage lucratif découvert par la blockchain.",
          "Prêt DeFi en ETH",
          "Généralement élevé et déduit automatiquement de votre APY.",
          "Décidé par la plateforme – fixe.",
          "Plateforme centralisée et opaque, impossible de voir comment elle fonctionne en interne.",
          "Faible – nécessite seulement quelques clics pour compléter.",
          "Pirates informatiques, faillite de la plateforme.",
          "Les frais sont généralement faibles et clairement indiqués avec l'APY.",
          "Décidé par le marché – toujours fluctuant.",
          "Plateforme décentralisée et transparente, avec un code souvent open source.",
          "Élevé – nécessite une certaine expertise de DeFi pour comprendre le processus.",
          "Pirates informatiques.",
          "Les trois plateformes ci-dessus sont des plateformes centralisées participant à la finance centralisée (CeFi). Une autre option est le prêt DeFi en ETH, qui fonctionne comme un marché de cryptomonnaies pour les prêteurs et les emprunteurs.",
          "Les plateformes DeFi utilisent des codes pour maintenir un marché automatisé et déterminer les taux d'intérêt de prêt instantanés. Par conséquent, les taux d'intérêt sur le prêt en ETH sur DeFi fluctuent en fonction de l'offre et de la demande.",
          "L'avantage de DeFi réside dans les frais beaucoup plus bas des plateformes. De plus, comme les protocoles DeFi utilisent généralement des codes open source et sont gérés par une communauté de détenteurs de tokens, les protocoles DeFi sont beaucoup plus transparents que les prêteurs CeFi.",
          "Le prêt DeFi peut être rentable, mais il est généralement plus complexe et donc mieux adapté aux traders expérimentés.",
          "Q : D'où viennent les mineurs pour obtenir de l'ETH ?",
          "R : Les mineurs obtiennent de l'ETH par le minage de liquidité.",
          "Le minage de liquidité DeFi est le processus par lequel les utilisateurs fournissent de la liquidité aux plateformes de finance décentralisée (DeFi) en échange de récompenses. Jetons un coup d'œil de plus près à cet article pour mieux comprendre.",
          "Qu'est-ce que le minage de liquidité DeFi ?",
          "Le minage de liquidité DeFi est le processus par lequel les utilisateurs fournissent de la liquidité aux plateformes DeFi en échange de récompenses. Les fournisseurs de liquidité (LP) peuvent gagner des frais grâce aux transactions qui ont lieu dans les pools et aux tokens du protocole DeFi lui-même.",
          "Comment fonctionne le minage de liquidité DeFi ?",
          "Le minage de liquidité DeFi fonctionne grâce à un processus appelé Market Maker Automatique (AMM). L'AMM est un contrat intelligent qui crée des pools de liquidité pour différents tokens. Les partenaires limités peuvent ajouter leurs tokens aux pools de liquidité et gagner des frais grâce aux transactions dans ces pools.",
          "Les frais sont payés sous forme de tokens échangés dans le pool. Par exemple, si vous ajoutez de l'ETH et de l'USDC au pool de liquidité, vous gagnerez des frais en ETH et en USDC. Vous pouvez également gagner des tokens du protocole DeFi lui-même, généralement appelés tokens de gouvernance, qui vous donnent des droits de vote sur la plateforme.",
          "Quels sont les avantages du minage de liquidité DeFi ?",
          "Participer au minage de liquidité DeFi présente plusieurs avantages. Tout d'abord, vous pouvez gagner un revenu passif en fournissant de la liquidité à une plateforme DeFi. Ensuite, vous pouvez gagner des tokens du protocole DeFi, qui ont un potentiel d'appréciation. Troisièmement, vous contribuez à améliorer la liquidité d'une plateforme DeFi, ce qui bénéficie à tous ses utilisateurs.",
          "Le minage de liquidité DeFi est-il sûr ?",
          "Le minage de liquidité DeFi n'est pas sans risque, mais s'il vous plaît accepter ces risques, il peut devenir un moyen lucratif de générer un revenu passif. Il est essentiel de faire des recherches avant de participer à un projet de minage de liquidité DeFi et d'investir uniquement ce que vous êtes prêt à perdre.",
          "Qu'est-ce que le minage de liquidité DeFi ? Comment ça marche ? - J'espère que cet article vous a apporté des informations utiles."      
      ],
    },
    check: {
      "title": "Connexion quotidienne",
      "rules": "Règles",
      "point": "Accumulation de points",
      "signin": "Se connecter",
      "days": "Vous êtes connecté depuis ${consecutiveDays} jours consécutifs",
      "reSigning": "Se reconnecter",
      "day10": "10 jours",
      "day90": "90 jours",
      "rewards": "Récompenses",
      "day10Rewards": "Récompenses pour 10 jours consécutifs de connexion",
      "getIt": "Recevoir",
      "exchange": "Échanger",
      "exchangeSub": "Échange de points",
      "points": "Points",
      "share": "Partager la tâche",
      "shareSub": "Récompenses de la tâche de partage",
      "rank": "Classement des points"
    },
  };
  