<template>
  <div class="nav">
    
    <h3>
      <img :src="ethIcon" />
      <div>web3.0</div>
    </h3>
    <LangSelect></LangSelect>
    <van-cell-group>
      <van-cell
        :title="$t('nav.home')"
        is-link
        to="/index"
        :title-class="{ 'title-active': $route.name === 'index' }"
        @click="$emit('close')"
      >
        <template v-slot:icon>
          <img :src="$route.name === 'index' ? homeActiveIcon : homeIcon" />
        </template>
      </van-cell>

      <van-cell
        title="NFT"
        is-link
        to="/nft"
        :title-class="{ 'title-active': $route.name === 'nft' }"
        @click="$emit('close')"
      >
        <template v-slot:icon>
          <img :src="$route.name === 'nft' ? nftActiveIcon : nftIcon" />
        </template>
      </van-cell>

      <van-cell
        :title="$t('nav.trading')"
        is-link
        to="/trading"
        :title-class="{ 'title-active': $route.name === 'trading' }"
        @click="$emit('close')"
      >
        <template v-slot:icon>
          <img
            :src="$route.name === 'trading' ? tradingActiveIcon : tradingIcon"
          />
        </template>
      </van-cell>

      <van-cell
        :title="$t('nav.pool')"
        is-link
        to="/pool"
        :title-class="{ 'title-active': $route.name === 'pool' }"
        @click="$emit('close')"
      >
        <template v-slot:icon>
          <img :src="$route.name === 'pool' ? poolActiveIcon : poolIcon" />
        </template>
      </van-cell>

      <van-cell
        :title="$t('nav.loan')"
        is-link
        to="/loan"
        :title-class="{ 'title-active': $route.name === 'loan' }"
        @click="$emit('close')"
      >
        <template v-slot:icon>
          <img :src="$route.name === 'loan' ? loanActiveIcon : loanIcon" />
        </template>
      </van-cell>

      <van-cell
        :title="$t('nav.paper')"
        is-link
        to="/paper"
        :title-class="{ 'title-active': $route.name === 'paper' }"
        @click="$emit('close')"
      >
        <template v-slot:icon>
          <img :src="$route.name === 'paper' ? paperActiveIcon : paperIcon" />
        </template>
      </van-cell>
    </van-cell-group>
    
  </div>
</template>

<script>
import ethIcon from "@/assets/eth.svg";
import homeIcon from "@/assets/home.png";
import homeActiveIcon from "@/assets/home_active.png";
import nftIcon from "@/assets/nft.png";
import nftActiveIcon from "@/assets/nft_active.png";
import tradingIcon from "@/assets/trading.png";
import tradingActiveIcon from "@/assets/trading_active.png";
import poolIcon from "@/assets/pool.png";
import poolActiveIcon from "@/assets/pool_active.png";
import loanIcon from "@/assets/loan.png";
import loanActiveIcon from "@/assets/loan_active.png";
import paperIcon from "@/assets/paper.png";
import paperActiveIcon from "@/assets/paper_active.png";
import LangSelect from "@/components/LangSelect.vue";

export default {
  name: "Nav",
  components: { LangSelect },
  watch: {},
  data: function () {
    return {
      ethIcon,
      homeIcon,
      homeActiveIcon,
      nftIcon,
      nftActiveIcon,
      tradingIcon,
      tradingActiveIcon,
      poolIcon,
      poolActiveIcon,
      loanIcon,
      loanActiveIcon,
      paperIcon,
      paperActiveIcon,
    };
  },
  created: function () {},
  mounted: function () {},
  methods: {},
};
</script>

<style scoped>
.nav h3 {
  margin: 2.13333rem 0.66667rem;
  font-size: 0.85333rem;
  color: #1f4ed8;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.nav h3 img {
  height: 0.85333rem;
  margin-right: 0.13333rem;
}

.nav .van-cell {
  padding-top: 0.53333rem;
  padding-bottom: 0.53333rem;
}

.nav .van-cell .van-icon-exchange {
  font-size: 0.56rem;
  margin-right: 0.48rem;
}

.nav .van-cell img {
  width: 0.53333rem;
  height: 0.53333rem;
  margin-right: 0.48rem;
}

.nav .van-cell .van-cell__title {
  font-size: 0.48rem;
}

.nav .van-cell .title-active {
  color: #1f4ed8;
}

.lang .van-cell__title {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}

.lang .van-cell__value {
  -webkit-box-flex: 2;
  -webkit-flex: 2;
  flex: 2;
}
</style>
