const KEY = "address";

function getAddress() {
  try {
    const data = localStorage.getItem(KEY);
    let address = "";
    if (data) {
      address = JSON.parse(data).value;
    }
    return address;
  } catch (e) {
    console.log("getAddress", e);
    return "";
  }
}

function setAddress(value) {
  if (value) {
    const data = {
      key: KEY,
      value: value,
    };
    localStorage.setItem(KEY, JSON.stringify(data));
  }
}

export default {
  getAddress,
  setAddress,
};
