// 
export default class WebSocketService {
  constructor() {
    this.url = this._getUrl();
    this.onmessages = [];
    this.sendDatas = [];
    this.myWebSocket = null;
    this.ws = null;
    this.status = null;
    this.pingInterval = null;
    this._timeout = 5000;
    this._reconnectInterval = 3000;
    this.isHeart = true;
    this.isReconnection = false;
    this._reconnectIntervaler = setInterval(
      this._reconnect.bind(this),
      this._reconnectInterval
    );
  }

  // 重新连接
  _reconnect() {
    if (this.isReconnection) {
      console.log("WebSocket.reconnect");
      this.ws = new WebSocket(this.url);
      this.connect();
    }
  }

  // 获取WebSocket的URL
  _getUrl() {
    const host = window.location.host;
    return `ws://${host}/websocket/`;
  }

  // 连接WebSocket
  connect() {
    this.ws = new WebSocket(this.url);
    this.ws.onopen = (event) => {
      this.status = "open";
      this.isReconnection = false;
      console.log("连接成功", event);
      this.reSend();
      this.isHeart && this._heartCheck();
    };

    this.ws.onmessage = (event) => {
      if (((this.isReconnection = false), this.onmessages)) {
        this.onmessages.forEach((callback) => callback.call(this, event));
      }
    };

    this.ws.onclose = (event) => {
      console.log("onclose", event);
      this._closeSocket(event);
    };

    this.ws.onerror = (event) => {
      console.log("onerror", event);
      this._closeSocket(event);
    };
  }

  // 重新发送数据
  reSend() {
    this.sendDatas.forEach((data) => {
      this.ws.send(JSON.stringify(data));
    });
  }

  // 添加事件监听
  addOnmessage(callback) {
    if (typeof callback === "function") {
      const index = this.onmessages.findIndex(
        (cb) => cb.toString() === callback.toString()
      );
      if (index > -1) this.onmessages.splice(index, 1);
      this.onmessages.push(callback);
    }
  }

  // 添加发送数据
  addSendData(data) {
    const index = this.sendDatas.findIndex(
      (item) => JSON.stringify(item) === JSON.stringify(data)
    );
    if (index > -1) this.sendDatas.splice(index, 1);
    this.sendDatas.push(data);
    console.log(this.sendDatas);
  }

  // 发送数据
  send(data) {
    if (this.ws.readyState !== WebSocket.OPEN) {
      setTimeout(() => this.send(data), 1000);
    } else {
      this.ws.send(JSON.stringify(data));
      this.addSendData(data);
    }
  }

  // 心跳检查
  _heartCheck() {
    this.pingInterval = setInterval(() => {
      if (this.ws.readyState === WebSocket.OPEN) {
        this.ws.send("PING");
      }
    }, this._timeout);
  }

  // 重置心跳
  _resetHeart() {
    clearInterval(this.pingInterval);
    return this;
  }

  // 关闭WebSocket连接
  _closeSocket(event) {
    this._resetHeart();
    if (this.status !== "close") {
      this.isReconnection = true;
    } else {
      console.log("手动关闭", event);
    }
  }

  // 单例模式获取实例
  static getInstance() {
    if (!this.myWebSocket) {
      this.myWebSocket = new this();
      this.myWebSocket.removeHandlers();
    }
    return this.myWebSocket;
  }

  // 移除处理器
  removeHandlers() {
    this.onmessages = [];
    this.sendDatas = [];
  }

  // 销毁WebSocket连接
  static destroy() {
    if (this.myWebSocket) {
      this.myWebSocket.status = "close";
      this.myWebSocket.isReconnection = false;
      clearInterval(this.myWebSocket._reconnectIntervaler);
      if (this.myWebSocket.ws) this.myWebSocket.ws.close();
      this.myWebSocket = null;
    }
  }
}
