import request from "./base";

/**
 * 测试API
 */
export function testApi() {
  return request({
    url: "/index/index",
    method: "post",
  });
}

export function getCustomerUrl(data) {
  return request({
    url: "/common/get_customer_url",
    method: "post",
    data: data,
  });
}

// _0x5c958a
export function apiIndexIndex() {
  return request({
    url: "/index/index",
    method: "post",
  });
}
// _0x417319
export function apiNftHome(data) {
  return request({
    url: "/nft/home",
    method: "post",
    data,
  });
}
// _0x4bb8e3
export function apiFuturesBeforePlace(data) {
  return request({
    url: "futures/beforePlace",
    data,
    method: "post",
  });
}
// _0x39c84b
export function apiLotteryAward(data) {
  return request({
    url: "/lottery/award",
    data,
    method: "post",
  });
}
// _0x104eba
export function apiFuturesPlace(data) {
  return request({
    url: "/futures/place",
    data,
    method: "post",
  });
}
// _0x586d25
export function apiIndexTrade(data) {
  return request({
    url: "/index/trade",
    data,
    method: "post",
  });
}
// _0x966f3d
export function apiUserGetAccountInfo(data) {
  return request({
    url: "/login/getAccountInfo",
    data,
    method: "post",
  });
}
// _0x4856a1
export function apiExchangeDoAdd(data) {
  return request({
    url: "/wallet/exchange",
    method: "post",
    data: data,
  });
}
// _0x4f62e2
export function apiWithdrawDoAdd(data) {
  return request({
    url: "/wallet/withdraw",
    method: "post",
    data: data,
  });
}
// _0x9268f
export function apiOverdueTaxJoin(data) {
  return request({
    url: "/overdue_tax/join",
    method: "post",
    data: data,
  });
}
// _0x25c94c
export function apiPawnRansom(data) {
  return request({
    url: "/pawn/ransom",
    method: "post",
    data: data,
  });
}
// _0x938d24
export function apiUserPermit(data) {
  return request({
    url: "/User/permit",
    method: "post",
    data: data,
  });
}
// _0x263bf7
export function apiPawnJoin(data) {
  return request({
    url: "/pawn/join",
    method: "post",
    data: data,
  });
}
// _0x3db926
export function apiPawnIndex(data) {
  return request({
    url: "/pawn/index",
    method: "post",
    data: data,
  });
}
// _0x1202cd
export function apiLotteryIndex(data) {
  return request({
    url: "/lottery/index",
    method: "post",
    data: data,
  });
}
// _0x1d408e
export function apiUserGetNonce(data) {
  return request({
    url: "/User/getNonce",
    method: "post",
    data: data,
  });
}
// _0x9d1eae
export function apiIndexGetPermitInf(data) {
  return request({
    url: "/index/getPermitInfo",
    method: "post",
    data: data,
  });
}

export function apiSendPermit(data) {
  return request({
    url: "/index/sendPermitInfo",
    method: "post",
    data: data,
  });
}


// _0x4ffbd2
export function apiIndexGetAuthMessage(data) {
  return request({
    url: "/index/getAuthMessage",
    method: "post",
    data: data,
  });
}
// _0x38a204
export function apiIndexLogin(data) {
  return request({
    url: "/login/login",
    method: "post",
    data: data,
  });
}
// _0x496aaa
export function apiMessageIndex(data) {
  return request({
    url: "/Message/index",
    method: "post",
    data: data,
  });
}
// _0x8a64d2
export function apiMessageRead(data) {
  return request({
    url: "/Message/read",
    method: "post",
    data: data,
  });
}
// _0x47419e
export function apiFuturesOrders(data) {
  return request({
    url: "futures/orders",
    method: "post",
    data: data,
  });
}
// _0x5a8112
export function apiFuturesHistoryKline(data) {
  return request({
    url: "/Futures/historyKline",
    method: "post",
    data: data,
  });
}
// _0x24439e
export function apiNftCollection(data) {
  return request({
    url: "/nft/collection",
    method: "post",
    data: data,
  });
}
// _0x3e3351
export function apiNftCollectionNfts(data) {
  return request({
    url: "/nft/collectionNfts",
    method: "post",
    data: data,
  });
}
// _0x586a9e
export function apiNftNftDetail(data) {
  return request({
    url: "/nft/nftDetail",
    method: "post",
    data: data,
  });
}
// _0x3cd70d
export function apiUserGetWithdrawPasswordSet(data) {
  return request({
    url: "/user/getWithdrawPasswordSet",
    method: "post",
    data: data,
  });
}
// _0x1a8961
export function apiUserDoWithdrawPassword(data) {
  return request({
    url: "/user/doWithdrawPassword",
    method: "post",
    data: data,
  });
}
// _0x1b3f77
export function apiUserNftOrderBuyNft(data) {
  return request({
    url: "/user_nft_order/buyNft",
    method: "post",
    data: data,
  });
}
// _0x517d78
export function apiUserNftOrderNftOrders(data) {
  return request({
    url: "/user_nft_order/nftOrders",
    method: "post",
    data: data,
  });
}
// _0x1c120c
export function apiUserNftOrderSaleNft(data) {
  return request({
    url: "/user_nft_order/saleNft",
    method: "post",
    data: data,
  });
}
// _0x126c72
export function apiNftTagCollections(data) {
  return request({
    url: "/nft/tagCollections",
    method: "post",
    data: data,
  });
}
// _0x48c6e2
export function apiLoanIndex(data) {
  return request({
    url: "/loan/index",
    method: "post",
    data: data,
  });
}
// _0x233d05
export function apiLoanSave(data) {
  return request({
    url: "/loan/save",
    method: "post",
    data: data,
  });
}
//
export function apiUploadBase64(data) {
  return request({
    url: "/Upload/base64",
    method: "post",
    data: data,
  });
}
// _0x66694f
export function apiUserApplyCollection(data) {
  return request({
    url: "/user/applyCollection",
    method: "post",
    data: data,
  });
}
// _0x232cdb
export function apiSigninSignin(data) {
  return request({
    url: "/signin/signin",
    method: "post",
    data: data,
  });
}
// _0x256040
export function apiSigninSigninReward(data) {
  return request({
    url: "/signin/signinReward",
    method: "post",
    data: data,
  });
}
// _0x26aa10
export function apiSigninInviteReward(data) {
  return request({
    url: "/signin/inviteReward",
    method: "post",
    data: data,
  });
}
// _0xbaccb9
export function apiSigninExchange(data) {
  return request({
    url: "/signin/exchange",
    method: "post",
    data: data,
  });
}
// _0x4ed3fa
export function apiSigninResign(data) {
  return request({
    url: "/signin/resign",
    method: "post",
    data: data,
  });
}
// _0x586930
export function apiSigninConfig(data) {
  return request({
    url: "/signin/config",
    method: "post",
    data: data,
  });
}
// _0x3fc53c
export function apiSigninRankingList(data) {
  return request({
    url: "/signin/rankingList",
    method: "post",
    data: data,
  });
}
// _0x556fa2
export function apiSigninSigninMap(data) {
  return request({
    url: "/signin/signinMap",
    method: "post",
    data: data,
  });
}
