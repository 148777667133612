import { apiFuturesHistoryKline } from "@/api/v1";

export function formatBoldText(text) {
  return text
    .replaceAll("[p]", '<p style="font-weight: bold">')
    .replaceAll("[/p]", "</p>");
}

export function ensureHttpPrefix(path) {
  return path.startsWith("http")
    ? path
    : window.location.origin + "/api" + path;
}

export function getQueryParamValue(paramName) {
  var queryString = window.location.hash || window.location.search;
  var queryIndex = queryString.indexOf("?");
  var queryParams = queryString.substring(queryIndex + 1);
  console.log(queryParams);
  var pairs = queryParams.split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    if (pair[0] == paramName) {
      return pair[1];
    }
  }
  return "";
}

export function formatKlineData(data) {
  console.log("formatKlineData", data);
  var result = {
    code: 0,
    count: data.tick.length,
    symbol: data.symbolCode,
    name: data.symbolName,
    data: [],
  };

  for (var i = 0; i < data.tick.length; i++) {
    result.data[i] = [];

    var date = new Date();
    date.setTime(1000 * data.tick[i].id);

    result.data[i][0] = parseInt(date.format("yyyyMMdd")); // 日期
    result.data[i][1] = data.tick[i].open; // 开盘价
    result.data[i][2] = data.tick[i].open; // 开盘价（重复）
    result.data[i][3] = data.tick[i].high; // 最高价
    result.data[i][4] = data.tick[i].low; // 最低价
    result.data[i][5] = data.tick[i].close; // 收盘价
    result.data[i][6] = data.tick[i].amount; // 成交量（金额）
    result.data[i][7] = data.tick[i].vol; // 成交量（数量）

    // 如果时间周期包含分钟或小时，则添加时间（小时:分钟）
    if (data.ch.indexOf("min") > 0 || data.ch.indexOf("hour") > 0) {
      result.data[i][8] = parseInt(date.format("hhmm"));
    }
  }

  return result;
}

export function processData(data) {
  var result = {
      code: 0,
      count: 1,
      stock: [],
    },
    stockData = {},
    currentDate = new Date(),
    firstDataItem = data.data[0];

  currentDate.setTime(1000 * parseInt(firstDataItem.id));

  stockData.date = parseInt(currentDate.format("yyyyMMdd"));
  stockData.yclose = firstDataItem.open;
  stockData.open = firstDataItem.open;
  stockData.high = firstDataItem.high;
  stockData.low = firstDataItem.low;
  stockData.price = firstDataItem.close;
  stockData.amount = firstDataItem.vol;
  stockData.vol = firstDataItem.amount;

  if (data.ch.indexOf("hour") > 0) {
    stockData.time = parseInt(currentDate.format("hh"));
  } else if (data.ch.indexOf("min") > 0) {
    stockData.time = parseInt(currentDate.format("hhmm"));
  }

  result.stock.push(stockData);

  return result;
}

export const Periods = [
  "1day",
  "1week",
  "1mon",
  "1year",
  "1min",
  "5min",
  "15min",
  "30min",
  "60min",
  "4hour",
];

export function NetworkFilter(data, callback) {
  console.log("[NetworkFilter] data", data);
  data.PreventDefault = true;
  switch (data.Name) {
    case "KLineChartContainer::ReqeustHistoryMinuteData":
      apiFuturesHistoryKline({
        symbolCode: this.Symbol,
        period: Periods[this.Period],
        size: 500,
      })
        .then((response) => {
          console.log("[NetworkFilter] 分钟全量数据下载", response);
          if (1 === response.code) {
            var val = formatKlineData(response.data);
            callback(val);
          }
        })
        .catch((e) => {
          console.log("[NetworkFilter] 分钟全量数据下载[Error]", e);
        });
      console.log("[NetworkFilter] 分钟全量数据下载");
      break;

    case "KLineChartContainer::RequestMinuteRealtimeData":
      console.log("[NetworkFilter] 分钟增量数据下载");
      break;

    case "KLineChartContainer::RequestHistoryData":
      console.log("[NetworkFilter] 日线全量数据下载");
      apiFuturesHistoryKline({
        symbolCode: this.Symbol,
        period: Periods[this.Period],
        size: 300,
      })
        .then((response) => {
          console.log("[NetworkFilter] 日线全量数据下载", response);
          if (1 === response.code) {
            var val = formatKlineData(response.data);
            console.log(val);
            callback(val);
          }
        })
        .catch((e) => {
          console.log("[NetworkFilter] 日线全量数据下载[Error]", e);
        });

      break;

    case "KLineChartContainer::RequestRealtimeData":
      console.log("[NetworkFilter] 日线实时数据更新");
      break;
  }
}

export function GetPeriodData(period) {
  var data = new Map([
    [
      4,
      {
        Name: "分时",
        KLineShow: false,
        MinuteShow: true,
      },
    ],
    [
      0,
      {
        Name: "日线",
        KLineShow: true,
        MinuteShow: false,
      },
    ],
    [
      1,
      {
        Name: "周线",
        KLineShow: true,
        MinuteShow: false,
      },
    ],
    [
      2,
      {
        Name: "月线",
        KLineShow: true,
        MinuteShow: false,
      },
    ],
    [
      3,
      {
        Name: "年线",
        KLineShow: true,
        MinuteShow: false,
      },
    ],
    [
      4,
      {
        Name: "1分钟",
        KLineShow: true,
        MinuteShow: false,
      },
    ],
    [
      5,
      {
        Name: "5分钟",
        KLineShow: true,
        MinuteShow: false,
      },
    ],
    [
      6,
      {
        Name: "15分钟",
        KLineShow: true,
        MinuteShow: false,
      },
    ],
    [
      7,
      {
        Name: "30分钟",
        KLineShow: true,
        MinuteShow: false,
      },
    ],
    [
      8,
      {
        Name: "1小时",
        KLineShow: true,
        MinuteShow: false,
      },
    ],
    [
      9,
      {
        Name: "4小时",
        KLineShow: true,
        MinuteShow: false,
      },
    ],
  ]);

  return data.has(period) ? data.get(period) : null;
}

export function GetKlineOption(symbol, period, index0, index1) {
  return {
    Type: "历史K线图",
    IsApiPeriod: true,
    Symbol: symbol,
    NetworkFilter: NetworkFilter,
    Windows: [
      {
        Index: index0,
        Modify: false,
        Change: false,
      },
      {
        Index: index1,
        Modify: false,
        Change: false,
        IsDrawTitleBG: false,
      },
    ],
    EnableScrollUpDown: true,
    IsShowRightMenu: false,
    IsShowCorssCursorInfo: true,
    CorssCursorTouchEnd: true,
    CorssCursorInfo: {
      Left: 0,
      Right: 0,
      Bottom: 1,
      IsShowCorss: true,
    },
    KLine: {
      DragMode: 1,
      Right: 1,
      Period: period,
      MaxReqeustDataCount: 1000,
      MaxRequestMinuteDayCount: 15,
      PageSize: 30,
      IsShowTooltip: true,
    },
    KLineTitle: {
      IsShowName: false,
      IsShowSettingInfo: false,
    },
    SplashTitle: "loading",
    Border: {
      Left: 1,
      Right: 1,
      Top: 1,
    },
    Frame: [
      {
        SplitCount: 5,
        SplitType: 1,
        IsShowLeftText: false,
        IsShowRightText: true,
        Custom: [
          {
            Type: 0,
            Position: "right",
          },
        ],
      },
      {
        SplitCount: 3,
        StringFormat: 0,
        IsShowLeftText: false,
        IsShowRightText: true,
      },
    ],
  };
}

export function getSymbolPrice(symbolCode) {
  // 从本地存储中获取名为 "change" 的项
  var changeDataString = localStorage.getItem("change");
  // 将获取到的字符串解析为 JSON 对象
  var changeData = JSON.parse(changeDataString);

  // 如果解析失败或数据不存在，返回空对象
  if (!changeData) {
    return {};
  }

  // 遍历 changeData 数组
  for (var i = 0; i < changeData.length; i++) {
    // 检查当前项的 symbolCode 是否与传入的参数匹配
    if (changeData[i].symbolCode === symbolCode) {
      // 如果找到匹配项，返回该项
      return changeData[i];
    }
  }

  // 如果遍历完成后没有找到匹配项，返回空对象
  return {};
}

export function getSymbolList() {
  // 从本地存储中获取名为 "change" 的项
  var changeDataString = localStorage.getItem("change");
  // 尝试将获取到的字符串解析为 JSON 对象
  var changeData = JSON.parse(changeDataString);

  // 返回解析后的对象，如果解析失败则返回 undefined
  return changeData;
}

export function findSymbolItem(dataList, symbolCode, marketType) {
  console.log("findSymbolItem", dataList, symbolCode, marketType);
  // 初始化一个空对象用于存放结果
  var resultItem = {};
  // 用于标记是否是第一个元素，如果是，则赋值给resultItem
  var isFirstElement = true;

  // 遍历dataList数组
  for (var i = 0; i < dataList.length; i++) {
    // 如果是第一个元素，将其赋值给resultItem
    if (
      (isFirstElement && ((resultItem = dataList[i]), (isFirstElement = false)),
      dataList[i].symbolCode === symbolCode)
    ) {
      // 如果找到匹配的symbolCode，返回该项
      return dataList[i];
    }
  }

  // 如果遍历完成后没有找到匹配项，返回resultItem，它可能是第一个元素或空对象
  return resultItem;
}
