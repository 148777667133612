<template>
  <div
    v-if="isExternal"
    class="svg-external-icon svg-icon"
    :style="styleExternalIcon"
  >
    <slot></slot>
  </div>
  <svg v-else :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
  },
  computed: {
    isExternal: function () {
      return /^(https?:|mailto:|tel:)/.test(this.iconClass);
    },
    iconName: function () {
      return `#icon-${this.iconClass}`;
    },
    svgClass: function () {
      return this.className ? `svg-icon ${this.className}` : "svg-icon";
    },
    styleExternalIcon: function () {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        "-webkit-mask": `url(${this.iconClass}) no-repeat 50% 50%`,
      };
    },
  },
};
</script>

<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon {
  background-color: currentColor;
  -webkit-mask-size: cover !important;
  mask-size: cover !important;
  display: inline-block;
}
</style>
