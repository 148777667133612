export default {
  zt:{
    st_0:"审核中",
    st_1:"进行中",
    st_2:"已赎回",
    st_3:"审核未通过",
    st_4:"만료됨"
  },
  langName: "한국인",
  common: {
    error: "시스템 오류입니다. 다시 시도해주세요!",
    home: "홈",
    detail: "상세정보",
    market: "시장",
    trade: "거래",
    options: "옵션",
    wallets: "지갑",
    notMore: "더 이상 기록이 없습니다",
    reconnect: "다시 연결중",
    noData: "현재 내용이 없습니다",
    loading: "로딩중...",
    liveChat: "라이브 채팅",
    help: "도움말",
    lang: "언어",
    notify: [
      "이 링크를 지갑에서 열어주세요!",
      "성공적으로 제출되었습니다",
      "이미 쿠폰을 받았습니다!",
      "인증을 통과할 수 없습니다",
    ],
  },
  header: {
    voucher: "쿠폰 받기",
    connectWallet: "로그인",
    receive: "수신 설명",
    reveiveInfo:
      "쿠폰을 받으려면旷사업자의 수수료를 지불해야 합니다. 지갑에 ETH가 충분한지 확인해주세요.",
    receiveBtn: "받기",
    reward: "당첨되었습니다",
  },
  share: {
    shareFriend: "친구에게 공유",
    info: "초대 링크를 보내면, 친구들이 귀하의 링크를 통해 노드에 가입하면, 풍성한 토큰 보상을 받게 됩니다",
    myShareLink: "내 공유 링크",
    copy: "복사",
  },
  ad: {
    plan: "계획저축:",
    regular: "일반저축:",
  },
  output: {
    interest: "이자",
    desc: "유연한 이자 수입",
    output: "출력",
    address: "주소",
    quantity: "수량",
  },
  order: {
    order: "주문",
    amount: "금액",
    available: "가능",
    all: "전체",
    confirm: "확인",
    period: "기간",
    requird: "금액 요구",
    rate: "비율(ETH)",
    additional: "추가 보상",
    less: "1 USDC 이상이어야 합니다",
    participants: "참여자",
    total: "총 금액(usdc)",
  },
  chart: {
    loading: "로딩중",
    min1: "1분",
    min15: "15분",
    min5: "5분",
    min30: "30분",
    hour1: "1시간",
    hour4: "4시간",
    day1: "1일",
    week1: "1주",
    mon1: "1개월",
    more: "더보기",
    price: "가격",
    open: "시가",
    amount: "량",
    high: "고가",
    low: "저가",
    buy: "구매",
    sell: "판매",
    buyLong: "장기/Buy",
    sellShort: "단기/Sell",
    main: "주요",
    side: "서브",
    setting: "설정",
    history: "위치",
  },
  futureOrder: {
    title: "주문 확인",
    name: "이름",
    direction: "방향",
    price: "가격",
    info: "배송 시간(왼쪽으로 스와이프하여 더 많은 이익을 얻으세요)",
    amount: "금액(USDC)",
    available: "가능한 USDC",
    fee: "수수료",
    second: "초",
    buy: "구매",
    sell: "판매",
    minAount: "${minAmount}-${maxAmount} 최소",
    startPrice: "주문 가격",
    expectedProfits: "예상 이익",
    finish: "기한 완료",
    currentPrice: "현재 가격",
    completeResult: "완료 결과",
    confirmOrder: "확인",
    continueBuy: "계속 구매",
  },
  futureHistory: {
    title: "주문 기록",
    direction: "방향",
    amount: "금액",
    buyPrice: "주문 가격",
    OrderPrice: "최종 가격",
    deliveryTime: "종류",
    pl: "손익",
    sellTime: "완료 시간",
  },
  market: {
    currency24h: "화폐 24시간",
    volume: "거래량",
    liquidity: "유동성",
  },
  user: {
    title: "새로운 사용자가 광산プール에 가입했습니다.",
    nation: "사용자의 국적",
    address: "퍼블릭 체인 주소",
    Hashrate: "지갑 해시레이트",
  },
  rule: {
    daily: "일간",
    interest: "이자",
    rule: "규칙",
    flexible: "유연한",
    payments: "이자는 4시간마다 지급되며, 하루에 6번입니다.",
    safety: "자산 안전",
    less: "USDC 예금 자산의 약 80%는 3개월 이하의 단기 미국 정부 채권으로 세계에서 가장 안전한 자산 중 하나이며, 이는 세계 최대 경제인 미국 정부의 '전면적인 신용 보증'을 받고 있습니다.",
    economy:
      "이들은 거래에서 중개인으로서 이익을 가져가지 않고 기능할 수 있으며, 사용자는 그들이 공유하거나 소비하는 콘텐츠에 대한 법률 및 규제적 영향을 책임집니다.",
    fees: "수수료",
    additional: "이자를 인출할 때 추가 수수료가 없습니다.",
  },
  report: {
    title: "dapp 지원을 위한 웹3 지갑",
    subTitle: ["모바일", "하드웨어", "스마트 계약", "기타"],
  },
  help: {
    article: [
      {
        title: "데이터 소유",
        details: [
          "이더리움 블록체인을 사용하는 이 소프트웨어 암호화폐 지갑으로 모든 이점을 누리세요. 로컬에서 데이터를 소유하고 저장할 수 있습니다. 브라우저 확장 프로그램이나 모바일 앱을 통해 지갑에 접근할 수 있습니다.",
        ],
      },
      {
        title: "WEB3.0의 이점은 무엇입니까?",
        details: [
          "웹 3.0은 분권화, 개방성, 사용자 유틸리티 향상의 핵심 개념 위에 구축되었습니다.",
          "분권화된 시스템에서는 정보가 내용에 따라 검색될 수 있으며, 동시에 여러 위치에 저장될 수 있습니다. 이는 인터넷 거인들이 현재 보유하고 있는 거대 데이터 세트를 해체하면서도 확장 가능하고 안전하며 접근 가능하게 만듭니다.",
        ],
      },
      {
        title: "탈중앙화 응용 프로그램은 무엇입니까?",
        details: [
          "탈중앙화 응용 프로그램(dApps)은 블록체인이나 피어 투 피어(P2P) 네트워크의 노드에서 실행되는 프로그램이며, 단일 중앙화된 컴퓨터에 의존하지 않습니다.",
          "dApps의 몇 가지 이점은 중개인이 거래에서 이,익을 가져가지 않아 재정적으로 효율적이며, 사용자는 그들이 공유하거나 소비하는 콘텐츠에 대한 법률 및 규제적 영향을 책임집니다.",
        ],
      },
      {
        title: "스마트 계약",
        details: [
          "탈중앙화 응용 프로그램(dApps)은 블록체인이나 피어 투 피어(P2P) 네트워크의 노드에서 실행되는 프로그램이며, 단일 중앙화된 컴퓨터에 의존하지 않습니다.",
          "dApps의 몇 가지 이점은 중개인이 거래에서 이익을 가져가지 않아 재정적으로 효율적이며, 사용자는 그들이 공유하거나 소비하는 콘텐츠에 대한 법률 및 규제적 영향을 책임집니다.",
        ],
      },
    ],
    center: "도움말 센터",
    hope: "도움이 되었으면 좋겠습니다",
  },
  partner: {
    title1: "감사 보고서",
    detail1: "우리는 안전한 감사 보고서를 가지고 있습니다",
    title2: "파트너",
    detail2: "우리의 비즈니스 파트너",
  },
  trading: {
    titles: ["총 거래량", "미개仓이자", "총 사용자"],
    currency: "인기 화폐",
    banners: ["유동성으로 USDC를 벌기", "우리의 강점"],
  },
  poolIndex: {
    tab1: "풀 데이터",
    tab2: "계획",
    tab3: "세금",
    tab4: "계좌",
    tab5: "옮기다",
    transfer: "지갑에서 잔고로 USDC를 전송",
    button: "전송",
  },
  liquidity: {
    type:"유형",
    interest: "이자",
    period: "기간",
    amountRequire: "금액 요구(USDC)",
    rate: "비율(ETH)",
    additionalReward: "추가 보상",
    days: "일",
    subscription: "스테이킹",
    redeem: "리딤",
    record: "기록",
    participants: "참여자",
    totalAmount: "총 금액(usdc)",
    interestRate: "이자율",
    amount: "금액(usdc)",
    collectionAmount: "수집 금액",
    reward: "보상",
    addReward: "추가 보상",
    endTime: "종료 시간",
    status: "상태",
    stakingAmount: "스테이킹 금액",
    unCollected: "미수집 금액",
  },
  pool: {
    total: "총 배당 데이터",
    participant: "참여자",
    userRevenue: "사용자 수입",
    poolData: "풀 데이터",
  },
  message: {
    title: "메시지",
    ok: "확인",
  },
  lottery: {
    title: "블라인드박스 USDC 펌핑",
    btn: "랜덤 하나",
    stake: "스테이킹",
    connectWallet: "지갑 연결",
    voucher: "쿠폰 받기",
    tab1: "풀 데이터",
    tab2: "계획",
    tab3: "세금",
    tab4: "계좌",
    ok: "확인",
    lotteryChance: "ETH! 드로우 로터리 기회를 얻으신 것을 축하합니다!",
    lotteryBtn: "로터리 시작",
    congratulations: "축하합니다",
    experienceUsdt1: "축하합니다! 당신은",
    experienceUsdt2: "USDC 시험 기금을 얻었습니다. 현재 시험의 이자는",
    experienceUsdt3: "ETH. 시험 기한은",
  },
  my: {
    myAccount: "내 계좌",
    totalOutput: "총 출력",
    walletBalance: "지갑 잔고",
    exchangeable: "환전 가능",
    exchange: "환전",
    withdraw: "출금",
    record: "기록",
  },
  exchange: {
    redeem: "전체 변환",
    exchange: "환전",
    convert: "Eth 코인을 USDC로 변환",
  },
  withdraw: {
    total: "총 잔고",
    confirm: "확인",
    tip: "당신의 출금은 24시간 내에 미래에 USDC 지갑 주소로 전송될 것입니다",
  },
  record: {
    time: "시간",
    quantity: "수량",
    status: "상태",
    output: "출력",
    exchange: "환전",
    withdraw: "출금",
    interest: "이자",
    rebate: "리베이트",
  },
  loan: {
    title: "대출 금액",
    dueDate: "기한일",
    tips: ["간편 문서로", "대출"],
    form: "대출 신청서",
    download: "다운로드",
    print: "양식 인쇄 및 작성",
    upload: "사진 촬영 및 양식 업로드",
    btn: "업로드",
    file: "파일 다운로드",
    cancel: "취소",
    uFile: "파일 업로드",
    tip: "업로드 클릭",
    doc1: "개인 대출 계약 1.doc",
    doc2: "개인 대출 계약 2.doc",
    file1: "PERSONAL_LOAN_AGREEMENT-1.docx",
    file2: "PERSONAL_LOAN_AGREEMENT-2.docx",
  },
  nav: {
    home: "홈",
    lang: "언어",
    loan: "대출",
    paper: "문서",
    pool: "풀 데이터",
    trading: "거래",
  },
  whitepaper: {
    title: "USDC 백서",
    view: "보기",
    eth: "이더리움 백서",
  },
  nft: {
    vol: "거래량",
    amount: "거래 금액",
    qty: "Nft 수량",
    users: "이윤 사용자",
    quantity: "수량",
    totalVol: "총 거래량",
    lastestTrans: "최신 거래 가격",
    collQuantity: "컬렉션 수량",
    showMore: "더 보기",
    pd: "프로젝트 상세 정보",
    sellPrice: "판매 가격",
    royalty: "로열티",
    royaltyTip: "이 거래로 인한 모든 로열티는 자선에 기부됩니다",
    buy: "구매",
    basic: "기본 정보",
    contract: "계약 주소",
    network: "네트워크",
    attribute: "속성",
    trait: "이 특성이 있습니다",
    records: "기록",
    balance: "잔고",
    sellNow: "지금 판매",
    cancel: "취소",
    buyNow: "지금 구매",
    pay: "지불 예정",
    transPass: "거래 비밀번호",
    transTip:
      "참고: 거래 비밀번호를 기억해 주세요. 거래 비밀번호를 잊어버리셨다면, 플랫폼의 온라인 고객 서비스에 문의해주세요.",
    newPass: "새 비밀번호",
    rePass: "비밀번호 확인",
    confirm: "확인",
    purchaseTime: "구매 시간",
    purchaseAmount: "구매 금액",
    price: "가격",
    priceTip: "판매 가격을 입력해주세요",
    sell: "판매",
    digitTip: "6자리를 입력해주세요",
  },
  info: {
    1: [
      "USDC는 트레이더가 이자를 벌기 위해 활용하는 코인입니다. 이를 수행하는 방법은 여러 가지가 있으며, 이는 암호화폐의 고유한 특징 중 하나인데, 기존 은행은 수익 면에서 거의 제공하지 않기 때문입니다. USDC 토큰을 사용하는 방법을 이해하는 것은 암호화폐를 거래하는 사람들에게 가치가 있습니다.",
      "목차",
      "1 USD 코인(USDC)이란 무엇입니까?",
      "2 USDC 이자는 어떻게 작동합니까?",
      "3 USDC에서 이자를 벌는 방법은 무엇입니까?",
      "4 USDC에서 수익을 벌 수 있는 다양한 방법은 무엇입니까?",
      "5 USDC에서 이자를 벌는 것이 가치가 있습니까?",
      "6 FAQ: 자주 묻는 질문",
      "USD 코인(USDC)이란 무엇입니까?",
      'USD 코인은 "USDC"라고도 하며 스테이블코인으로 알려진 암호화폐 유형입니다. Circle과 Coinbase는 미국에서 가장 큰 규제 금융 기관 두 곳에서 발행하며, 준비 자산으로 완전히 뒷받침됩니다. USDC 하나의 가격은 1달러에 고정됩니다. 즉, 미국 달러와 1대 1로 상환할 수 있습니다.',
      "이를 통해 암호화폐 지갑에서 USD 고정 거래가 가능합니다. USD 코인은 이더리움 네트워크에서 구동되는 ERC-20 이더리움 토큰으로, 이더리움을 수용할 수 있는 모든 지갑에 저장할 수 있습니다. 이를 통해 암호화폐 공간에서 대부분이 매우 유연하고 사용할 수 있습니다.",
      "USDC 이자는 어떻게 작동합니까?",
      "다른 자산의 가치에 고정된 토큰인 스테이블코인은 이자를 벌기에 이상적입니다. USDC의 경우 1달러의 가치가 있습니다. 이러한 코인에서 발생하는 이자는 다른 암호화폐보다 훨씬 높은 경향이 있는데, 훨씬 쉽게 정량화할 수 있기 때문입니다. 자산이 안정적일 때 너무 많이 변동하지 않아야 하므로 보상을 제공하기가 더 쉽습니다. 사람들이 USDC를 빌릴 수 있도록 허용함으로써 회사와 개인은 투자, 개인적 이유 또는 레버리지 거래를 위해 블록체인을 통해 대출을 받을 수 있습니다. 은행에서 받는 전통적인 대출과 마찬가지로 대출에 이자를 더하여 상환하는 데 동의합니다. 다시 말해, 10,000 USDC를 빌리면 10,000달러를 빌리는 것과 같습니다.",
      "암호화폐 이자 대 스테이블코인",
      "스테이블코인과 암호화폐 모두에서 이자를 벌 수 있습니다. 그러나 둘은 상당히 다르며, 여러분이 겪게 될 위험 프로필은 이를 반영할 것입니다. 암호화폐는 스테이블코인보다 변동성이 훨씬 더 크기 때문에 거래소는 암호화폐를 빌려줄 의향이 훨씬 적습니다. 암호화폐는 단 하루에 가치의 30%를 잃을 수 있지만 스테이블코인은 거의 움직이지 않습니다.",
      "암호화폐의 극심한 변동성 때문에 거래소는 거래소에 암호화폐를 스테이킹하는 사람들에게 해당 통화에 대한 더 낮은 환율을 제공합니다. 결국, 그들은 악화된 수익을 지불해야 할 수도 있습니다. 반면에 암호화폐를 스테이킹하는 사람들은 약간의 이자 수익을 얻을 수 있지만 동시에 엄청난 양의 원금을 잃을 수도 있습니다. 항상 위험이 있습니다.",
      'USDC와 같은 토큰은 변동성이 덜하기 때문에 스테이블코인은 암호화폐에 가치를 저장하는 더 안전한 방법입니다. 따라서 거래소와 블록체인은 사용자에게 더 높은 이자율을 제공하고자 합니다. USDC APR은 주로 "안정적"이기 때문에 강세를 보입니다.',
      "USDC에서 이자를 받아야 하는 이유",
      "USDC에서 이자를 받고 싶은 데에는 여러 가지 이유가 있으며, 다음과 같은 이점이 있습니다.",
      "저축 대체:",
      "일부 사람들은 저축 계좌를 대체하기 위해 USDC에서 이자를 받을 것입니다. 저축 계좌는 지난 몇 년 동안 이자가 거의 없었고, 암호화폐가 그 공백을 메우기 위해 서두르고 있습니다.",
      "더 높은 수익률:",
      "USDC를 포함한 암호화폐 스테이킹에서 얻는 수익률은 일반적으로 기존 은행에서 얻는 수익률보다 훨씬 높습니다.",
      "기다리는 동안 수익 창출:",
      "시장은 때때로 위험하고 변동성이 심할 수 있습니다. 이 때문에 일부 거래자는 시장에서 벗어나 USDC를 스테이킹합니다. 이는 계정을 계속 키우는 좋은 방법입니다.",
      "USDC에서 이자를 얻는 방법?",
      "USDC의 혜택을 받으려면 먼저 USDC를 소유해야 합니다. USDC를 저장하는 것은 간단하며 대부분 거래소에서 할 수 있습니다. 여러 거래소에 등록해야 합니다. 거래소로 송금하는 것이 좋습니다. 송금된 자금이 거래소에 도착하면 먼저 등록한 거래소에서 ETH를 구매한 다음 ETH를 거래소로 보내야 합니다. WEB3 지갑에서 다음 단계는 WEB3 지갑에서 받은 ETH를 USDC로 변환하는 것입니다. 이는 매우 간단하며 몇 분 안에 완료할 수 있습니다.",
      "USDC에서 수익을 얻는 다양한 방법은 무엇입니까?",
      "USDC에서 이자를 얻을 수 있는 다양한 방법과 장소가 있습니다. 그러나 각 플랫폼 유형 간의 차이점과 장단점을 알아야 합니다.",
      "거래소를 통해 USDC에서 수익 창출",
      "거래소를 통해 수익을 창출하는 것은 아마도 가장 간단한 프로세스일 것입니다. 대부분 거래소는 계정 생성, USDC 입금, 선호하는 수익 창출 상품 선택을 포함한 유사한 일련의 단계를 요구합니다. 여기에는 특정 기간 동안 USDC를 잠금하는 것이 포함될 수도 있고 포함되지 않을 수도 있습니다. 유동성이 필요한 경우 문제가 될 수 있습니다.",
      "중앙 집중형 거래소를 사용하는 가장 큰 장점은 간단하다는 것입니다. 해당 플랫폼에서 암호화폐를 보관하거나 적어도 암호화폐 거래에 대한 자금을 조달할 가능성이 높기 때문입니다.",
      "장점:",
      "단점:",
      "쉽고 편리합니다.",
      "특정 기간 동안 UsDc를 잠금해야 할 수도 있고 그렇지 않을 수도 있습니다. 사용자.",
      "동일한 플랫폼에서 수행할 수 있습니다.",
      "더 낮은 환율을 받을 수 있습니다.",
      "대부분 거래소는 자본이 풍부합니다.",
      "사용 가능한 상품이 제한될 수 있습니다.",
      "Web3는 암호화폐 스테이킹 분야의 선두 주자 중 하나입니다.",
      "DeFi로 USDC에서 수익 창출",
      "암호화폐에서 가장 흥미로운 가능성 중 하나는 사용자가 중개자 없이도 필수적인 금융 서비스에 액세스할 수 있게 해주는 DeFi 플랫폼입니다. 사용자는 암호화폐(이 경우 USDC)를 분산형 대출 프로토콜에 입금하여 이자를 얻을 수 있습니다.",
      "이 분산형 금융 분야를 통해 사용자는 새로운 금융 상품을 빌리고, 빌려주고, 투자할 수 있습니다. 이는 모두 스마트 계약과 블록체인 기술을 사용하여 가능합니다.\n",
      "장점:",
      "단점:",
      "스마트 계약은 사용 편의성을 제공합니다.",
      "DeFi 프로토콜은 사용자의 실수를 되돌리지 않습니다.",
      "중개자가 없습니다.",
      "일부 프로토콜에서는 확장성이 문제가 될 수 있습니다.",
      "다른 제품을 사용할 수 있습니다.",
      "소규모 프로토콜에서는 유동성이 문제가 될 수 있습니다.",
      "분산형 금융 프로토콜의 예로는 Uniswap과 Bancor가 있습니다.",
      "USDC에서 이자를 받는 것이 가치가 있습니까?",
      "USDC에서 이자를 받는 것은 암호화폐 자산에서 이자를 받는 좋은 방법입니다. 현실 세계의 일반적인 저축 계좌보다 이자율이 훨씬 높으므로 더 높은 수익을 얻을 수 있습니다.\n",
      "USDC에서 이자를 받는 것은 대부분의 거래자에게 유익합니다.",
      "USDC를 사용하여 이자를 받기로 선택할 수 있는 여러 가지 이유가 있습니다. 가장 분명한 이유는 이자율 측면에서 표준 오프라인 은행보다 훨씬 우수하다는 것입니다. 그 외에도 USDC는 일반적인 암호화폐 매도에서 가치가 하락할 수 있는 자산에서 이자를 받는 좋은 방법입니다. 예를 들어, 비트코인을 현금화한 다음 USDC로 전환하면 시장이 반전될 때까지 이자를 받을 수 있습니다.",
      "아무것도 100% 안전하지 않다는 점을 명심하세요. 하지만 평판이 좋은 거래소나 대출 서비스에 머무르는 한 보호받을 수 있습니다. 이는 USDC에서 이자를 받는 것이 고수익 채권과 매우 유사하므로 전체 포트폴리오의 일부를 다각화하는 좋은 방법이 될 수 있다는 점을 기억하세요.\n",
      "대부분의 트레이더는 USDC를 보유하고 물론 시장이 대처하기 어려울 때 이자를 받으려고 노력함으로써 이익을 얻을 것입니다. 포트폴리오에 이러한 안전한 자산을 보유하면 안전성뿐만 아니라 다른 코인으로 전환하거나 안전망으로 사용할 수 있으므로 유연성도 제공됩니다. 대부분의 전문 트레이더는 다양한 시기에 이러한 코인을 최소한 일부 보유하고 있습니다\n",
      "FAQ: 자주 묻는 질문",
      "USDC 코인은 이자를 지급합니까?",
      "코인 자체는 이자를 지급하지 않지만 이자를 지급하는 거래소와 대출 기관이 많이 있습니다. 이자는 가장 흔한 코인 중 하나이므로 선택의 폭이 넓습니다.",
      "USDC는 매월 이자를 지급합니까?",
      "대출하는 곳에 따라 다릅니다. 예치 기간은 매우 단기에서 1년 이상까지 다양합니다.",
      "USDC를 사서 돈을 벌 수 있습니까?",
      "반드시 그런 것은 아니지만 이자를 받는 것은 돈을 버는 방법입니다. USDC는 미국 달러에 고정되어 있으므로 본질적으로 일대일 거래입니다.",
      "USDC의 이자가 왜 그렇게 높습니까?",
      'USDC의 이자율은 공급과 수요 상황으로 인해 높습니다. 이는 다양한 시장 상황, 특히 "암호화폐 겨울"로 알려진 종류의 시장에서 상승하고 하락하는 경향이 있습니다. 일반적으로 대출 수요는 대출할 수 있는 USDC 공급을 초과합니다. 이자율은 이를 반영하여 공급과 수요에 따라 상승하고 하락합니다.\n',
      "USDC에서 돈을 잃을 수 있습니까?",
      "이론적으로는 미국 달러에 대한 고정이 깨졌다고 가정하면 손실이 발생할 수 있습니다. 지금까지 암호화폐 세계에서 가장 안전한 코인 중 하나입니다.",
      "USDC의 요점은 무엇인가?",
      "USDC에는 여러 가지 용도가 있는데, 가장 분명한 것은 블록체인에서 미국 달러를 이체하는 방법입니다. 그러나 거래소, 대출 서비스 또는 DeFi 공간에 입금할 수 있으므로 이자를 받는 것이 사용에 중요한 포인트입니다.\n",
    ],
    2: [
      "목차",
      "1. Web3 지갑이란?",
      "1.1. Web3이란?",
      "2. Web3 지갑이 필요한 이유는?",
      "3. Web3 지갑 대안",
      "3.1. MetaMask",
      "3.2. Coinbase 지갑",
      "3.3. Argent",
      "3.4. Trust 지갑",
      "3.5. Rainbow",
      "4. Web3 지갑이란?-요약",
      'Web3에 대한 관심이 높아지면서 다양한 생태계 참여가 급격히 증가하고 있습니다. 게다가 이 분야에 익숙하지 않다면 진입 장벽이 높고 극복하기 어려워 보일 수 있습니다. 그러나 암호화폐와 Web3 개발에 대한 관심이 커지면서 이러한 장애물은 점차 사라지고 업계가 사용자 친화적으로 변하고 있습니다. 접근성이 높아지는 것과 함께 암호화폐 분야도 경쟁이 치열해짐에 따라 진화하고 있으며, 새로운 dApp(분산형 애플리케이션), 토큰, NFT, Web3 지갑이 지속적으로 개발되고 있습니다. 지갑은 Web3 공간에서 몇 가지 필수 기능을 수행하기 때문에 암호화폐에 뛰어드는 데 필수적입니다. 따라서 이 글에서는 암호화폐 공간을 더 깊이 파고들어 "Web3 지갑이란 무엇인가?"라는 질문에 답하겠습니다.\n',
      "Web3 지갑이 무엇인지 더 잘 이해하면 시장에서 가장 인기 있는 대안 중 일부도 더 깊이 파고들 것입니다. 게다가 Web3 개발에 관심이 있고 자신의 암호화폐 지갑을 만들고 싶다면 올바른 길로 안내해 드리겠습니다. 바로 WEB3.0에서 시작하는 길입니다.",
      "WEB3를 사용하면 운영 체제의 기본 백엔드 인프라에 액세스할 수 있으므로 빠르고 효율적으로 자신의 Web3 지갑을 개발할 수 있습니다. Web3.0은 Web3 개발을 위한 최고의 운영 체제이며 백엔드 인프라와 함께 사용자는 광범위한 툴박스에도 액세스할 수 있습니다. 도구 중에서 Web3.0 Speedy Nodes, Web3.0 SDK, Price API, IPFS에 대한 기본 지원 등과 같은 솔루션을 찾을 수 있습니다.\n",
      "따라서 블록체인 개발자이자 저축자가 되고자 한다면 첫 번째 단계는 Web3.0에 가입하는 것입니다. 가입하는 데 몇 분 밖에 걸리지 않으며 가입은 완전합니다.",
      "Web3 Wallet이란 무엇입니까?",
      '암호화폐에 관여하는 것을 고려하고 있다면 여정의 첫 번째 단계는 Web3 Wallet을 얻는 것입니다. Web3 Wallet은 Web3 공간, DeFi 및 암호화폐에 액세스하는 데 필수적입니다. 〝Web3" 또는 〝crypto wallet"이라는 단어를 우연히 접했을 수 있습니다. 이 공간에 처음 접한다면 먼저 〝Web3 Wallet이란 무엇입니까?"라는 질문에 답해 보겠습니다.',
      "우선 Web3 Wallet은 본질적으로 디지털 지갑입니다. 따라서 디지털 자산을 저장할 수 있습니다. 여기에는 대체 가능한 토큰에서 대체 불가능한(NFT) 토큰까지 모든 것이 포함됩니다. 둘째, Web3 지갑은 또한 암호화폐 영역으로의 문을 열어 다양한 블록체인의 dApp과 상호 작용할 수 있게 해줍니다. 지갑은 광범위한 dApp 생태계에 액세스하는 데 도움이 됩니다.\n",
      "암호화폐 지갑은 종종 비보관 특성을 가지고 있어 지갑 소유자인 귀하는 중개자나 중개인이 필요 없이 디지털 자산을 저장할 수 있습니다. 즉, 사용자는 다른 사람이 귀하의 토큰에 액세스할 수 없기 때문에 모든 자산을 완벽하게 제어할 수 있습니다. 그러나 독점적인 액세스 권한이 있는 경우 모든 책임은 귀하에게 있으므로 개인 키를 본인에게만 보관하는 것이 필수적입니다.",
      "지갑은 디지털 자산을 호스팅하는 기능과 함께 종종 추가 기능을 제공합니다. 예를 들어, 이를 통해 Web3 지갑을 사용하여 토큰을 보내고 교환할 수 있습니다. 따라서 암호화폐 지갑을 사용하여 추가 토큰을 획득하는 방법을 포함하여 자산을 완벽하게 관리할 수 있습니다.",
      "시중에는 각자의 강점이 있는 다양한 지갑이 있습니다. 가장 인기 있는 것 중 일부는 MetaMask, Coinbase 지갑, DeFi 지갑 TrustWallet, Argent, \n Atoken, Hyperpay, Bitkeep 등입니다. 그러나 나중에 다른 섹션에서 이러한 대안에 대해 더 자세히 살펴보겠습니다. 게다가 자세히 살펴볼 가치가 있는 추가 주제는 WalletConnect인데, Web3 지갑과 긴밀하게 연결되어 있기 때문입니다. \n",
      "Web3이란 무엇입니까?",
      '블록체인 산업이 등장하면서 여러분은 아마도 "Web3"에 대해 들어보셨을 것입니다. 그러나 이 용어에 익숙하지 않은 사람들에게는 생소하고 다소 혼란스러울 수 있습니다. 따라서 이 섹션에서는 Web3가 무엇인지 설명하고 요약하는 데 시간을 할애하겠습니다.\n',
      '일반적으로 Web3는 인터넷의 "최신 세대" 또는 "단계"를 말합니다. 짐작하실 수 있겠지만, 이전 세대는 Web1과 Web2로, 여러분이 더 잘 알고 있는 단계입니다. 세 가지 인터넷 세대는 특정 지점에서 시작되지 않았고\n 인터넷을 혁신하기 위해 단일 엔터티가 시작한 것이 아닙니다. 그러나 각 단계는 Web1이 정적이고 Web2가 동적이고 Web3이 분산된 고유한 특성을 가지고 있습니다.',
      "분산화가 인터넷의 최신 단계에서 핵심 개념이기 때문에\n 데이터 분산이 우세합니다. Web2와 달리 데이터를 소유하는 단일 중앙 엔터티가 없습니다. 대신 분산되고 공유됩니다. 게다가 Web3는 궁극적으로 사용자가 자신의 데이터를 제어함에 따라 회사가 대량의 개인 정보를 소유하는 문제를 해결합니다.",
      "Web3 생태계 내에서 우리는 또 다른 필수 구성 요소인 dApps(분산형 애플리케이션)를 발견합니다. 이들은 일반적으로 블록체인 기반인 분산형 애플리케이션이며, 가장 큰 dApp 생태계는 현재 이더리움 블록체인에서 호스팅됩니다. dApps의 분산형 측면을 통해 단일 실패 지점을 포함하여 중앙 집중화와 함께 발생하는 다양한 문제를 제거하는 강력한 애플리케이션을 개발할 수 있습니다.",
      "그러나 인터넷의 최신 단계에 대해 자세히 알고 싶다면 Web3를 더 자세히 설명하는 기사를 읽어 보는 것이 좋습니다.",
      "왜 Web3 지갑이 필요한가요?",
      '"Web3 지갑이란 무엇인가요?" 섹션에서 누군가가 왜 그런 지갑이 필요한지에 대해 간략하게 다루었습니다. 그러나 이 기사의 이 부분에서는 이러한 요점에 대해 자세히 설명할 것입니다. 그렇다면 왜 Web3 지갑이 필요한가요?',
      "Web3 지갑의 첫 번째 사용 사례는 암호 자산을 완벽하게 관리하는 기능입니다. 이를 예시하기 위해 시장에서 가장 큰 암호 지갑인 MetaMask를 자세히 살펴보겠습니다. MetaMask 계정이 있다면 지갑이 어떻게 작동하는지 이미 알고 있을 것입니다. 그럼에도 불구하고 인터페이스는 다음과 같습니다.",
      '보시다시피, "자산"과 "활동"을 위한 두 개의 탭이 있습니다. 활동 탭은 거래 내역을 간단히 보여주며, 독립적으로 탐색할 수 있습니다. 게다가 자산 탭에서 모든 자산을 볼 수 있습니다. 또한 매수, 보내기, 교환이라는 세 가지 작업 버튼이 있습니다. 버튼은 자명하므로 더 자세히 설명하지 않겠습니다.',
      "그러나 이는 암호 지갑으로서 MetaMask를 통해 모든 자산을 직접 완벽하게 관리할 수 있음을 보여줍니다. 게다가, 이는 다른 지갑 대부분에서 자산을 비슷하게 관리할 수 있기 때문에 고유한 것이 아닙니다.\n",
      "Web3 지갑이 필요한 두 번째 이유는 접근성입니다. 암호화폐 지갑은 다양한 블록체인으로 가는 관문입니다. 따라서 Web3 지갑을 사용하면 대부분의 애플리케이션과 상호 작용하는 데 암호화폐 자산이 필요하기 때문에 사용자가 dApp에 쉽게 액세스할 수 있습니다. 따라서 사용자는 지갑을 사용하여 자신을 인증하여 거래 비용을 지불할 수 있는 수단을 확보할 수 있습니다.",
      "사용자 인증에 더 관심이 있다면 MetaMask로 인증하는 방법과 WalletConnect로 사용자를 연결하는 방법을 다루는 기사를 확인하세요.",
      "Web3 지갑 대안",
      '앞서 언급했듯이, 더 많은 사람들이 암호화폐 공간에 진입함에 따라 Web3 지갑의 수가 증가하고 있습니다. 이는 기업이 신흥 시장에서 성장할 기회를 보고 있는 단순한 "수요와 공급" 딜레마의 결과입니다. 그러나 이는 사용자가 풍부한 대안 중에서 선택하려고 할 때 혼란스러울 수 있습니다.\n',
      "따라서 선택할 수 있는 다섯 가지 대체 지갑을 강조하겠습니다. 대부분은 같은 목적을 가지고 있지만, 다소 다르며, 각자 고유한 방식으로 고유합니다. 다섯 가지 Web3 지갑은 다음과 같습니다.",
      "MetaMask",
      "Coinbase Wallet",
      "Argent",
      "Trust Wallet",
      "Rainbow",
      "그러니 더 이상 미루지 말고 MetaMask를 자세히 살펴보는 것으로 시작해 보겠습니다!",
      "MetaMask",
      "우리의 짧은 목록에 있는 첫 번째 Web3 지갑은 MetaMask이며, 이는 전 세계적으로 2,100만 명 이상의 사용자를 보유한 시장에서 가장 잘 알려진 지갑 중 하나입니다. 이 지갑은 모바일 애플리케이션 또는 브라우저 확장 프로그램의 두 가지 별도 형태로 제공됩니다. 이전에 누군가가 암호화폐 지갑이 필요한 이유를 강조하려고 할 때 MetaMask를 언급했습니다. 우리는 MetaMask를 통해 분산된 방식으로 암호화폐 자산을 완전히 관리할 수 있다는 점을 지적했습니다.",
      "그러나 MetaMask가 dApp의 Ethereum 생태계로 가는 관문이라는 점은 언급하지 않았습니다. 게다가 Ethereum은 가장 중요한 개발 블록체인이므로 MetaMask 사용자에게는 수천 개의 애플리케이션이 제공됩니다. 따라서 Ethereum이 계속 성장함에 따라 MetaMask 지갑을 갖는 것이 매우 유익할 것입니다. MetaMask에 대한 자세한 가이드는 MetaMask 설명 기사를 참조하세요.",
      "Coinbase Wallet",
      "암호화폐에 관심이 있다면 Coinbase에 대해 들어보셨을 것입니다. Coinbase는 전 세계적으로 가장 큰 암호화폐 플랫폼 중 하나이며, 사용자 친화적인 플랫폼과 함께 Coinbase는 이제 Coinbase Wallet이라는 형태의 Web3 지갑도 제공합니다.",
      "Coinbase Wallet은 중앙 집중식 Coinbase 플랫폼과 독립적인 비보관형 Web3 지갑입니다. 따라서 사용자는 Coinbase의 중앙 제어와 무관하게 dApp과 암호화폐의 세계를 경험할 수 있습니다. 또한 Coinbase 지갑의 고유한 기능은 사용자 이름에 따라 암호화폐 자산을 보내고 받을 수 있다는 것입니다. 지갑\n 주소에 따라서만 자산을 보낼 수 있습니다. 이는 길고 어려운 단어와 숫자 시퀀스이므로 지정한 주소로 암호화폐 자산을 보내고 받을 때 복사하여 붙여넣어야 합니다.",
      "또한 토큰 보내기에 대해 자세히 알아보려면 ERC-20 토큰을 보내는 방법에 대한 이 기사\n을 확인하세요. 이는 Web3.0으로 작업할 때 ERC-20 토큰을 보내는 것이 얼마나 쉬운지 강조합니다.",
      "세 번째 지갑은 Argent이며, 시중에 나와 있는 광범위한 Web3\n 지갑 세트에 비교적 최근에 추가된 것입니다. Argent는 Ethereum 토큰과만 호환되므로 Ethereum 사용자를 대상으로 합니다. 게다가 이 지갑은 dApp과 DeFi(분산형 금융)를 사용할 때 사용자 친화적인 고객 경험을 제공하는 데 중점을 둡니다. 이들은 dApp과 프로토콜을 Argent dApp에 기본적으로 통합하여 이를 달성합니다. 따라서 앱을 통해 자산을 직접 빌리고 빌릴 수 있으므로 앱 내 브라우저를 사용하지 않아도 됩니다.\n",
      'DeFi에 대해 자세히 알아보려면 "DeFi란 무엇인가?"를 확인하는 것이 좋습니다. WEB3의 기사에서 DeFi 기술을 개발하고 싶다면 DeFi 101과 DeFi 201 블록체인 과정을 자세히 살펴볼 수도 있습니다.\n',
      "그러나 Argent의 가장 큰 단점 중 하나는 지갑이 Ethereum 네트워크에만 제공된다는 사실입니다. 따라서 예를 들어 Bitcoin 블록체인과 같은 다른 체인에 네이티브인 토큰을 저장할 수 없습니다.",
      "Trust Wallet",
      "네 번째 대안은 Trust Wallet이며 Argent와 달리 이 지갑은 거의 모든 유형의 토큰을 저장할 수 있는 잠재력이 있습니다. 이 지갑은 소위 블록체인에 독립적이므로 다양한 체인의 자산을 지원합니다. 게다가 Trust Wallet은 전 세계적으로 천만 명 이상의 사용자를 보유하고 있어 시장에서 확고히 자리 잡았습니다. Trust Wallet은 다양한 자산과 블록체인을 지원하므로 이 지갑은 분산형 웹이 제공하는 모든 것을 활용할 수 있는 합리적인 대안이 됩니다.\n",
      "Rainbow",
      "마지막 옵션은 Rainbow로, Ethereum 네트워크를 지원하는 비보관형 지갑입니다.게다가 Rainbow는 Argent처럼 Ethereum 네트워크의 dApp에 대한 기본 지원 기능이 있습니다.여기서 한 가지 예는 애플리케이션에서 스왑을 실행하는 데 사용되는 Uniswap V2입니다.Rainbow 지갑의 또 다른 멋진 기능은 NFT 지원입니다.이를 통해 지갑은 모든 디지털 자산을 깔끔하게 표시할 수 있습니다.\n",
      "이것은 사용 가능한 많은 대안 중 5가지에 불과하며, 짧은 설명에서 알 수 있듯이 모두 서로 비슷하면서도 다릅니다.따라서 특정 요구 사항에 가장 적합한 지갑을 찾는 것은 귀하에게 달려 있습니다.\n",
      "Web3 지갑이란 무엇입니까? — 요약",
      "암호화폐의 세계에 뛰어들 계획이라면 가장 먼저 필요한 것은\n Web3 지갑입니다.암호화폐 지갑을 사용하면 모든 디지털 자산을 관리하는 동시에\n dApp의 분산형 생태계에 액세스할 수 있습니다. 게다가 이 모든 것이 분산화가 본질적으로 Web3의 특징이기 때문에 모든 자산을 완전히 통제하는 동안 가능합니다. 따라서 거래, 대출 및 자산 차용과 같은 작업을 수행하는 중개자가 필요하지 않습니다.",
      "분산 웹에 대한 관심이 증가함에 따라 다양한 지갑 대안의 수가 크게 증가했습니다. 그러나 가장 인기 있는 옵션 중 5가지는 MetaMask, Coinbase Wallet, Argent, Trust Wallet 및 Rainbow입니다. 이러한 대안은 많은 유사점을 공유하지만 요구 사항과 상황에 따라 일부는 다른 대안보다 더 적합합니다.",
      "게다가 WEB3의 힘으로 특정 기능이 있는 자체 지갑을 쉽고 빠르게 개발할 수 있습니다. Moralis 사용자는 운영 체제의 기본 백엔드 인프라를 활용하여 몇 분 만에 완벽하게 작동하는 Web3 지갑을 만들 수 있기 때문에 가능합니다.",
      "그러나 Moralis의 한계는 여기서 끝나지 않습니다. 사실, 모든 미래 블록체인 프로젝트의 개발 시간을 크게 단축할 수 있습니다. 여전히 확신이 서지 않는다면, Ethereum dApp을 만들고, 자체 NFT 마켓플레이스를 개발하고, 5단계로 BSC 토큰을 만드는 것이 얼마나 쉬운지 보여주는 블로그의 기사를 확인하세요.\n",
      "그러니 Web3 개발 게임을 한 단계 업그레이드하고 싶은 야망이 있다면 바로 WEB3에 가입하세요. 그러면 백엔드 인프라와 Moralis 도구에 액세스할 수 있습니다!\n",
    ],
    3: [
      "Web3에서 Web3\n 개념을 홍보하고 베트남 UEL에서 장학금을 제공합니다...",
      'Web3에서 최신 학생 전용 이벤트인 "Gate Web3 - Empowering GenZ in Web3"의 성공적인 마무리를 발표하게 되어 기쁩니다. 이 교육 및 자선 행사는 2023년 10월 28일 오후 1시부터 오후 4시 30분까지 베트남 호치민시에 있는 경제 및 법학 대학에서 열렸습니다.',
      "이 행사는 차세대, 특히 Z세대에게 빠르게 진화하는 Web3 기술 세계에서 지식과 기회를 제공하는 것을 목표로 합니다. 참석자들은 Web3의 혁신적인\n개념과 이것이 미래의 선도적 기술이 될 준비가 된 이유에 대한 귀중한 통찰력을 얻었습니다. 게다가 전문가들은 필수 기술 세트, 어디서부터 시작해야 하는지, 그리고 이 역동적인\n 분야에서 성공적인 경력을 쌓는 방법에 대한 지침을 제공했습니다.",
      "Web3는 수업료와 생활비에 대한 재정 지원이 필요한 학생들에게 장학금을 제공하여 불우한 학생들을 지원하는 데 전념합니다. 기조연설자 Mai Ngo 씨,",
      "이 행사에는 또한 블록체인 기술 분야의 경력 기회에 대한 귀중한 통찰력을 공유한 Mai Ngo 씨, BSCS의 Tuan Anh 씨, Investpush Legal의 관리 변호사 Dao Tien Phong 씨를 포함한 업계 전문가들과의 패널 토론이 있었습니다. 참석자들은 이 전문가들과 의미 있는 대화를 나눌 기회를 가졌으며, 귀중한 지식과 지침을 얻었습니다.",
      "정보 세션 외에도 Web3와 Gate Charity는 행사 기간 동안 장학금을 수여했습니다. 각각 1,000,000 VND 상당의 우수한 학업 성취도를 보인 불우 학생 15명에게 장학금이 지급됩니다.",
      "이 행사에는 FTC 대학의 학생 약 100명과 강사가 초대될 예정입니다. 이 행사에는 또한 상당한 온라인 참여가 있을 예정이며, 온라인 트래픽은 50,000명이 될 것으로 예상됩니다.",
      "Web3는 이 행사를 주최하게 되어 기쁩니다. 이 행사는 전문가, 학생, Web3 기술 옹호자들을 하나로 모아 이 혁신적인 분야에서 교육, 인식, 기회를 증진하는 데 목적을 두고 있습니다.",
      "Gate Charity 소개",
      'Charity는 전 세계적으로 블록체인 자선 운동을 주도하는 데 중점을 둔 글로벌 비영리 자선 단체입니다. "균형 잡힌 세계 증진"이라는 사명에 따라 운영되는 Charity는 암호화폐 커뮤니티를 통합하여 빈곤 격차를 해소하고, 소외된 사람들의 삶을 개선하고, 교육적 형평성을 극대화하고, 혁신적이고 신뢰할 수 있는 블록체인 네트워크와 암호화폐 이니셔티브를 통해 환경적 지속 가능성을 증진하는 것을 목표로 합니다.',
      "면책 조항:",
      "이 활동과 관련된 모든 콘텐츠는 자선 목적으로만 제공됩니다. 모든 정보는 일반적인 성격을 띠며 어떤 종류의 전문적인 조언을 구성하지 않습니다. 콘텐츠에 포함된 어떤 내용도 어떤 사람이 서비스나 제품을 사용하거나 구매하도록 권유, 권장, 지지 또는 제안하는 것이 아닙니다. Gate Charity는 이 활동과 모든 관련 정보에 대한 최종 해석에 대한 권리를 보유합니다. 문의 사항이 있으시면 support@gatecharity.org로 Gate Charity에 문의하세요.\n",
      "Gateway to CryptoTrade는 web3에서 1,700개가 넘는 암호화폐를 안전하고 빠르고 쉽게 거래합니다.",
      "web3에 가입하고 추천인에 대한 40% 수수료를 받아 지금 web3 저장소 슬롯을 받으세요.",
      "저희에게 연락하세요!",
      "팀 2023년 10월 23일",
    ],
    4: [
      "블록체인으로 삶을 바꾸다",
      "언제 어디서나 필요할 때 블록체인 솔루션을 통해 모든 사람이 금융 서비스와 도움을 받을 수 있는 세상을 구축합니다.",
      "총 기부금(USD)",
      "총 수혜자",
      "수혜 지역",
      "세계의 균형 잡힌 개발 촉진",
      "빈곤 근절",
      "우리는 블록체인과 암호화폐가 평등한 교육과 고용 기회를 창출하고, 빈곤에 시달리는 사람들을 돕고, 수백만 명을 빈곤에서 벗어나게 하는 데 역할을 할 수 있다고 믿습니다.",
      "불우 계층 지원",
      "불우 지역의 여성, 장애인, 소외 계층 어린이, 노인, 취약 계층 가족을 돕는 데 중점을 두고 중요한 자원과 지원 시스템에 대한 장벽을 제거하여 포용성, 접근성, 번영하고 성장할 수 있는 능력을 제공합니다.",
      "우리 지구를 구하다",
      "지구는 하나뿐이며, 우리는 항상 기후와 자연 환경에 대한 인간의 피해를 줄이기 위해 노력할 것입니다. 건강한 환경은 미래 세대에 보편적으로 긍정적인 영향을 미치는 것을 의미합니다.",
      "인도적 지원 제공",
      "재난과 위기로 인해 상황이 가장 위급할 때 피해를 입은 사람들에게 적시에 구호를 제공합니다. 여기에는 갈등으로 피해를 입은 사람들에 대한 지원, 재난 지역 재건, 이주민에 대한 지원, 의료적으로 박탈당한 지역에 대한 지원이 포함됩니다.",
      "투명하고 추적 가능한 모금",
      "블록체인 기술을 사용하여 투명하고 신뢰할 수 있는 자선 활동을 통해 불우한 사람들을 진정으로 돕습니다.",
      "NFT 아트워크 모금 채널",
      "NFT 경매 모금을 통해 아트워크의 가치를 전파하는 동시에 자선 목적을 효과적으로 달성할 수 있습니다.",
      "Metaverse 자선 게임 프로젝트",
      "모든 사람의 마음에 자선의 씨앗을 퍼뜨리는 교육적이고 재미있는 방법을 제공하는 다양한 Web3.0 솔루션.",
      "블록체인 기술을 활용하여 사람들에게 혜택을 제공",
      "글로벌 선도적 블록체인 기술",
      "안전하고 혁신적인 블록체인 기술을 통해 자선 기부가 투명하고 안전하며 신뢰할 수 있도록 보장합니다. 재정적 지원이 필요한 모든 사람은 모든 측면에서 도움을 받을 수 있습니다.",
      "광범위하게 지원되는 디지털 자산 저장소",
      "우리는 세계에서 가장 광범위한 디지털 자산 풀을 지원하는 것을 자랑스럽게 생각합니다. 즉, 이 위대한 자선 운동에 참여하기 위해 보유한 모든 디지털 자산을 사용할 수 있습니다.",
      "블록체인 교육에 대한 액세스",
      "우리는 교육이 모든 것의 근원이라고 믿으며, 기본적인 블록체인 교육을 실시하는 것은 우리가 돕는 사람들에게 유익할 것이며, 그들은 미래 블록체인 구축의 필수적인 부분이 될 것입니다.",
      "자선 목적을 위한 다자간 협력",
      "우리는 최고의 기관, 프로젝트 및 산업 전문가를 통합하여 블록체인의 효과를 극대화하는 데 전념하며, 모든 형태의 통합이 궁극적으로 자선 자체에 도움이 될 것이라는 점을 이해합니다.",
      "리더보드",
      "10월 21일 오전 8시부터 오후 4시(GMT -3)까지 Web3 Charity는 A. A. Garotos de Ouro 및 Gate.io와 협력하여 브라질의 유명한 Arena Brahma에서 특별한 어린이의 날 이벤트를 개최했습니다. 이 놀라운 모임에는 인근 지역의 약 150명의 어린이가 모여 모두 열의에 넘쳐 흥분했습니다.",
      '글로벌 비영리 자선 단체인 Web3 Charity는 2023년 10월 6일 필리핀 바클레이온에서 "바다를 보호하라"라는 주제의 행사를 성공적으로 개최했습니다. 이 행사에는 지역 환경 운동가 2명과 예술가 JR이 함께 모여 이 지역의 해양 생태계 보호를 옹호했습니다.',
      "우리의 이니셔티브",
      "소말리아 식량 위기 지원 프로젝트",
      "Gate Charity와 Worid Vision에 가입하여 소말리아 기근 피해자를 지원하세요",
      "모금됨",
      "기부",
      "지금 기부",
      "지원 대상",
      "전 세계 최소 45개국에서 약 5천만 명이 굶주림 직전에 있습니다. 아프리카, 라틴 아메리카, 중동, 아시아 국가가 영향을 받는 현대에 이와 같은 굶주림 위기를 본 적이 없습니다. 소말리아와 같은 나라에서는 수백만 명의 사람들이 굶주림으로 고통받고 있습니다. 가뭄, 홍수 및 기타 극심한 기상 조건과 같은 기후 변화의 부정적인 영향으로 작물과 소득이 파괴되었으며, 일부 국가에서는 메뚜기 떼가 대량으로 발생하여 수확이 황폐화되었습니다. COVID-19 팬데믹의 여파로 식량 및 기타 상품의 전 세계 가격이 급등했습니다. 이로 인해 가족들은 생존에 필요한 기본 식료품을 살 수 없습니다. 한편, 전 세계 여러 지역에서 갈등으로 인해 수백만 명의 사람들이 뿌리를 뽑히고 집, 삶, 생계가 파괴되고 있습니다. Web3 Charity와 World Vision은 소말리아의 기근으로 피해를 입은 가족, 특히 인도적 지원에 생존이 달려 있는 어린이에게 식량을 제공하기 위해 손을 잡을 것입니다.",
      "유엔 세계 식량 계획과의 협정은 한 사람이 기부한 1USDC가 27배가 된다는 것을 의미합니다. 1,000USDC의 기부는 WEP의 기여로 27USDC가 됩니다.",
      "세계 밀 생산의 요람인 우크라이나에서의 전쟁을 포함한 여러 갈등의 영향은 세계에서 가장 취약한 어린이들에게 완벽한 폭풍을 만들어내고 있습니다.",
      "한편, 전 세계 여러 지역에서 일어나는 갈등으로 수백만 명의 사람들이 뿌리를 뽑히고 집, 삶, 생계가 파괴되고 있습니다. 전 세계적으로 모든 이주민의 2/3가 굶주림으로 인해 집을 떠나야 했다는 것을 알고 계셨나요?",
      "COVID-19 팬데믹의 여파로 인해 식량 및 기타 상품의 전 세계 가격이 급등했습니다. 이로 인해 가족들이 생존에 필요한 기본 식료품을 살 수 없게 되었습니다.",
      "가뭄, 홍수, 극심한 폭풍, 예측할 수 없는 날씨와 같은 기후 변화의 부정적인 영향은 농작물과 수입을 파괴했으며, 일부 국가에서는 메뚜기 떼가 대거 몰려들어 수확을 황폐화시키기도 했습니다.",
      "기근에 맞서 힘을 합치다",
      "45개국에서 약 5천만 명에게 영향을 미치는 전례 없는 기아 위기에 대응하여 web3 Charity는 World Vision과 협력했습니다. 이 파트너십은 특히 소말리아와 같은 기근에 시달리는 지역에서 절실히 필요한 사람들에게 중요한 식량 지원을 제공하는 것을 목표로 합니다. 이 협력은 아프리카, 라틴 아메리카, 중동, 아시아의 여러 지역에서 놀라운 수준에 도달한 기아라는 중대한 문제를 해결하고자 합니다.",
      "세계 식량 계획으로 영향력 극대화",
      "Web3 Charity는 World Vision과 협력하여 유엔 세계 식량 계획(WFP)과 협력하여 개인 기부금을 활용했습니다. 개인이 기부한 1 USDC는 27배로 증폭되어 1,000 USDC 기부금이 27,000 USDC로 바뀝니다. 이러한 자원은 기근에 시달리는 지역 가정, 특히 생존을 위해 인도적 지원에 의존하는 어린이에게 필수적인 식량 지원을 제공하는 데 필수적입니다. 이 이니셔티브에는 가족에게 식량 패키지를 배포하고 가장 어린 어린이에게 치료적 영양을 제공하여 이 위기 동안 즉각적인 영양 요구 사항을 해결하는 것이 포함됩니다.",
      "파트너",
      "World Vision은 전 세계의 취약한 지역 사회, 가족 및 어린이 사이에서 빈곤과 싸우고 지속 가능한 개발을 촉진하는 기독교 NGO입니다. 이 조직은 매분 필요한 사람들에게 물, 식량 및 권한 부여를 제공합니다. 거의 100개국에 진출한 World Vision은 비상 사태, 위기 및 자연 재해에 신속하게 대응할 수 있습니다. 2021년에 이 조직은 52개국에서 비상 사태의 영향을 받은 3,010만 명 이상을 도왔습니다. World Vision의 회계는 투명성을 위해 매년 감사됩니다.",
      "인도네시아의 SLB Sri Soedewi School for Disadvantaged Children을 지원하세요",
      "Gate Charity와 Worid Vision에 가입하여 소말리아의 기근 피해자를 지원하세요",
      "모금",
      "기부금",
      "지금 기부하기",
      "지원 대상",
      "1982년에 설립된 SLB Sri Soedewi for the Differently-Abled는 시각 장애, 청각 장애, 지적 장애, 신체 장애, 자폐증을 포함한 다양한 장애를 위한 프로그램을 제공하는 100명 이상의 학생을 수용합니다. 초등학교, 중학교, 고등학교 수준의 학생을 수용합니다. 이 기관은 각 학생의 고유한 잠재력과 관심사를 활용하고, 학업 능력과 다양한 기술을 육성하여 전체적인 성장을 보장하는 데 중점을 둡니다. 궁극적인 목표는 학생들에게 사회에서 독립적인 삶을 살 수 있는 생활 기술을 갖추게 하는 것입니다. Web3 Charity와 SLB Sri Soedewi School for the Differently-Abled는 포용성을 촉진하고 모든 어린이에게 안전하고 지원적인 플랫폼을 제공하기 위해 최선을 다하고 있습니다.",
      "모든 기부는 학생들의 삶에 의미 있는 변화를 가져올 것이며, 여러분도 그 일부가 될 수 있습니다.",
      "시각 장애가 있는 학생을 위한 양질의 지원과 학업 생활은 자존감을 키우고, 중요한 우정을 발전시키고, 미래가 탐험할 수 있다는 믿음을 주는 데 도움이 될 수 있습니다.",
      "올바른 학교 환경은 어린이의 사회적, 문화적 요구에 대한 중요한 장기적 대응입니다. SLB Sri Soedewi는 학생들이 빛날 수 있도록 정서적, 언어적, 학업적 지원 시스템을 개발할 수 있는 기회를 제공합니다.",
      "장애는 삶을 살아가는 데 장애물이 아니며, 일부 활동에 접근하고 참여하는 데 장벽이 되지도 않습니다. 그들은 모두 특별하기 때문에 할 수 없는 활동을 하는 다른 방식이 있을 뿐입니다.",
      "단결의 정신은 포용성을 촉진하고 멋진 미소를 짓고 서로 손을 잡고 강한 유대감을 형성하는 데 중요한 요소 중 하나입니다.",
    ],
    5: [
      'Circle에서 첫 번째 연례 "USDC 경제 현황" 보고서 발표',
      '아래는 "USDC 경제 현황 - 디지털 통화의 유용성 가치 단계에 오신 것을 환영합니다" 보고서의 서문입니다.',
      "USDC 경제 현황 보고서 전문을 다운로드하고 Circle.com/Davos에서 세계 경제 포럼 #WEF23의 일정을 확인하세요.",
      "달러 디지털 통화가 돈, 지불 및 금융의 차세대 진화를 주도하고 있습니다.",
      "10년 전, 저는 전통적인 돈을 디지털 통화 형태로 인터넷에 자연스럽게 도입하고 개방적이고 상호 운용 가능한 글로벌 네트워크에서 사용할 수 있다는 비전을 가지고 Circle을 공동 창립했습니다. 인터넷의 첫 10년을 특징짓는 마찰 없는 정보 교환 및 커뮤니케이션을 반영한 마찰 없는 가치 교환의 세계를 여는 것은 그 이후로 Circle의 작업에 활력을 불어넣었습니다.",
      '2013년에는 디지털 통화와 블록체인 기술에 대한 기술적 성숙도, 산업 이해도, 규제 인식이 사실상 존재하지 않았습니다. 실제로 "인터넷의 달러"에 대한 프로토콜을 구축하는 데 필요한 조건조차 존재하지 않았습니다. 우리는 이것이 최소한 10~20년의 여정이 될 것이라는 것을 알고 있었고, 디지털 통화가 개방형 인터넷에 미치는 영향은 정보 인터넷보다 훨씬 더 심오할 것이라는 것을 알고 있었습니다.',
      "이제 10년 후, 세계 최고의 규제된 달러 디지털 통화인 USD 코인(USDC)이 주류로 부상하고 있습니다. 거의 모든 지표와 추세에서 USDC 경제는 성장하고 건강하며 인터넷 규모에서 경제 활동을 촉진하는 주요 세력이 되어 고정된 인프라나 금융 울타리에 의존하는 은행 거래라면 불가능했을 사람과 시장에 도달하고 있습니다.",
      "비트코인과 기타 암호 자산에 대한 투기 시대가 격동의 한 해를 겪었지만, USDC와 같은 디지털 통화는 블록체인의 유틸리티 가치 단계와 프로그래밍 가능하고 구성 가능한 항상 켜진 돈으로의 필수적인 전환을 예고합니다. 인터넷 시대에 이러한 책임 있는 혁신을 촉진하는 것은 기존 금융 시스템과 경쟁하는 것이 아니라 미완의 작업을 완료하는 것입니다.",
      "기회를 관점에 맞춰 설명하자면 USDC와 같은 달러 디지털 통화는 2조 달러가 넘는 결제 산업 수익의 상당 부분을 흡수하기 시작할 수 있으며 21조 달러 규모의 M2 통화 공급의 새로운 디지털 표현이 될 수 있습니다.",
      "예를 들어 상인은 이러한 진화의 중요성을 이해합니다. 85% 이상이 디지털 통화 결제를 활성화하는 것을 우선시하고 있습니다. USDC에 대한 초기 단계의 POS 결제가 여기에 있습니다. 그리고 Checkout.com, Mastercard, Plaid, Stripe, Visa, FIS의 Worldpay 등을 포함한 많은 기존 결제 제공업체는 상인이 USDC를 수용하기 쉽도록 USDC로 구축하고 있습니다.",
      '퍼블릭 블록체인 인프라는 "다이얼업에서 광대역으로" 업그레이드를 거치며 거의 즉각적이고, 매우 안전하고, 프로그래밍 가능하고, 저렴한 거래를 촉진하고 있습니다. 점점 더 많은 신생 기업, 기업, 기존 결제 및 금융 서비스 회사가 가치의 인터넷에 연결하려고 하면서 Web2 빌더와 개발자가 Web3로 몰려들고 있습니다.',
      "한편, 전 세계 정책 입안자들은 초점을 맞추고, 은행 및 지불 규제 기관이 신뢰할 수 있는 디지털 통화를 일관되고 조화로운 방식으로 감독할 수 있는 방법에 대한 절실히 필요한 규제를 도입하고 있습니다.",
      "Circle은 창립 이래로 인터넷 기반, 즉 가치의 저장 및 전송을 위한 개방적이고 글로벌하며 상호 운용 가능한 공공 인터넷 인프라, 그리고 궁극적으로 보다 광범위하게 자본을 중개하는 데 새로운 글로벌 경제 시스템을 구축할 수 있다는 생각에 활력을 얻었습니다. 그 비전이 현실이 되고 있으며 2023년은 화폐, 지불 및 금융의 다음 진화에서 중요한 해가 될 것입니다.",
      "첫 번째 USDC 경제 현황 연례 회고에서 설명했듯이, 글로벌 유틸리티 가치의 녹색 싹이 모든 곳에서 돋아나면서 번영으로 가는 길을 개선하고 글로벌 금융 시스템의 무결성을 보호할 수 있는 개방적이고 책임감 있는 금융 서비스 혁신의 물결을 촉진하고 있습니다.",
    ],
    6: [
      "2024년 USDC 경제 현황의 새로운 소식",
      "새로운 인터넷 금융 시스템이 등장하고 있습니다. USDC, 블록체인 네트워크, 세계 최고의 개발자 및 기술 회사의 힘 덕분에 금융 시스템이 업그레이드되고 있습니다. 더 빠르고 쉽고 안전해져서 모든 사람이 돈을 사용할 수 있습니다.",
      "서문",
      "지난 10년 동안 블록체인 기술의 부상으로 인터넷과 글로벌 금융이 융합되었습니다. 이 융합은 금융 시스템을 위한 소프트웨어 업그레이드를 제공하고 있으며, 이는 혁신에 못지않습니다.",
      "이것이 바로 2013년 Circle이 설립된 계기가 되었습니다. 저희는 개방형 인터넷 소프트웨어의 핵심 요소를 돈의 세계에 주입하는 데 주력했습니다. 저희의 사명은 인터넷의 개방성과 규모를 활용하여 마찰 없는 가치 교환을 통해 글로벌 경제적 번영을 증진하는 것입니다. 즉, 떠오르고 새로운 인터넷 금융 시스템을 구축하는 것입니다.",
      "소프트웨어는 한 세대 전에 많은 기존 산업을 진화시켰던 것처럼 금융 세계를 삼킬 태세입니다. 통신, 엔터테인먼트 및 기타 비즈니스 활동을 더 빠르고 저렴하며 접근하기 쉽게 만들어 비용이 감소하고 장벽이 무너졌으며 혁신이 번성했습니다. 우리는 상거래가 블록체인으로 빠르게 이전되는 것을 수십 년 동안 지속된 디지털 전환 추세의 다음 물결로 보고 있으며, 1990년대와 2000년대 초에 원래 인터넷이 촉발한 것보다 더 큰 사회적, 경제적 영향을 미칠 가능성이 있습니다.",
      "2000년에서 2020년 사이에 인터넷 사용이 전 세계 인구의 7% 미만에서 60%로 증가했다는 점을 고려하세요.1 블록체인이 오랫동안 폐쇄되어 있던 기존 금융의 측면을 개방함에 따라 블록체인 기반 상거래에 대해서도 비슷한 성장 궤적이 예상됩니다.",
      "앞으로 몇 년 안에 수백만 개의 기업과 수십억 명의 사람들이 이 새로운 개방형 Web3 인터넷 계층을 사용하여 수조 달러의 가치를 거래하기 시작할 것으로 예상합니다.",
      "오픈 머니는 인터넷의 개방성과 상호 운용성 설계에 기반한 경제적, 기술적, 철학적 원칙을 포함하는 큰 아이디어입니다. 데이터 배포의 한계 비용을 0으로 줄임으로써 웹은 모든 사람이 인간 지식의 총합을 이용할 수 있게 했습니다. 개발자와 제작자가 쉽게 구축할 수 있게 함으로써 풍부한 사이트와 앱 생태계를 구축할 수 있었습니다.",
      "그러나 인터넷 프로젝트는 아직 완료되지 않았습니다. 데이터가 전 세계적으로 안전하고 즉시 무료로 이동하더라도 인터넷의 통화 고속도로는 아직 포장되지 않았기 때문입니다.",
      "오늘날 우리가 하는 모든 일은 이 인터넷 금융 시스템을 구축하고 가속화하는 데 집중되어 있습니다.",
      "이 보고서에서 자세히 설명했듯이, 모든 것은 USDC를 세계에서 가장 크고 가장 널리 사용되는 스테이블코인 네트워크로 구축하려는 우리의 노력에서 시작됩니다. USDC를 통해 우리는 거의 모든 사람이 어디에서나 인터넷에서 달러에 액세스하고 사용할 수 있는 능력을 제공하고 있습니다. 그리고 우리는 USDC를 기업과 개발자가 오늘날의 지배적인 인터넷 및 금융 플랫폼과 경쟁하고 재편할 수 있는 새로운 물결의 앱을 구축할 수 있는 개방형 플랫폼으로 전환하고 있습니다.",
      "작년은 블록체인 기반 인터넷 네이티브 금융의 미래에 있어 중요한 전환점이 되었습니다. 나쁜 행위자들은 그들의 사악한 활동의 ​​전적인 책임을 지기 시작했고, 사기가 밝혀졌지만, 기반 기술은 성숙해졌고, 주류 기업들은 USDC와 같은 기술에 더욱 깊이 관여했습니다.",
      "주요 결제 회사와 상인 결제 회사는 이제 USDC를 사용하여 현실적인 문제점을 해결하고 있습니다. 블록체인 기반 신용 시장은 USDC를 사용하여 전 세계의 실제 경제 활동을 지원하고 있습니다. 인터넷에서 탄생한 핀테크와 네오뱅크는 인터넷 상거래 속도로 달러를 이동하는 수단으로 USDC로 전환하고 있습니다. 송금 회사와 비정부 기구(NGO)는 USDC를 사용하여 가치를 필요로 하는 사람들에게 빠르고 공정하게 이동하는 어려운 문제를 해결하고 있습니다. 기존 은행, 자본 시장 회사 및 보관 기관은 USDC를 비즈니스 성장의 수단으로 지원하고 있습니다.",
      "이러한 추진력으로 인해 전 세계 정책 입안자들은 결제 스테이블코인과 오픈 블록체인에 책임감 있게 참여하는 방법에 대한 규칙과 지침을 발표하는 데 상당한 진전을 이루었습니다. 그들은 기회의 규모를 이해하고 경제와 사회에 대한 이점을 활용하기로 결심했습니다. 혁신가와 정책 입안자는 함께 인터넷에서 돈으로 무엇이 가능한지에 대한 예술을 재정의하고 있습니다.",
      "아직 초기 단계이기는 하지만 USDC와 더 광범위한 생태계가 탈출 속도에 도달할 준비가 되었다는 확실한 징후가 있습니다. 전 세계적으로 오픈 머니의 힘을 발휘할 것입니다.",
      "2024년 USDC 경제 현황을 즐기시기 바랍니다. 저와 제 팀은 여러분과 결과에 대해 논의하기를 기대합니다.",
      "진심으로,",
      "공동 창립자, 회장, CEO",
      "모든 사람을 위한 달러, 모든 곳",
      "개방형 블록체인 네트워크는 새로운 형태의 화폐를 구동하고 사람과 기업이 전 세계적으로 가치를 교환하는 방식을 재정의하고 있습니다. 이러한 네트워크는 인터넷을 활용하여 깊이 내재된 비용과 마찰을 줄이고 속도를 높이며 국경을 넘나드는 디지털 달러를 보내거나 다른 기존 자산의 토큰화된 버전과 상호 작용하는 등 가치를 교환할 때 가능한 일을 재구성하는 새로운 계층의 금융 기술을 나타냅니다.",
      "이러한 가능성으로 인해 Circle은 2018년에 USDC를 출시했습니다. USDC는 미국 달러의 강점, 신뢰 및 네트워크 효과와 개방형 인터넷의 초능력을 결합한 지불 스테이블코인입니다.",
      "단기적 기회는 상당하며 글로벌 금융의 중심에서 달러의 역할에 의해 주도되어 엄청난 영향을 미치기 시작했습니다. 달러는 라틴 아메리카에서 무역 청구서의 90% 이상, 아시아 태평양에서 74%, 유럽을 제외한 나머지 세계에서 79%를 차지합니다.3 연방준비제도에 따르면, 존재하는 모든 100달러 지폐의 3분의 2를 포함하여 약 1조 달러의 미국 지폐가 미국 외부에 보관되어 있습니다.4",
      "달러의 글로벌 역할은 오늘날 스테이블코인 활동의 압도적 다수가 달러로 표시되어 있음을 의미합니다. USDC는 거의 모든 사람이 어디에서나 인터넷을 통해 디지털 달러에 액세스하고, 보유하고, 거래할 수 있도록 합니다. USDC는 이미 190개국 이상에서 사용할 수 있으며 약 300만 개의 온체인 지갑에 보관되어 있습니다.5",
      "인터넷에서 돈을 위한 개방형 플랫폼",
      "지난 몇 년 동안 USDC는 스테이블코인 네트워크로서 기하급수적인 성장을 경험했습니다. 2018년에 도입된 이후 USDC는 약 12조 달러의 블록체인 거래를 결제하는 데 사용되었습니다. 더 많은 국가의 더 많은 사람들이 블록체인에서 디지털 달러를 잠금 해제하는 이점을 경험하고 있습니다. 이는 개발자들이 새로운 앱을 구축하고 USDC를 중심으로 한 서비스 생태계를 강화하며, 이를 획득, 전송 및 저장하기가 더욱 쉬워지도록 자극합니다.",
      "블록체인 기술의 증가하는 사용 사례는 클라우드 컴퓨팅의 도입 곡선을 떠올리게 합니다. 클라우드는 역사 초기에는 틈새 시장이자 위험한 것으로 여겨졌지만, 이제 클라우드가 기업에 미치는 고유한 이점이 명확해졌습니다. 오늘날 기업은 데이터의 60%를 클라우드에 저장하고 있으며, 대기업 10개 중 9개가 멀티 클라우드 아키텍처를 채택하고 있습니다.6 우리는 더 많은 기업이 엔터프라이즈급 블록체인에서 Web3를 채택함에 따라 이러한 진화의 다음 단계에 접어들고 있습니다. Circle이 구축하는 데 도움을 주는 인터넷 금융 시스템은 클라우드와 경쟁하지 않고 클라우드를 완성하는 데 도움을 줍니다. 실제로 블록체인 네트워크는 신뢰할 수 있는 데이터, 거래 및 컴퓨팅 파워를 퍼블릭 인터넷에 제공합니다.",
      "우리는 더 많은 기업이 엔터프라이즈급 블록체인에서 Web3를 채택함에 따라 이러한 진화의 다음 단계에 접어들고 있습니다.",
      "인터넷 금융을 위한 달러 API USDC는 본질적으로 퍼블릭 인터넷의 달러 API입니다. 이는 잘 규제된 오픈소스 빌딩 블록으로, 다른 핀테크, 은행 및 디지털 통화 프로젝트에 쉽게 통합하여 세계에서 가장 널리 사용되는 통화로 표시된 거의 즉각적이고 거의 무료인 거래를 가능하게 합니다. 이는 금융 기술 스택의 급속한 진화와 API의 증가하는 보편성 시대에 엄청난 기회를 창출합니다.",
      "블록체인 상호 운용성을 위해 구축 USDC는 기본적으로 15개 블록체인 네트워크에서 사용할 수 있습니다. 시간이 지남에 따라 개발자가 활동하고 적절한 보안 조치가 마련되어 있는 모든 곳에서 USDC를 사용할 수 있도록 하여 블록체인 생태계에서 쉽고 안전하게 흐를 수 있도록 하는 것이 저희의 계획입니다.",
      "동시에, 저희는 블록체인의 복잡성을 배경으로 사라지게 하고 있습니다. 최근 출시된 크로스체인 전송 프로토콜(CCTP)은 USDC를 한 블록체인에서 다른 블록체인으로 보낼 때 마찰을 줄이고, 안전성과 보안성을 높이고, 비용을 줄이는 데 도움이 됩니다. 가까운 미래에 저희는 USDC가 기본으로 제공되는 모든 블록체인에서 CCTP 지원을 활성화하여 사용자가 USDC를 한 블록체인에서 다른 블록체인으로 옮길 때 겪는 현재 어려움 없이 인터넷을 통해 달러를 원활하게 보낼 수 있도록 할 것입니다.",
      "개발자를 위한 블록체인을 더 쉽게 만들기",
      "이미 수만 명의 개발자가 블록체인 네트워크를 구축하고 있으며, 이 수치는 앞으로 몇 년 안에 빠르게 증가할 것으로 예상합니다. Circle은 프로그래밍 가능한 지갑, 스마트 계약 템플릿 및 기타 서비스를 제공하여 이 생태계의 성장을 지원하고 기업과 개발자가 블록체인 네트워크에서 새로운 앱을 구축하는 프로세스를 단순화합니다. 규모를 달성한 인터넷 기업은 처음부터 다시 구축할 필요 없이 기존 고객 경험에 USDC 및 기타 디지털 자산을 간단히 넣을 수 있습니다.",
    ],
    7: [
      "달러 업로드",
      "USDC는 스테이블코인이라고도 알려진 디지털 달러로, 24시간 연중무휴로 이용 가능하고 인터넷 속도로 움직입니다. USDC는 기본적으로 인터넷에 존재하며 세계에서 가장 진보된 블록체인에서 실행됩니다. 매일 수십억 달러의 USDC가 손을 바꾸고1, 모든 USDC 디지털 달러는 항상 미국 달러와 1:1로 교환할 수 있습니다.",
      "항상 켜져 있습니다. 항상 열려 있습니다.",
      "USDC는 국경과 은행 영업 시간을 초월합니다. 전 세계로 뻗어 있는 디지털 달러인 USDC는 언제 어디서나 필요할 때 사용할 수 있습니다. 전 세계로 USDC를 보내고, 상품과 서비스를 지불하고, 미래를 위해 저축하는 것이 쉽습니다. 인터넷에 연결된 사람이라면 누구나 USDC를 보내고, 받고, 저축할 수 있습니다.",
      "즉각적인 현금 흐름. 하루 종일. 매일.",
      "더 이상 거래를 마무리하고 현금을 인출하기 위해 기다릴 필요가 없습니다. USDC는 전 세계를 돌며 이메일만큼 빠르게 계좌에 도착할 수 있는 지불로 결제 시간이라는 개념을 쓸모없게 만듭니다. 몇 초면 완료되는 모든 규모의 FX 거래를 상상해보세요. 바로 USDC의 힘입니다.",
      "비용 효율적으로 송금",
      "돈을 사용하는 데 비용이 들지 않아야 합니다. 송금에 50달러를 지불하는 것과 작별하고 1센트의 일부에 인사하세요. 상인은 신용카드 결제에 2.9%의 수수료를 부과받지 않고, 즉각적인 현금 흐름의 혜택을 누리고, 저축을 고객에게 돌려줄 수 있습니다.",
      "재정 재구상",
      "USDC는 현금의 능력을 한 단계 끌어올립니다. USDC로 전 세계적으로 접근 가능한 암호화폐 자본 시장에서 거래, 대출, 차용 및 기금 조달의 기회를 열어보세요. 가능성은 이제 막 시작일 뿐입니다.",
      "오픈 머니 플랫폼",
      "글로벌, 오픈 소스, 상호 운용 가능하고 무료로 사용할 수 있는 uber-money API로 제품을 구축하세요. 개발자는 USDC를 앱의 핵심 구성 요소로 신뢰하여 사용자에게 실시간 지불, 거래 및 금융 서비스를 제공합니다. USDC로 큰 꿈을 꾸세요.",
      "USDC는 금융을 혁신합니다.",
      "USDC는 기존 및 암호화폐 상거래를 아우르는 글로벌 생태계의 일부입니다. 규제된 핀테크 기업인 Circle에서 만든 USDC는 신뢰할 수 있고 널리 받아들여지며 유동성이 높은 디지털 달러입니다.",
      "신뢰할 수 있는 안정성",
      "USDC는 항상 미국 달러와 1:1로 교환할 수 있습니다. USDC 준비금은 BlackRock 및 BNY Mellon을 포함한 주요 미국 금융 기관의 관리 및 보관 하에 보관됩니다.",
      "미국 공인 회계사 협회(AICPA)에서 정한 증명 기준에 따라 매월 유통 금액보다 USDC 준비금이 많거나 같은지 확인하는 월별 증명을 확인하세요.",
      "Circle은 PayPal, Stripe 및 Apple Pay와 마찬가지로 미국 주법에 따라 허가된 송금 기관으로 규제됩니다. Circle의 재무 제표는 매년 감사를 받습니다.",
      "USDC는 어디에나 있습니다",
      "디지털 달러의 세계로의 여정을 선택하세요. 기업, 사용자, 개발자가 USDC를 사용하는 모든 방법을 자세히 알아보거나 지금 USDC를 받으세요.",
      "기업",
      "즉각적인 현금 흐름을 제공하는 번개처럼 빠른 USDC 지불로 글로벌 성장을 가속화하세요. 암호화폐 자본 시장에서 거래, 차용, 대출 및 투자를 위한 USDC 유동성에 액세스하세요. USDC에 액세스하는 가장 좋은 방법",
      "생태계",
      "USDC로 차세대 돈을 경험하세요. 전 세계 어디에서나 24시간 인터넷 속도로 디지털 달러를 보내고, 쓰고, 저축하고, 교환하세요.",
      "개발자",
      "오늘 앱에 USDC를 통합하세요. USDC는 오픈 소스, 구성 가능하고 누구나 빌드할 수 있는 프로그래밍 가능한 디지털 달러입니다.",
      "리더가 USDC를 선택하는 이유",
      "투명성이든 프로그래밍 가능성이든 USDC는 미래를 위해 구축되었습니다.",
      "Consensus 2023의 혁신가들이 Circle과 함께 구축하는 이유 알아보기",
      "파트너에게 Circle과 USDC를 선택한 이유를 물었고, Circle과 USDC는 다르게 구축되었다는 강력한 메시지가 나왔습니다. Circle은 사용 및 구현을 빠르고 쉽게 만들어 주며, USDC의 예비금은 스테이블코인 산업에서 차별화합니다.",
      "Circle Ventures 설립자들이 USDC를 사용하는 방법 알아보기",
      "신뢰와 투명성이 기본 원칙처럼 보이지만, Circle Ventures 포트폴리오에 있는 회사 설립자들이 USDC를 선택하는 주된 이유입니다. 다른 이유로는 프로그래밍 가능한 유동성, 거의 즉각적인 결제 속도, 효율성 등이 있습니다.",
      "스테이블코인 입법의 표준 설정",
      "USDC는 종종 규제된 디지털 통화를 설계하기 위한 청사진으로 여겨집니다. Circle은 최고 수준에서 미국 의회 지도자들과 긴밀히 협력하여 Web3의 개방적 가치를 기존 금융 시스템과 일치시키고 조화롭고 상호 연결된 미래를 만드는 데 도움을 줍니다.",
      "USDC는 암호화폐인가요?",
      "USDC는 암호화폐 또는 디지털 자산의 한 유형인 완전 예비 스테이블코인입니다. 가격이 변동하는 다른 암호화폐와 달리 USDC는 미국 달러와 가격 동등성을 유지하도록 설계되었습니다. USDC는 블록체인 기술의 속도와 보안을 활용하는 안정적인 가치 저장소입니다.\n",
      "USC가 항상 미국 달러로 1:1로 상환될 것이라고 어떻게 믿을 수 있습니까?",
      "USDC는 유동성이 높은 현금 및 현금성 자산으로 100% 지원되는 디지털 달러이며 항상 미국 달러로 1:1로 상환할 수 있습니다. USDC 준비금의 일부는 BlackRock이 관리하는 SEC 규제 머니 마켓 펀드인 Circle Reserve Fund(USDXX)에 투자됩니다. 포트폴리오에 대한 매일 독립적인 제3자 보고서는 공개적으로 제공됩니다. 자세한 내용은 투명성 페이지에서 확인하세요.\n",
      'USDC가 "채굴"되거나 "소각"된다는 것은 무엇을 의미합니까?',
      '기업은 Circle Mint 계정을 개설하여 미국 달러를 USDC로 교환할 수 있습니다. 기업이 Circle Mint 계정에 USD를 입금하면 Circle은 해당 기업에 동일한 금액의 USDC를 발행합니다. 새로운 USDC를 발행하는 과정을 "채굴"이라고 합니다. 이 과정을 통해 유통되는 새로운 USDC가 생성됩니다. \n',
      '마찬가지로, 기업이 USDC를 미국 달러로 교환하려면 기업은 USDC를 Circle Mint 계정에 입금하고 미국 달러를 무료로 받도록 요청할 수 있습니다. 이 USDC 상환 과정을 "소각"이라고 합니다. 이 과정을 통해 USDC가 유통에서 제외됩니다. \n',
      "디지털 자산 거래소에서 미국 달러를 USDC로 교환할 때 거래소는 일반적으로 교환을 이행하기 위해 보유 중인 USDC 잔액을 제공합니다. 거래소에 교환을 이행하기 위해 더 많은 USDC가 필요한 경우 거래소는 종종 Circle Mint 계정을 사용하여 더 많은 USDC를 주조합니다.",
      "USDC에 액세스하려면 무엇이 필요합니까?",
      "기업이 USDC에 액세스하는 빠르고 쉬운 방법은 Circle Mint 계정을 신청하는 것입니다.",
      "USDC는 Coinbase, Crypto.com, Kraken과 같은 디지털 자산 거래소에서도 빠르게 액세스할 수 있습니다.",
      "개발자는 GitHub 리포에 액세스하여 오늘 USDC에서 빌드를 시작할 수 있습니다. 추가 리소스는 개발자를 위한 USDC 페이지에서 제공됩니다.",
      "USC는 어떤 블록체인에서 사용할 수 있습니까?",
      "USDC는 기본적으로 Algorand, Arbitrum, Avalanche, Base, Ethereum, Flow, Hedera, NEAR, Noble, OP Mainnet, Polkadot, Polygon PoS, Solana, Stellar, TRON 등 15개 블록체인에서 액세스할 수 있으며 올해와 그 이후에 더 많은 블록체인이 예상됩니다.",
      "USDC는 또한 타사 브리지를 통해 많은 신흥 블록체인에 브리지되어 USDC와 같은 브리지 형태의 USDC가 생성되었습니다.",
      "자세한 내용은 개발자를 위한 USDC 페이지를 참조하세요.",
      "USDC는 CBC(중앙은행 디지털 통화)와 어떻게 다릅니까?",
      "USDC는 민간 부문의 회사인 Circle에서 발행하는 반면, CBDC는 정부에서 발행합니다. 대부분의 CBDC는 아직 연구 단계에 있는 반면, USDC는 오늘날 존재하며 전 세계 수백만 명의 사람들이 널리 사용하고 있습니다. Circle은 USDC가 11개의 퍼블릭 블록체인에서 실행될 수 있도록 하는 기술을 개발했으며, 오픈 소스 및 민간 시장 혁신이 달러 디지털 통화 모델의 급속한 진전을 주도하고 있습니다.",
      "연방준비제도의 CBDC 논의에 대한 Circle의 통찰력에 대해 자세히 알아보세요.",
    ],
    8: [
      "USD 코인(USDC)이란?",
      "스마트 계약은 두 당사자 이상이 투명하고 갈등 없는 방식으로 돈, 재산, 주식 또는 가치 있는 모든 것을 교환할 수 있도록 하는 자체 실행 디지털 계약으로, 제3자가 필요하지 않습니다.",
      "가장 간단한 방식으로 설명하자면, 스마트 계약을 복잡한 거래의 자동판매기에 비유할 수 있습니다.",
      '스마트 계약은 블록체인에 코드로 작성된 간단한 "if/when…then…" 문장을 따라 작동합니다. 컴퓨터 네트워크는 미리 정해진 조건이 충족되고 검증되면 작업을 실행합니다. 이러한 작업에는 적절한 당사자에게 자금을 지급하거나, 차량을 등록하거나, 알림을 보내거나, 티켓을 발급하는 것이 포함될 수 있습니다. 그런 다음 거래가 완료되면 블록체인이 업데이트됩니다. 즉, 거래를 변경할 수 없으며, 허가를 받은 당사자만 결과를 볼 수 있습니다.',
      "스마트 계약을 사용하면 비트코인을 자판기(예: 원장)에 넣기만 하면 에스크로, 증서, 계약, 상품, 운전면허증 또는 계약의 목적이 무엇이든 간단히 계정에 입금됩니다. 스마트 계약은 주문 조건이 충족되었는지 여부를 판단하기 위한 모든 작업을 수행합니다.",
      "스마트 계약은 기존 계약과 동일한 방식으로 계약 주변의 규칙과 페널티를 정의하고 이러한 의무를 자동으로 시행합니다.",
      "스마트 계약은 어떻게 수익을 창출합니까?",
      "스마트 계약 개발자가 작업을 수익화하는 데는 두 가지 주요 온체인 방법이 있습니다. 프로토콜을 사용하는 데 필요한 Double Profit 기능을 시작하고 블록체인이 블록체인에서 스마트 계약에 서명한 사용자에게 받은 보상에서 얻은 수익의 90%를 보냅니다. 스마트 계약 = 저축 계획, 블록체인이 상당한 비율을 블록체인 창고로 보낸 다음 토큰 가격이 상승함에 따라 더 많은 것을 판매하여 수익을 창출합니다. 스마트 계약 사용에 대해 두 배의 수수료를 청구합니다.",
      "예를 들어, 스마트 계약은 은행의 정기 예금에 대해 두 배의 이자를 받는 원칙과 유사합니다.",
      "스마트 계약은 위험한가요?",
      "스마트 계약은 프로세스를 자동화하고 신뢰 없이 거래를 가능하게 함으로써 업계를 혁신한 블록체인 플랫폼의 자체 실행 코드입니다. 따라서 블록체인 금융에서도 이를 중요하게 여기고 있으며, 지금까지 스마트 계약이 위험한 사례를 접한 적이 없습니다.",
      "좋아요, 스마트 계약은 어디에 존재할까요? 어디에서 실행될까요? 그리고 어떻게 작동할까요?",
      "알아야 할 사항",
      "스마트 계약은 구매자와 판매자 간의 계약 조건이 컴퓨터 네트워크를 통해 자동으로 검증되고 실행되는 자체 실행 코드 줄입니다.",
      '1998년에 "비트 골드"라는 가상 화폐를 발명한 미국의 컴퓨터 과학자 닉 사보는1 스마트 계약을 계약 조건을 실행하는 컴퓨터화된 거래 프로토콜로 정의했습니다.2',
      "블록체인에 배포된 스마트 계약은 거래를 추적 가능하고 투명하며 되돌릴 수 없게 만듭니다.",
      "스마트 계약의 이점 정확성, 속도 및 효율성",
      "조건이 충족되면 계약이 즉시 실행됩니다.",
      "스마트 계약은 디지털이고 자동화되어 있기 때문에 처리할 서류가 없습니다.",
      "수작업으로 문서를 작성할 때 발생할 수 있는 오류를 수정하는 데 시간을 들이지 않았습니다.",
      "신뢰와 투명성",
      "제3자가 관여하지 않고 암호화된 거래 로그가 참여자 간에 교환되기 때문에 개인적 이익을 위해 정보가 변조될까 걱정할 필요가 없습니다.",
      "스마트 계약은 블록체인 네트워크에서 실행되는 컴퓨터 프로그램에 의해 검증, 실행 및 시행됩니다. 스마트 계약에 참여하는 두 당사자가 조건에 동의하면 프로그램이 자동으로 실행됩니다. 계약이 블록체인 네트워크에서 검증되고 시행되므로 제3자가 필요 없습니다.",
      "스마트 계약은 사람이 아닌 코드에 의해 실행되므로 인적 오류의 가능성을 제거하고 전통적으로 인간의 상호 작용이 필요했던 많은 작업을 자동화할 수 있습니다.",
      "블록체인의 가장 좋은 점 중 하나는 모든 허용된 당사자 간에 존재하는 분산 시스템이기 때문에 중개자(중간자)에게 비용을 지불할 필요가 없고 시간과 갈등을 절약한다는 것입니다.",
      "물론 블록체인에는 문제가 있지만 기존 시스템보다 확실히 빠르고 저렴하며 안전합니다. 이것이 우리가 Ethereum, Solana, Tezos, Hyperledger 등을 포함한 다양한 블록체인 네트워크에서 더 많은 스마트 계약이 실행되는 것을 보는 이유입니다.",
      "지갑을 강화하세요",
      "NFT, DeFi 프로토콜, USDC 등으로 혁신적인 경험을 만드세요. 모든 스마트 계약을 통합하여 지갑에 더 많은 기능을 추가하세요.",
      "충성도와 고객 참여도 증가",
      "NFT 드롭, 충성도 포인트 또는 대면 경험과 교환할 수 있는 제한된 토큰을 포함하여 고객과 소통하고 충성도를 높이는 새롭고 독특한 경험을 구축하세요.",
      "스왑, 스테이킹 또는 브리지",
      "임베디드 스마트 계약을 통해 앱에 DeFi 프로토콜을 원활하게 통합하여 DeFi와의 격차를 메우세요.",
      "USDC로 확장",
      "스마트 계약을 활용하여 급여, 구독 또는 지불과 같은 프로그래밍 방식의 USDC 거래를 쉽게 활성화하고 관리하세요",
      "앱에 Web3 상호 작용을 더 빠르게 도입하세요",
      "스마트 계약에 대한 Vitalik Buterin",
      '28세의 이더리움 창립자 Vitalik Buterin이 DC Blockchain Summit에서 설명했듯이 "스마트 계약 방식에서 자산 또는 통화는 프로그램으로 전송되고 프로그램은 이 코드를 실행하고 어느 시점에서 자동으로 조건을 검증하고 자산을 한 사람에게 보내야 할지 다른 사람에게 돌려줘야 할지 또는 보낸 사람에게 즉시 환불해야 할지 또는 그 조합을 자동으로 결정합니다."',
      "그 사이에 분산 원장은 문서를 저장하고 복제하여 특정 보안 및 불변성을 제공합니다.",
      "예: 거래에 대한 에스크로",
      "당신이 나에게서 아파트를 빌렸다고 가정해 보세요.",
      "블록체인을 통해 암호화폐로 지불하면 이를 수행할 수 있습니다. 가상 계약에 보관된 영수증을 받게 되고, 저는 지정된 날짜까지 귀하에게 도착하는 디지털 진입 키를 제공합니다. 키가 제때 도착하지 않으면 블록체인에서 환불을 처리합니다.",
      "대여 날짜 전에 키를 보내면 함수에서 키를 보관하고 날짜가 되면 수수료와 키를 귀하와 저에게 각각 발급합니다.",
      "이 시스템은 If-Then 전제에 따라 작동하며 수백 명의 사람이 목격하므로 오류 없는 배송을 기대할 수 있습니다.",
      "키를 주면 반드시 돈을 받습니다. 비트코인으로 특정 금액을 보내면 키를 받습니다. 할당된 시간이 지나면 문서가 자동으로 취소되고, 모든 참여자가 동시에 알림을 받기 때문에 두 사람 모두 모르게 코드를 간섭할 수 없습니다.",
      "스마트 계약의 실제 사용 사례 식별",
      "IBM의 블록체인 기술 부사장인 제리 쿠오모는 스마트 계약이 금융 서비스에서 의료, 보험에 이르기까지 체인 전반에 걸쳐 사용될 수 있다고 믿습니다. 다음은 몇 가지 예입니다.",
      "DeFi 애플리케이션",
      "DeFi 앱이 주류로 성장함에 따라 우리가 돈을 관리하는 방식에 큰 변화가 일어나고 있습니다.",
      "분산형 금융은 기존 은행 서비스와 규범에서 벗어나는 것입니다. 이는 매우 섬세한 전환이지만 불가피한 것으로 보입니다.",
      "이를 증명할 숫자가 있습니다. DeFi의 연간 거래 가치는 2020년에 14배 증가했고 2021년에는 4배 이상 증가하여 1,120억 7,000만 달러에 달했으며 DeFi 스마트 계약에만 200억 달러 이상의 총 가치가 잠겨 있습니다.",
      "DeFi의 스마트 계약은 상품, 서비스, 데이터, 자금 등의 교환을 용이하게 합니다. 은행 및 신용 조합과 같은 중앙 금융 기관의 사용자는 중개자에게 의존하여 거래를 실행합니다. 반면 DApp은 스마트 계약을 사용하여 각 작업이 진짜이고 투명하며 인적 오류가 없는지 확인합니다.",
      "불과 몇 년 만에 NFT가 시장에 폭발적으로 확산되었고, 2021년 시가총액은 무려 409억 달러에 육박하면서 스마트 계약의 가장 성공적인 사용 사례로 밝혀졌습니다.",
      "NFT는 블록체인에 배포된 스마트 계약이 필요한 주조 프로세스를 통해 생성됩니다.",
      "스마트 계약은 NFT 소유자와 구매자 간의 판매 계약을 구현할 수 있는 도구입니다. 스마트 계약에는 작품의 제작자, NFT가 판매될 때마다 로열티를 받을 자격이 있는 다른 당사자, 작품의 소유권 내역과 같은 NFT에 대한 정보가 포함됩니다.",
      "대부분의 NFT는 블록체인에 기록되지 않습니다. 블록체인에 너무 많은 데이터를 보관하는 것은 비용이 많이 들고 에너지도 많이 소모되기 때문입니다. 그 결과 스마트 계약에는 종종 자신이 대표하는 작품에 대한 링크가 포함되어 있으며, 소유자만 볼 수 있습니다.",
      "정부",
      "내부자들은 우리의 투표 시스템이 조작되기가 극히 어렵다고 주장하지만, 그럼에도 불구하고 스마트 계약은 무한히 더 안전한 시스템을 제공함으로써 모든 우려를 해소할 것입니다. 원장 보호 투표는 디코딩되어야 하며 액세스하려면 과도한 컴퓨팅 파워가 필요합니다. 아무도 그렇게 많은 컴퓨팅 파워를 가지고 있지 않으므로 신이 시스템을 해킹해야 합니다!",
      "둘째, 스마트 계약은 투표율을 낮출 수 있습니다. 관성의 대부분은 줄을 서서 신원을 보여주고 양식을 작성하는 어설픈 시스템에서 비롯됩니다. 스마트 계약을 통해 자원봉사자는 투표를 온라인으로 이전할 수 있으며 밀레니얼 세대는 POTUS에 투표하기 위해 대거 나올 것입니다.",
      "DeFi 애플리케이션",
      "2020년 미국 대선에서 유타 카운티는 Voatz라는 블록체인 기반 모바일 애플리케이션을 통해 부재자 투표자의 투표를 성공적으로 수집했습니다. 유권자 신원은 고유한 서명/해시 값 뒤에 숨겨져 있으며 관련 데이터도 외부 위협 없이 블록체인 내에서 안전하게 유지됩니다.",
      "블록체인은 신뢰의 원천으로 단일 원장을 제공할 뿐만 아니라 정확성, 투명성 및 자동화된 시스템 덕분에 커뮤니케이션 및 워크플로에서 발생할 수 있는 걸림돌을 제거합니다.",
      "일반적으로 사업 운영은 승인을 기다리고 내부 또는 외부 문제가 스스로 해결될 때까지 왔다 갔다를 견뎌야 합니다. 블록체인 원장은 이를 간소화합니다. 또한 일반적으로 독립적인 처리에서 발생하고 비용이 많이 드는 소송과 합의 지연으로 이어질 수 있는 불일치를 제거합니다.",
      "사례 연구",
      "2015년에 Depository Trust & Clearing Corp.(DTCC)는 블록체인 원장을 사용하여 1조 5,000억 달러 상당의 증권을 처리했으며, 이는 3억 4,500만 건의 거래를 나타냅니다.",
      "공급망 자동차",
      "우리가 게으른 인간 이전의 척추동물에서 초지능 로봇으로 진화하고 있다는 것은 의심의 여지가 없습니다. 모든 것이 자동화되는 미래를 생각해보세요. Google은 스마트폰, 스마트 안경, 심지어 스마트 자동차로 그 단계에 도달하고 있습니다. 바로 여기서 스마트 계약이 도움이 됩니다.",
      "한 가지 예는 자율 주행 또는 자체 주차 차량으로, 스마트 계약은 충돌 시 누가 잘못했는지 감지할 수 있는 일종의 '오라클'을 실행할 수 있습니다. 센서 또는 운전자, 그리고 수많은 다른 변수가 있습니다. 스마트 계약을 사용하면 자동차 보험 회사는 고객이 차량을 운전하는 장소와 조건에 따라 요금을 다르게 청구할 수 있습니다.",
      "부동산",
      "아파트를 누군가에게 임대하려면 Craigslist나 신문과 같은 중개업체에 광고 비용을 지불해야 하고, 그 사람이 임대료를 지불하고 이행했는지 확인하기 위해 다시 누군가에게 비용을 지불해야 합니다.",
      "분산형 솔루션은 비용을 절감하는 데 도움이 될 수 있습니다. 암호화폐로 지불하고 스마트 계약에 계약을 인코딩하기만 하면 됩니다. 모든 사람이 보고 자동으로 이행됩니다. 중개인, 부동산 중개인, 하드머니 대출 기관 및 부동산 게임과 관련된 모든 사람이 이익을 얻을 수 있습니다.",
      "스마트 계약은 현재 부동산 관행을 변화시키는 측면에서 혁신적입니다.",
      "스마트 계약은 판매자와 구매자 간의 유일한 계약으로 기존 계약을 대체하고 있습니다. 계약의 특정 조건이 충족되는 즉시 요구 사항을 자동으로 실행합니다.",
      "스마트 계약은 신뢰를 구축하여 단일 버전의 진실을 통해 신뢰를 보장합니다. 은행, 에이전트, 모기지 대출 기관을 포함한 모든 당사자는 스마트 계약을 통해 계약에 서명할 수 있습니다. 거래는 블록체인에 보관되므로 이 공유 원장을 통해 관련 당사자는 언제 어디서나 프로세스를 살펴볼 수 있습니다.",
      "의료",
      "개인 건강 기록은 블록체인에 인코딩되어 개인 키와 함께 저장될 수 있으며, 이를 통해 특정 개인에게만 액세스 권한이 부여됩니다. 동일한 전략을 사용하여 HIPAA 법률에 따라 연구가 수행되도록 할 수 있습니다(안전하고 기밀이 유지되는 방식으로).",
      "수술 영수증은 블록체인에 저장되어 자동으로 보험 제공자에게 배달 증명으로 전송될 수 있습니다.원장은 약물 감독, 규정 준수, 테스트 결과, 의료용품 관리와 같은 일반적인 의료 관리에도 사용할 수 있습니다.",
      "스마트 계약은 굉장합니다!",
      "스마트 계약이 제공하는 이점은 다음과 같습니다.",
      "자율성",
      "당신이 계약을 맺습니다. 브로커, 변호사 또는 기타 중개자에게 확인을 요청할 필요가 없습니다. 우연히도, 이는 제3자가 조작할 위험도 없애줍니다. 실행은 한 명 이상의 편향된 개인이 아닌 네트워크에서 자동으로 관리되기 때문입니다.",
      "신뢰",
      "문서는 공유 원장에 암호화됩니다. 누군가가 분실했다고 말할 수 있는 방법이 없습니다.",
      "백업",
      "은행에서 저축 계좌를 잃어버렸다고 상상해보세요. 블록체인에서 모든 친구가 당신을 지지합니다. 문서는 여러 번 중복됩니다.",
      "안전",
      "웹사이트의 암호화인 암호화는 문서를 안전하게 보호합니다. 해킹은 없습니다. 사실, 코드를 해독하고 침투하려면 비정상적으로 똑똑한 해커가 필요할 것입니다.",
      "스마트 계약은 블록체인 네트워크의 보안을 제공합니다. 기술 자체는 잠재적인 해킹을 방지하기 위한 적절한 종류의 보안을 제공할 수 있지만 스마트 계약을 작성하는 것도 안전을 보장하는 데 도움이 됩니다.",
      "절약",
      "스마트 계약은 중개자의 존재를 없애기 때문에 비용을 절감할 수 있습니다. 예를 들어 거래를 증명하기 위해 공증인에게 비용을 지불해야 합니다.",
      "정확성",
      "자동 계약은 더 빠르고 저렴할 뿐만 아니라 수많은 양식을 수동으로 작성하는 데서 발생하는 오류를 피할 수 있습니다.",
      "스마트 계약은 굉장합니다!",
      "스마트 계약의 미래 중 일부는 앞서 논의한 문제를 얽어매는 데 있습니다.",
      "예를 들어, Cornell Tech에서 스마트 계약이 일상 생활에 들어올 것이라고 주장하는 변호사들은 이러한 우려 사항을 조사하는 데 전념하고 있습니다.",
      "특히 NFT 마켓플레이스가 생겨나면서 스마트 계약의 문제점을 해결하기 위해 그 어느 때보다 더 많은 것이 걸려 있습니다.",
      "스마트 계약은 특정 산업, 특히 법률 산업의 변화에 ​​영향을 미칠 수 있습니다. 이는 변호사가 기존 계약서 작성에서 LegalZoom에서 찾을 수 있는 표준화된 기존 계약서와 유사한 표준화된 스마트 계약 템플릿을 만드는 것으로 전환하기 때문입니다.",
      "상인 인수자, 신용 회사 및 회계사도 실시간 감사 및 위험 평가와 같은 작업에 스마트 계약을 사용할 수 있습니다.",
      "전 세계적으로 더 많은 DeFi 앱이 배포되고 NFT 시장의 가치가 증가함에 따라 스마트 계약이 블록체인을 통해 계약이 검증되고 물리적 사본으로 입증되는 종이와 디지털 콘텐츠의 하이브리드로 통합되고 있음이 분명합니다.",
      "스마트 계약은 계속해서 개선될 뿐이며 기술이 발전함에 따라 점점 더 신뢰할 수 있고 효율적이 될 것입니다.",
      "Blockgeeks에서는 스마트 계약을 시작하는 데 필요한 모든 중요한 주제를 다루는 Ethereum 개발을 포함한 블록체인 기술에 대한 자세한 과정을 제공합니다!",
    ],
    9: [
      "NFT는 디지털 아트, 스포츠 수집품, 미디어 등을 변화시키고 있습니다.",
      "이 생태계는 디지털 상품이",
      "생성, 배포 및 수집되는 방식에 혁명을 일으키고 있습니다.",
      "NFT 세계는 다음과 함께 빠르게 성장하고 있습니다.",
      "블록체인",
      "NFT가 호스팅되고 주조되는 곳.",
      "마켓플레이스",
      "NFT가 상장되고 거래되는 곳.",
      "NFT 프로젝트",
      "생성 예술에서 가상 부동산에 이르기까지 그 자체로 다양합니다.",
      "하지만 이 생태계를 탐구하기 전에 NFT를 정의해 보겠습니다.",
      "Qut Fungibility 파악하기",
      "NFTS란 무엇인가요?",
      "NFT는 소유권 내역과 현재 소유자가 블록체인에 저장된 암호화된 보안 토큰입니다.",
      "이를 통해 디지털 상품에 대한 강력한 1차 및 2차 시장이 형성되어 중개자가 필요 없고 제작자가 자신의 작품을 최대한 활용할 수 있습니다.",
      "대체 가능 대",
      "대체 불가능",
      "대체 가능이란 상품의 개별 단위가 상호 교환 가능하고 가치가 구별되지 않는 경우를 말합니다.",
      "미국 달러와 같은 통화는 대체 가능합니다. 모든 미국 달러는 상호 교환 가능하고 다른 달러와 동일한 가치를 갖습니다.",
      "대체 불가능 토큰(NFT) 또는 상품은 고유한 속성을 가지므로 다른 유사한 토큰이나 상품과 다른 가치를 갖습니다.",
      "테라스가 있는 집은 다르게 평가되며 테라스가 없는 집과 상호 교환할 수 없습니다.",
      "테라스는 집을 대체 불가능하게 만들고 다른 가치를 갖는 여러 속성 중 하나일 수 있습니다.",
      "마찬가지로 대체 불가능 토큰은 다른 토큰과 구별되는 다른 예술적 속성이나 기타 속성을 가지므로 고유한 가치를 갖습니다.",
      '크립토펑크는 10,000개의 픽셀 아트 "펑크"로 구성된 NFT 컬렉션으로, 두 펑크가 똑같은 것은 없습니다.',
      "다양한 희귀도의 다른 속성은 특정 펑크를 더 희귀하고 더 높은 가치로 만듭니다.",
      "속성의 희소성이 가치 평가를 결정하는 경향이 있지만, 투자자와 수집가의 미적 및 주관적 선호도도 가치 평가에 영향을 미칠 수 있습니다.",
      '"buck teeth" 속성을 가진 펑크는 78개이고 "hoodie" 속성을 가진 펑크는 259개입니다. "hoodie" 펑크는 "buck teeth" 펑크의 세 배의 가치가 있습니다.',
      "출처: Larya Labs",
      "Eth 가격 4,430달러로 계산한 달러 가치",
      "2021년 11월 29일 기준 펑크 가치",
      "Mighty Minters",
      "NFT를 호스팅하는 블록체인 플랫폼",
      "시가총액",
      "이러한 블록체인의 대부분은 작업 증명에 비해 에너지를 상당히 적게 소모하는 지분 증명 합의를 사용합니다.",
      "Ethereum은 내년 언젠가 지분 증명으로 전환될 예정입니다.",
      "Tezos는 전담 NFT 커뮤니티를 보유하고 있으며 최초의 비-Ethereum NFT 플랫폼 중 하나였습니다.",
      "출처: Messari.io",
      "2021년 11월 29일 기준",
      "ETHEREUM 토큰 표준 탐색",
      "이더리움은 NFTS를 위한 가장 큰 플랫폼이며, NFT는 네트워크의 전송 및 활동에서 엄청난 부분을 차지합니다.",
      "ERC 721 토큰과 함께:",
      "ERC-20 계약 표준은 대체 가능한 토큰을 만드는 데 사용되며, 이더리움 네트워크 전체에서 가장 일반적으로 사용됩니다.",
      "ERC-1155 토큰 표준은 Enjin에서 만들었으며 대체 가능한 자산과 대체 불가능한 자산을 모두 만들 수 있습니다.",
      "ERC-998은 NFT가 NFTS 및 ERC-20 토큰을 소유할 수 있도록 하는 ERC-721 표준의 확장입니다.",
      "이더리움의 NFT 토큰 표준은 이더리움 블록체인에 구축된 dapp(분산형 애플리케이션)과 상호 작용하여 원활한 통합을 허용합니다.",
      '이더리움 네임 서비스 NFT는 이더리움 지갑의 이름을 고유하고 기억하기 쉬운 주소 이름을 위해 선택한 ".eth" 주소로 변경합니다.',
      "이러한 NFT는 웹 도메인 이름과 유사하게 연간 기간 동안 구매됩니다.",
      "2차 시장의 열린 바다",
      "현재 NFT를 사고 파는 다양한 2차 시장이 있습니다.",
      "OpenSea는 지배적인 플랫폼으로 부상했습니다. 2021년 11월 거래량이 19억 달러였습니다.",
      "오늘날 NFT의 위치",
      "디지털 아트, 수집품, 게임 등",
      "NFT는 소유권에 대한 디지털 증명 그 이상이 될 수 있으며, 물리적 자산이나 혜택과 연결될 수 있습니다.",
      "Kings of Leon의 최신 앨범 When You See Yourself는 물리적 자산 및 혜택과 연결된 다양한 기간 한정 NFT와 함께 출시되었습니다.",
      "A$50(£35.70) NFT는 소유자에게 음악의 디지털 다운로드, 앨범의 물리적 비닐 및 디지털 상품에 대한 액세스를 제공합니다.",
      '6개의 "골든 티켓 NFT"는 보유자에게 밴드의 모든 쇼에서 4개의 최전방 좌석에 대한 평생 액세스를 제공합니다.',
      "영국 아티스트 데미안 허스트의 NFT 컬렉션 The Currency는 사람들이 NFT와 물리적 예술을 어떻게 평가하는지 탐구합니다.",
      "화폐는 10,000개의 고유한 NFT로 구성되어 있으며, 각각 2016년에 제작된 해당 물리적 ​​작품이 있습니다.",
      "물리적 작품은 영국의 금고에 보관되며, NFT 구매자는 1년(2022년 7월 27일까지) 동안 NFT를 보관할지 물리적 버전을 보관할지 결정해야 하며, 결정이 내려지면 다른 버전은 폐기됩니다.",
      "현재 NFT 공간에는 세 가지 주요 분야가 있으며, 각각 고유한 방식으로 혁신을 추진하고 있습니다.",
      "프로필 사진 컬렉션에서 생성적 예술 및 협업 음악 작품에 이르기까지 NFT는 디지털 아트가 번성할 수 있도록 해줍니다. 음악이든 스포츠 수집품이든, 비주얼 아트 NFT 외에도 더 많은 것이 있습니다.",
      "NFT 게임 공간은 혁신으로 가득 차 있으며, 다양한 프로젝트에서 게임 내 자산 및 구매에 대한 플레이어 소유권과 플레이 투 어닝 모델을 구현하고 탐구하고 있습니다.",
      "NFT는 디지털 소유권, 아이덴티티 및 공간을 재정의하고, 가상 현실에서 디지털 랜드를 토큰화하고, 소유자를 나타낼 3D 아바타를 제공합니다.",
      "디지털을 넘어서.",
      "NFT가 빅뱅 순간을 맞았지만 붐은 끝나지 않았습니다.",
      "이러한 분야와 함께 NFT는 여전히 주택이나 물리적 예술 작품과 같은 실제 자산을 토큰화하고 디지털 전송을 가능하게 하는 방법으로 탐구되고 있습니다.",
      "거의 모든 물리적 자산이나 상품은 소유권을 더 쉽게 증명하고 교환할 수 있도록 토큰화할 수 있으며, 분할, 담보화 및 스마트 계약이 제공할 수 있는 유틸리티와 같은 가능성도 열어줍니다.",
      "NFT 생태계 매핑",
      "NFT는 2021년 가장 화제가 되고 가장 거품이 많은 시장이었으며, 판매량이 100배나 증가했고 저녁 토크쇼에서도 화제가 되었습니다.",
      "암호화폐가 주류에 침투하는 데 거의 10년이 걸렸지만, NFT는 사람들의 관심을 사로잡는 데 불과 몇 년이 걸렸습니다. Budweiser, Visa, Adidas와 같은 브랜드가 NFT를 구매하여 이 분야에 진출하면서 NFT가 단순히 새로운 인기 트렌드 이상이라는 것이 분명해졌습니다.",
      "Next Decentrum에서 후원하는 이 인포그래픽은 NFT를 정의하고 이를 중심으로 빠르게 성장한 번영하는 생태계를 살펴봅니다. 대체 불가능한 것이 무엇을 의미하는지, NFT가 어디에서 주조되고 거래되는지, 이 자산 클래스의 미래가 어떻게 될지 알아보세요.",
      "NFT란 무엇이고, 대체 가능성은 무엇인가요?",
      "NFT는 소유권 내역과 현재 소유권이 블록체인에서 암호화되어 보호되는 대체 불가능한 토큰입니다. 이러한 토큰은 jpeg 형식의 디지털 아트 작품이든 mp3 파일 형식의 노래이든 무엇이든 나타낼 수 있습니다.",
      "이러한 토큰의 거래를 블록체인에 저장함으로써 이중 지출이나 과거 거래 및 소유권의 변조에 대한 두려움 없이 이러한 디지털 상품에 대한 소유권과 시장을 디지털로 증명할 수 있습니다.",
      "대체성 파악",
      "이 모든 것이 암호화폐와 매우 유사하게 들리지만, NFT를 그렇게 특별하게 만드는 것은 무엇일까요? 대체 불가능성입니다. 비트코인이나 이더리움과 같은 암호화폐와 달리 대체 불가능한 토큰은 고유한 속성과 특성을 가진 상품이나 자산을 나타내므로 동일한 컬렉션의 일부이더라도 고유한 가치를 가질 수 있습니다.",
      "대체 가능: 가치가 구별되지 않는 상호 교환 가능한 단위가 있는 상품. 예: 미국 달러, 비트코인, 아케이드 토큰",
      "대체 불가능: 고유한 속성을 가진 상품으로 유사한 상품과 비교했을 때 고유한 가치를 갖습니다. 예: 부동산, 그림, NFT.",
      '가장 인기 있는 NFT 컬렉션인 Cryptopunks는 다양한 모자, 안경, 헤어스타일 등과 같은 다양한 속성을 가진 10,000개의 픽셀 아트 "펑크" 컬렉션입니다. 희소성이 다른 속성의 무작위 조합으로 인해 각 펑크는 고유한 가치를 갖게 됩니다.',
      "희소성과 주관적인 미적 선호도는 Cryptopunks 및 기타 NFT의 가치를 좌우하며, 역사적 중요성과 호스팅되는 블록체인과 같은 다른 요소도 가치에 영향을 미칩니다.",
      "NFT 가능 블록체인 비교",
      "NFT를 주조하고 호스팅할 수 있는 다양한 블록체인이 있으며, 현재 시가총액과 거래량 면에서 가장 크고 가장 많이 사용되는 것은 Ethereum입니다.",
      "디지털 아트, 게임, 메타버스 및 NFT의 미래",
      "NFT는 2021년에 큰 인기를 끌었으며, 창작자에게 작품을 호스팅하고 교환할 수 있는 디지털 및 분산형 네트워크를 제공했습니다.",
      "현재 디지털 우선 사용 사례가 NFT 개발의 최전선에 있으며, 메타버스에서 게임 내 자산이나 상품의 소유권이 탐구되고 있는 두 가지 주요 사용 사례입니다. 그러나 NFT는 부동산, 물리적 아트워크 등과 같은 물리적 자산을 토큰화하는 데 사용할 수 있어 응용 프로그램에 대한 거의 무한한 가능성이 열립니다.",
      "오늘날의 부동산 거래소에서 서류 작업과 관료주의의 마찰을 제거하는 것부터 자산 소유권의 쉬운 분할을 허용하는 것까지, NFT의 실질적인 실제 사용 사례는 이제 막 탐구되기 시작했습니다.",
    ],
    10: [
      "Web3를 싫어하든 좋아하든, 벤처 캐피털리스트 마크 앤드리슨, 전 트위터 CEO 잭 도르지, 테슬라 최고경영자 일론 머스크와 같은 기술 영향력 있는 사람들이 최근에 Web3에 대해 논쟁을 벌이고 있기 때문에 지금은 인기가 많습니다.[1] Web3 지지자들은 또한 Web3가 경제 환경을 재편하고 있다고 믿습니다. Gartner는 Web3가 2030년까지 세계 GDP를 1조 7,600억 달러 늘릴 것으로 추정합니다.[2] Web3의 부상은 가상 거버넌스, 데이터 프라이버시, IP 보호, 규정 준수 및 침해와 같은 많은 법적 문제를 불가피하게 제기합니다. 이는 또한 기술 변호사와 로펌이 이 빠르게 진화하는 법률 분야에 참여할 수 있는 많은 비즈니스 기회를 제공합니다.",
      "Web3란 무엇인가요?",
      "Web3는 빌더와 사용자가 소유한 분산 및 블록체인 기술을 기반으로 구축된 새로운 플랫폼으로, 사람들이 암호화폐, NFT 및 메타버스를 새로운 플랫폼으로 결합할 수 있습니다. Web3에서 개인은 각 사용자에게 직접 서비스를 제공하고 시스템 내에 본질적으로 존재하는 가치 있는 항목을 거래할 수 있습니다. Web3 애플리케이션/네트워크/플랫폼의 한 예로는 은행을 사용하는 대신 분산형 블록체인에서 작동하는 메타버스(사용자가 협업하고, 만나고, 게임을 하고, 교류할 수 있는 여러 가상 공간을 결합한 온라인 3차원 우주)가 있습니다.",
      "Web3에는 법적 보호가 필요할까요?",
      "분산형 플랫폼은 규제되지 않는다는 오해가 있습니다. 또한 디지털 자산은 지역 규제 기관이 거래를 규제하는 법률과 규정을 도입한 세계 여러 지역에서 빠르게 성장하고 있습니다. 예를 들어, 미국 재무부의 금융 범죄 단속망(FinCEN)은 암호화폐 거래소를 추적하고 의심스러운 활동을 보고하는 규칙을 개발하고 있습니다. 또한 호주와 캐나다는 비트코인을 세금이 부과될 수 있는 가치가 있는 금융 자산으로 간주합니다. 아시아에서는 일본 의회가 6월에 투자자 보호를 위해 스테이블코인 또는 암호화폐를 규제하는 법안을 통과시켰습니다.",
      "전 세계적으로 극적으로 성장하고 있는 메타버스에서 법적 우려가 커질 것입니다. 일반적으로 많은 사용자는 NFT가 소유권에 대한 부인할 수 없는 증거를 제공한다고 믿습니다. 하지만 상황이 우리가 생각했던 것처럼 간단하지 않을 수도 있습니다. 예를 들어 인디애나 대학교 법학 교수인 조앙 마리노티에 따르면, 가상 토지 소유권은 재산법이 아닌 계약법에 속하기 때문에 재산 소유권이 있습니다. 또한 NFT를 구매한다고 해서 사용자에게 디지털 자산에 대한 소유권이 부여되는 것은 아닙니다.\n",
      "Web3는 완전히 새롭고 복잡한 환경이지만, 문제가 발생할 경우 새로운 종류의 법적 보호가 필요합니다. 암호화폐 소송이 급증하고 있습니다. 자산의 라이선스 및 공동 소유와 관련된 NFT에 대한 분쟁이 발생합니다. 오늘날 기업은 거버넌스, IP 보호 및 침해와 관련된 많은 법적 문제를 제기하는 비즈니스 및 마케팅 전략의 일부로 메타버스를 사용합니다.\n",
      "Web3의 기회",
      "모든 기술 환경이 최대한의 잠재력을 발휘하려면 모든 당사자의 이익을 보호하기 위한 설정된 권리와 의무를 포함하는 적절한 법적 프레임워크를 통합해야 합니다. 우리가 해결해야 할 법적 문제에는 세금, 가상 거버넌스, 규제 문제, 라이선싱 및 소유권, 거래 추적 및 보고, 데이터 프라이버시, IP 보호가 포함됩니다.\n",
      "뒤처지지 않으려면 전통적인 로펌, 변호사, 법학생은 Web3 기술의 개발과 Web3 및 지역 정부와 관련된 변화하는 법률 및 규정을 이해해야 합니다.",
    ],
    11: [
      "암호화폐 직불카드의 작동 방식",
      "암호화폐 직불카드는 Coin과 같은 암호화폐 금융 서비스를 전문으로 하는 회사에서 사용합니다. 이러한 회사는 Visa 및 Mastercard와 같은 결제 네트워크와 거래를 하여 사용자가 기존 결제 시스템을 허용하는 곳에서 암호화폐를 사용할 수 있도록 합니다.\n",
      "이 프로세스를 통해 거래가 안전하고 투명하게 보장됩니다. 게다가 변환이 완료될 때까지 몇 시간 또는 며칠을 기다리지 않고도 빠른 변환율을 제공합니다.\n",
      "올바른 암호화폐 직불카드 선택",
      "이 기사의 이 부분에서는 원활한 일상 거래를 위한 암호화폐 직불카드를 얻는 방법을 보여드리겠습니다. 암호화폐를 법정 통화로 변환하고 유효한 직불카드를 허용하는 곳에서 쇼핑하세요. 또한 시중의 여러 옵션 중에서 최고의 암호화폐 직불카드를 얻을 때 주의해야 할 요소에 대해서도 설명하겠습니다.",
      "•지원되는 암호화폐: 최고의 암호화폐 직불카드를 얻으려면 카드가 암호화폐를 지원하는지 확인해야 합니다.",
      "• 수수료: 암호화폐 직불카드는 종종 충전 수수료, 거래 수수료, ATM 인출 수수료 등 다양한 수수료를 부과합니다. 카드를 선택하기 전에 다양한 카드에서 부과하는 수수료를 비교하세요.\n",
      "• 보상: 지출을 극대화하기 위해 캐시백 보상 프로그램이 있는 암호화폐 직불 카드를 선택하세요. 가장 높은 캐시백을 제공하는 최고의 암호화폐 직불 카드를 검색, 비교 및 ​​선택하세요.\n",
      "• 보안: 암호화폐 직불 카드는 다양한 보안 기능을 사용하여 암호화폐를 보호합니다. 강력한 보안 기능을 제공하는 최고의 암호화폐 직불 카드를 선택하세요.",
      "• 고객 지원: 직불 카드가 있는 최고의 암호화폐 지갑을 얻으려면 고려해야 할 주요 사항 중 하나는 지원의 질입니다. 문제가 발생했을 때 지원에서 도움을 줄 수 없다면 돈과 시간을 낭비하게 되므로 유효한 지원을 선택하세요.\n",
      "이들은 실제 거래에서 디지털 자산을 사용할 수 있게 해주는 혁신적인 솔루션입니다. 블록체인 기술을 활용하여 이러한 카드는 주요 직불 카드가 허용되는 모든 곳에서 사용할 수 있는 안전하고 투명한 거래를 용이하게 할 수 있습니다.\n",
      '미국 최대의 암호화폐 거래소 중 하나가 금요일에 원활한 디지털 자산 이전 및 채택을 위해 글로벌 지불 프로세스와 "긴밀히" 협력하고 있다고 밝혔습니다.',
      '암호화폐에 대한 규제 우선 접근 방식으로 유명한 샌프란시스코에 본사를 둔 암호화폐 거래소인 Coinbase는 오늘 디지털 통화 채택을 강화하기 위해 "Visa와 같은 혁신 기업과 협력하게 되어 자랑스럽다"고 말했습니다.\n',
      "거래소는 Visa와 같은 기업이 암호화폐와 전통적인 금융 간의 격차를 메우는 데 도움을 주고 있다고 말했습니다. 이를 통해 [전통적 및 암호화폐] 분야의 리더를 하나로 모으는 동시에 암호화폐를 글로벌 네트워크에 연결할 수 있는 환경이 조성됩니다.\n",
      "Visa가 디지털 통화 접근 방식을 제시",
      "4,200억 달러의 가치를 지닌 결제 대기업 Visa는 네트워크에서 초당 25,000건 이상의 거래를 수행하며 이번 주 초에 블로그 게시물을 통해 암호화폐에 대한 계획을 밝혔습니다.\n",
      '"디지털 통화에 대한 접근 방식 발전"이라는 제목의 이 회사는 디지털 자산을 새로운 디지털 우선 전략의 중심에 두어 더 많은 시장을 개척하고 전 세계적으로 금융 포용성을 개선했습니다.\n',
      "Visa는 Coinbase와 같은 라이선스 및 규제를 받은 디지털 통화 플랫폼과 적극적으로 협력하여 디지털 통화와 6,100만 개의 기존 글로벌 상인 네트워크 간의 교량을 제공하고 있다고 언급했습니다.\n",
      '이 회사는 현재 25개 이상의 디지털 통화 지갑이 Visa 네트워크에 연결되어 있어 "사용자가 Visa 직불 또는 선불 자격 증명을 사용하여 디지털 통화 잔액에서 쉽게 지출할 수 있는 방법을 제공한다"고 지적합니다.\n',
      "다른 개발에는 소비자의 디지털 통화를 빠르게 변환하고 이를 추가하여 Visa 자격 증명을 푸시하는 회사의 즉각적인 법정 통화-암호화폐 교량인 Visa Direct와 같은 혁신이 포함됩니다. \n",
      'Visa는 이러한 노력이 디지털 통화 지갑에서 브랜드를 채택하기에 최적이라고 말했습니다. 후자는 "전 세계적으로 디지털 통화를 더 빠르고 쉽게 사용할 수 있도록 하여 사용자에게 가치를 더하고 싶어합니다." \n',
      "미래 경제의 큰 부분을 차지하는 스테이블코인",
      "Visa는 공공 부문에서 암호화폐를 출시하기 위한 광범위한 전략에서 정책 입안자 및 규제 기관과 협력하여 회사에 디지털 통화의 이점을 알리고 있다고 말했습니다.\n",
      "암호화폐 직불 카드로 거래",
      "직불 카드가 있는 암호화폐 지갑을 사용하면 변환하고 프로세스가 끝날 때까지 기다릴 필요 없이 암호화폐를 쉽게 사용할 수 있습니다. 공급업체가 자동으로 암호화폐를 법정 통화로 변환하여 이체합니다.",
      "일상 거래에 암호화폐 직불 카드 사용",
      "이 카드는 기존 직불 카드처럼 작동하여 Visa 또는 Mastercard가 허용되는 모든 곳에서 사용할 수 있습니다. 이러한 카드는 일상적인 거래를 용이하게 하며 캐시백이나 암호화폐 포인트 보상 프로그램과 같은 기존 카드에 비해 이점을 제공합니다.\n",
      "암호화폐 직불 카드의 보안 기능",
      "암호화폐 직불 카드는 암호화, 2단계 인증, 사기 방지, FDIC 보험을 사용하며 기존 카드와 동일한 보안 규정을 준수하여 암호화 및 개인 정보를 보호합니다.\n",
      "암호화폐 직불 카드 사용의 이점",
      "암호화폐 직불 카드를 받으면 분산화로 인해 편의성, 보상 및 자산에 대한 완전한 통제와 같은 많은 이점을 얻을 수 있습니다.",
    ],
    12: [
      "USD 코인(USDC) 저축 계좌: 암호화폐에 대한 이자 획득",
      "Web3에는 암호화폐 저축 계좌에 대한 선택 사항이 방대하고 계속 늘어나고 있습니다. 이러한 저위험 고수익 저축 계좌는 사용자가 월 최대 200%의 암호화폐 이자를 + 복리로 획득할 수 있도록 도와줍니다. USD 코인(USDC)은 유통 중인 가장 많이 사용되는 스테이블코인 중 하나입니다. 이에 대한 정보와 YouHodler에서 암호화폐 이자를 쉽게 축적하는 방법에 대한 추가 사실이 있습니다.",
      "USD 코인(USDC)이란?; 암호화폐를 획득하는 방법",
      '다른 많은 암호화폐와 달리 USDC는 "스테이블코인"이라고 하는 고유한 유형의 암호화폐입니다. USDC는 1:1 비율로 미국 달러에 의해 지원됩니다. 따라서 항상 1 USD 코인을 1.00달러 미국 달러로 교환할 수 있습니다. 이는 비트코인이나 다른 암호화폐보다 훨씬 더 안정적인 자산이 되어 자산을 보관하기에 안전한 장소가 됩니다.',
      "USDC는 이더리움 기반 토큰이므로 예를 들어 YouHodler의 USDC 지갑과 같은 이더리움 호환 지갑에 저장할 수 있습니다. USDC 팀은 사용자가 암호화폐 지갑에서 기업, 거래소 및 다른 사람에게 전 세계로 달러를 쉽게 이동할 수 있도록 이 스테이블 코인을 설계했습니다. YouHodler는 플랫폼에서 암호화폐 이자를 획득하는 또 다른 훌륭한 옵션으로 라인업에 추가했습니다.",
      "USDC 저축 계좌: Web3에서 암호화폐에 대한 이자를 획득하는 이유",
      "Web3는 디지털 자산에서 수익을 창출하는 다양한 방법을 제공하며, 암호화폐 저축 계좌를 통해 사용자는 고수익, 저위험 투자 도구를 잠금 해제할 수 있습니다. Web3에서 암호화폐에 대한 이자를 획득해야 하는 몇 가지 이유는 다음과 같습니다.",
      '암호화폐에서 최대 2.6% 및 10% USDC 이자 획득: Web3에서 연간 2.6% 및 10% USDC 일일 이자를 획득합니다. 이는 "고수익" 기존 은행 저축 계좌 및 기타 투자보다 훨씬 높습니다. Web3는 고객에게 시장에서 가장 좋은 USDC 환율을 제공합니다.',
      "자산 안전 유지: Web3의 모든 암호화폐 자금은 보관과 관련하여 업계 모범 사례를 사용하여 보호됩니다. 자금은 항상 핫 월렛에 100% 저장됩니다. 저희는 핫 월렛과 콜드 월렛의 하이브리드 저장 방식을 사용하여 사용자 자금을 최대한 보호합니다.",
      "암호화폐 이자는 수수료 없이 4시간마다 지급됩니다. 각 4시간 결제 기간이 끝나면 발생한 모든 암호화폐 이자가 사용자의 저축 계좌 입금에 적립됩니다. 사용자는 저축 계좌에서 지갑으로 출금하거나 계좌에 자금을 보관하여 다음 청구 주기 동안 더 많은 암호화폐 이자를 받을 수 있습니다. (계좌에서 이자 인출 횟수는 하루에 3회이며, 각 인출은 1 USDC보다 커야 합니다.)",
      "Web3로 암호화폐 이자를 받는 다른 방법 알아보기",
      "USDC 코인 이자는 Web3를 통해 암호화폐에 대한 이자를 받는 한 가지 옵션일 뿐입니다. 저희의 이자 받기 페이지를 방문하여 다양한 스테이블코인과 암호화폐 스테이블코인을 확인하세요. 비트코인, 바이낸스 코인 등에 대한 이자를 받으세요. 저축 계좌로 암호화폐를 벌고 HODL을 위한 더 효율적인 방법을 알아보세요.\n",
      "이자가 지급되는 USDC 저축 지갑 앱",
      "Wwb3 지갑 앱은 일반 지갑처럼 USD 코인(USDC)을 보관할 수 있고, 4시간마다 USDC 지갑 잔액에 대한 이자를 받을 수 있는 유일한 앱입니다.\n",
      "USDC 이자가 작동하는 방식",
      "기존 은행 저축 계좌와 마찬가지로 USDC에 대한 이자를 받을 수 있지만, 한 가지 주요 차이점이 있습니다. 은행의 거의 0%에 가까운 이자율 대신 ETH에 대해 최대 10%의 이자율을 제공합니다.\n",
      "무료 USDC를 얻는 방법",
      "간단하죠! 간단히 USDC를 Web3 지갑에 입금하면 4시간마다 무료 ETH를 얻을 수 있습니다.",
      "이자가 ETH인 이유는 무엇인가요?",
      "지갑에 USDC를 저장하면 4시간마다 얻는 이자는 암호화폐 ETH입니다. 그러나 블록체인에서 얻는 ETH는 지갑으로 직접 인출할 수 없습니다. Web3 블록체인에 로그인해야 합니다. 계정에서 승리하여 얻은 ETH는 지갑으로 인출하기 전에 USDC로 변환해야 합니다. 다음은 Web3 계정에서 승리하여 얻은 ETH를 USDC로 변환하는 방법에 대한 자세한 설명입니다.",
      "이자를 인출하기 전에 먼저 Web3 계정에 들어간 다음 교환을 클릭하고 모두 변환을 클릭하여 모두 변환해야 합니다.",
      "다음 단계는 Web3 계정에서 교환한 USDC를 지갑으로 인출하는 것입니다. 다음은 USDC 인출에 대한 자세한 설명입니다.",
      "이제 인출을 클릭한 다음 총 잔액을 클릭하고 마지막으로 확인을 클릭해야 합니다. 수익 인출이 완료되었습니다. USDC의 일반적인 출금은 10분 이내에 도착합니다. 네트워크가 혼잡한 경우 도착이 지연됩니다.",
      "USDC 및 ETH 누적 이자",
      "예, Web3 계정에 USDC와 ETH를 보유하면 누적 이자가 발생합니다. 4시간마다 복리 이자를 계산합니다.",
      "USDC 일별 수익률 및 수익률",
      "다른 플랫폼과 비교하여 APR 대신 일별 수입을 제공합니다. 일별 이자율은 복리 이자의 영향 없이 이자를 지급합니다. 따라서 Web3 일별을 사용하면 누적 이자로 인해 월말에 더 많은 USDC를 받게 됩니다.\n",
      "USDC 저축 계산기",
      "간단히 USDC 저축 계산기를 사용하여 Web3에 3개월, 6개월 또는 12개월 동안 보유하면 얼마를 벌 수 있는지 확인하세요.",
    ],
    13: [
      "이번 호의 3,000,000 ETH는 채굴자와 블록체인 간에 체결된 DeFi 대출 계약에서 얻은 것입니다. DeFi 대출 계약에 따르면, 채굴자와 블록체인 간에 체결된 DeFi 대출 계약이 3,000,000 ETH에 도달하면 블록체인은 블록체인의 데이터를 보관하고 ETH 자체의 가치를 보여주기 위해 블록체인 프로젝트에 3,000,000 ETH를 흘릴 권리가 있으므로 블록체인은 사용자가 저장한 USDC의 원금에 따라 이자율을 지불하고 USDC를 저장하는 사용자는 ETH로 이자를 받게 됩니다.\n",
      "1 ETH의 이자는 어디에서 나오나요?",
      "채굴자는 DeFi 프로토콜 대출을 통해 블록체인에서 채굴한 ETH를 빌립니다. 현재 블록체인은 이미 채굴자의 DeFi 프로토콜 대출에서 300만 ETH를 받았으므로 블록체인은 주요 Web3 지갑과 연계하여 수익을 위해 USDC를 저장하는 DeFi 프로젝트를 시작했습니다. 이 300만 ETH가 이자로 발송될 때까지 다음 블록체인 저장 수익 프로젝트에 참여할 수 있습니다. 과거 경험에 따르면 이 300만 ETH는 3~5개월 내에 배포됩니다.",
      "예를 들어, 채굴자의 블록체인과의 DeFi 대출 계약이 300만 ETH에 도달하면 블록체인은 채굴자가 약속한 ETH를 주요 Web3 지갑과 결합하여 ETH 자체의 가치를 반영하도록 유동화할 권리가 있습니다.",
      "또한, 현재 블록체인 VIP 스토리지 사용자가 되면 블록체인에서 발견한 다음 노드에 들어가 수익을 위해 계속 저장하도록 초대받는 기회를 얻게 됩니다.\n",
      "DeFi ETH 대출",
      "일반적으로 더 높고 APY에서 자동으로 차감",
      "플랫폼에서 결정 - 고정",
      "내부 작동에 대한 통찰력이 없는 중앙 집중식, 불투명한 플랫폼",
      "낮음 - 몇 번의 클릭으로 수행 가능",
      "해킹, 플랫폼 부실, 파산",
      "수수료는 일반적으로 더 낮고 APY와 함께 명시적으로 나열됨",
      "시장에서 결정 - 항상 변동",
      "코드가 종종 오픈 소스인 분산되고 투명한 플랫폼",
      "높음 - 프로세스를 완료하려면 DeFi에 대한 약간의 지식이 필요함",
      "해킹",
      "위에서 다룬 세 가지 플랫폼은 중앙화된 금융(CeFi)에 참여하는 중앙화된 플랫폼입니다. 다른 옵션은 대출자와 차용자를 위한 암호화폐 머니 마켓 역할을 하는 분산형 금융(DeFi) ETH 대출 프로토콜입니다.\n",
      "DeFi 플랫폼은 실시간 대출 및 차용 금리를 결정하는 코드를 사용하여 자동화된 시장을 유지합니다. 결과적으로 DeFi의 ETH 대출 금리는 공급과 수요에 따라 변동합니다.",
      "DeFi의 장점은 플랫폼 수수료가 훨씬 낮다는 것입니다. 그리고 DeFi 프로토콜은 일반적으로 오픈 소스 코드를 사용하고 토큰 보유자 커뮤니티에서 관리하기 때문에 DeFi 프로토콜은 CeFi 대출자보다 훨씬 더 투명합니다.",
      "DeFi 대출은 수익성이 있을 수 있습니다. 그러나 종종 더 복잡하기 때문에 일반적으로 숙련된 트레이더에게 더 적합합니다.",
      "질문: 채굴자는 ETH를 어디에서 얻습니까?",
      "답변: 채굴자는 유동성 채굴을 통해 ETH를 얻었습니다.",
      "DeFi 유동성 채굴은 사용자가 보상을 대가로 분산형 금융(DeFi) 플랫폼에 유동성을 제공하는 프로세스입니다. 더 잘 이해하기 위해 이 기사를 자세히 살펴보겠습니다.",
      "DeFi 유동성 마이닝이란?",
      "DeFi 유동성 마이닝은 사용자가 보상을 받는 대가로 분산형 금융(DeFi) 플랫폼에 유동성을 제공하는 프로세스입니다. 유동성 공급자(LP)는 풀에서 발생하는 거래와 DeFi 플랫폼 자체의 토큰에 대한 수수료를 얻을 수 있습니다.\n",
      "DeFi 유동성 마이닝은 어떻게 작동합니까?",
      "DeFi 유동성 마이닝은 자동화된 시장 조작(AMM)이라는 프로세스를 사용하여 작동합니다. AMM은 다양한 토큰에 대한 유동성 풀을 만드는 스마트 계약입니다. LP는 토큰을 유동성 풀에 추가하고 풀에서 해당 시간 동안 발생하는 거래에 대한 수수료를 얻을 수 있습니다.\n",
      "수수료는 풀에서 거래되는 토큰으로 지불됩니다. 예를 들어 ETH와 USDC를 유동성 풀에 추가하면 ETH와 USDC로 수수료를 얻게 됩니다. 또한 DeFi 플랫폼 자체의 토큰도 얻게 됩니다. 이러한 토큰은 종종 거버넌스 토큰이라고 하며, 플랫폼에서 투표권을 제공합니다.\n",
      "DeFi 유동성 채굴의 이점은 무엇입니까?",
      "DeFi 유동성 채굴에 참여하는 데는 여러 가지 이점이 있습니다. 첫째, DeFi 플랫폼에 유동성을 제공하여 수동 소득을 얻을 수 있습니다. 둘째, DeFi 플랫폼 자체에서 토큰을 얻을 수 있으며, 이는 가치가 상승할 가능성이 있습니다. 셋째, DeFi 플랫폼을 더 유동적으로 만드는 데 도움을 줄 수 있으며, 이는 플랫폼의 모든 사용자에게 이익이 될 수 있습니다.\n",
      "DeFi 유동성 채굴은 안전합니까?",
      "DeFi 유동성 채굴에는 위험이 없는 것은 아니지만, 이러한 위험을 감수할 의향이 있다면 수동 소득을 얻는 수익성 있는 방법이 될 수 있습니다. DeFi 유동성 채굴 프로젝트에 참여하기 전에 조사를 하고 손실을 감당할 수 있는 금액만 투자하는 것이 중요합니다.\n",
      "DeFi 유동성 채굴이란 무엇입니까? 어떻게 작동합니까? - 이 기사가 유익했기를 바랍니다.",
    ],
  },
  check: {
    title: "일일 로그인",
    rules: "규칙",
    point: "포인트 축적",
    signin: "로그인",
    days: "${consecutiveDays} 연속으로 로그인하셨습니다.",
    reSigning: "재로그인",
    day10: "10일",
    day90: "90일",
    rewards: "보상",
    day10Rewards: "연속 10일 로그인 보상",
    getIt: "받기",
    exchange: "교환",
    exchangeSub: "포인트 교환",
    points: "포인트",
    share: "공유 과제",
    shareSub: "공유 과제 보상",
    rank: "포인트 랭킹 리스트",
  },
};
