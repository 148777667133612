<template>
  <div style="display: flex; justify-content: center; padding: 10px">
    <van-popover
      trigger="click"
      :actions="actions"
      theme="dark"
      :placement="placement"
      v-model="showPopover"
    >
      <template #reference>
        <div class="kline-lang">
          <div class="kline-left">
            <div>{{ action.name }}</div>
          </div>
          <div>
            <van-icon name="play" class="arrow" />
          </div>
        </div>
      </template>
      <ul>
        <li
          v-for="(item, index) in actions"
          :key="index"
          style="padding-top: 5px; padding-bottom: 5px"
          @click="onSelect(item)"
        >
          <van-row>
            <van-col span="6">
              <span
                :class="[
                  'iconfont',
                  action.key === item.key
                    ? 'icon-xuanzhong choosed'
                    : 'icon-weixuanzhong unchoosed',
                ]"
              ></span>
            </van-col>
            <van-col
              :class="action.key === item.key ? 'choosed' : 'unchoosed'"
              span="18"
            >
              {{ item.name }}
            </van-col>
          </van-row>
        </li>
      </ul>
    </van-popover>
  </div>
</template>

<script>
export default {
  name: "LangSelect",
  components: {},
  props: {
    placement: {
      default: "bottom",
      type: String,
    },
  },
  data() {
    return {
      showPopover: false,
      lang: "",
      action: { key: localStorage.getItem("lang") || "en", name: "" },
      actions: Object.keys(this.$i18n.messages).map((key) => {
        return {
          key: key,
          name: this.$i18n.messages[key].langName || key,
        };
      }),
    };
  },
  mounted() {
    this.initLang();
  },
  methods: {
    onSelect(item) {
      this.action = item;
      this.$i18n.locale = item.key;
      localStorage.setItem("lang", item.key);
      this.showPopover = false;
      window.location.reload();
    },
    initLang() {
      for (let i = 0; i < this.actions.length; i++) {
        if (this.actions[i].key === this.$i18n.locale) {
          this.action = this.actions[i];
          break;
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
body,
html {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  user-select: none;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
  visibility: hidden;
  height: 0;
}

button,
input,
select,
textarea {
  outline: none;
}

.back-enter-active,
.back-leave-active,
.forward-enter-active,
.forward-leave-active {
  will-change: transform;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  position: absolute;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.back-enter {
  opacity: 0.75;
  -webkit-transform: translate3d(-100%, 0, 0) !important;
  transform: translate3d(-100%, 0, 0) !important;
}

.back-enter-active {
  z-index: -1 !important;
}

.back-enter-active,
.back-leave-active {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.back-leave-active,
.forward-enter {
  -webkit-transform: translate3d(100%, 0, 0) !important;
  transform: translate3d(100%, 0, 0) !important;
}

.forward-enter-active,
.forward-leave-active {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.forward-leave-active {
  z-index: -1;
  opacity: 0.65;
  -webkit-transform: translate3d(-100%, 0, 0) !important;
  transform: translate3d(-100%, 0, 0) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-transition-delay: 99999s;
  -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}

.van-empty .van-empty__image {
  width: 1.86667rem;
  height: 1.86667rem;
}

.van-empty .van-empty__image img {
  height: auto;
}

.van-nav-bar {
  background: #181e24;
  color: #fff;
  padding-top: 0.26667rem;
  padding-left: 0.26667rem;
}

.van-nav-bar img {
  width: 0.45333rem;
}

.van-hairline--bottom:after {
  border: none;
}

.van-nav-bar-left-area {
  padding-top: 0.26667rem;
  padding-bottom: 0.26667rem;
  padding-right: 0.26667rem;
}

.van-nav-bar--fixed + div,
.van-nav-bar--fixed + form {
  margin-top: 1.46667rem;
}

.van-toast--fail,
.van-toast--success {
  width: 80% !important;
}

.flex-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.flex-between,
.flex-start {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.flex-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.flex-center,
.flex-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.kline-lang {
  width: 2.93333rem;
  border: 0.08rem solid #6f7989;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border-radius: 0.4rem;
  padding: 0 0.26667rem;
  color: #ffc900;
  padding: 5px;
}

.kline-lang,
.kline-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.kline-left {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 2.66667rem;
}

.arrow {
  margin-left: 0.08rem;
  color: #6f7989;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.van-row {
  width: 3.2rem;
}

ul {
  padding: 0.26667rem 0.13333rem 0.13333rem 0.26667rem;
}

.choosed {
  color: #ffc900;
}

.unchoosed {
  color: #fff;
}
</style>
