import axios from "axios";
import { Notify } from "vant";
import router from "@/router";
import store from "@/store";
import address from "@/utils/address";

// 创建axios实例
const axiosInstance = axios.create({
  //baseURL: "/api",
  baseURL: "https://api.wwapius.com",
  timeout: 5000,
  /*httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),*/
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = store.getters["user/token"] || "";
    config.headers = {
      ...config.headers,
      Authorization: accessToken,
      address: address.getAddress(),
      lang: localStorage.getItem("lang") || "en"
    };
    //console.log("config", config);
    return config;
  },
  (error) => {
    console.log(error, "err");
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (data.code == 401) {
       localStorage.removeItem("token");
    }
    if (data instanceof ArrayBuffer || data.code !== -999999) {
      return data;
    } else {
      Notify({ type: "warning", message: data.msg });
      store.dispatch("user/logout");
      router.push("/").catch(() => {});
      return Promise.reject(new Error(data.msg || "Error"));
    }
  },
  (error) => {
    if (error.message.includes("timeout")) {
      Notify({
        type: "warning",
        message: "Connection timed out, please try again",
      });
    } else {
      Notify({
        type: "warning",
        message: "The system is busy, please try again later!",
      });
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
