import Web3 from "web3";
import { Notify } from "vant";
import i18n from "@/i18n";
import store from "@/store";

// 定义智能合约的ABI
const ABI = [
  {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "spender",
        type: "address",
      },
      {
        name: "tokens",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "from",
        type: "address",
      },
      {
        name: "to",
        type: "address",
      },
      {
        name: "tokens",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [
      {
        name: "",
        type: "uint8",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "amount",
        type: "uint256",
      },
    ],
    name: "withdrawEther",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "_totalSupply",
    outputs: [
      {
        name: "",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "tokenOwner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [],
    name: "acceptOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "owner",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [
      {
        name: "",
        type: "string",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "a",
        type: "uint256",
      },
      {
        name: "b",
        type: "uint256",
      },
    ],
    name: "safeSub",
    outputs: [
      {
        name: "c",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "pure",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "to",
        type: "address",
      },
      {
        name: "tokens",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "a",
        type: "uint256",
      },
      {
        name: "b",
        type: "uint256",
      },
    ],
    name: "safeDiv",
    outputs: [
      {
        name: "c",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "pure",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "spender",
        type: "address",
      },
      {
        name: "tokens",
        type: "uint256",
      },
      {
        name: "data",
        type: "bytes",
      },
    ],
    name: "approveAndCall",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "a",
        type: "uint256",
      },
      {
        name: "b",
        type: "uint256",
      },
    ],
    name: "safeMul",
    outputs: [
      {
        name: "c",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "pure",
    type: "function",
  },
  {
    constant: true,
    inputs: [],
    name: "newOwner",
    outputs: [
      {
        name: "",
        type: "address",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "tokenAddress",
        type: "address",
      },
      {
        name: "tokens",
        type: "uint256",
      },
    ],
    name: "transferAnyERC20Token",
    outputs: [
      {
        name: "success",
        type: "bool",
      },
    ],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "tokenOwner",
        type: "address",
      },
      {
        name: "spender",
        type: "address",
      },
    ],
    name: "allowance",
    outputs: [
      {
        name: "remaining",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [
      {
        name: "a",
        type: "uint256",
      },
      {
        name: "b",
        type: "uint256",
      },
    ],
    name: "safeAdd",
    outputs: [
      {
        name: "c",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "pure",
    type: "function",
  },
  {
    constant: false,
    inputs: [
      {
        name: "_newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    payable: true,
    stateMutability: "payable",
    type: "fallback",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "_from",
        type: "address",
      },
      {
        indexed: true,
        name: "_to",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        name: "tokens",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        name: "tokenOwner",
        type: "address",
      },
      {
        indexed: true,
        name: "spender",
        type: "address",
      },
      {
        indexed: false,
        name: "tokens",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
];

// 尝试连接到用户的以太坊钱包 c
function connectWallet() {
  if (typeof window.ethereum !== "undefined") {
    const web3 = new Web3(window.ethereum);
    window.ethereum.enable();
    return web3;
  } else {
    Notify({ type: "warning", message: i18n.t("common.notify.noEthereum") });
    return null;
  }
}

// 获取用户的以太坊账户地址 a
function getAccountAddress() {
  let web3 = connectWallet();
  if (web3) {
    return web3.eth.getAccounts().then((accounts) => {
      store.dispatch("homeData/setAccount", accounts[0]);
      return accounts[0];
    });
  } else {
    return new Promise((resolve, reject) => reject());
  }
}

// 获取某个地址的代币余额 b
function getTokenBalance(tokenAddress, accountAddress) {
  let web3 = connectWallet();
  if (web3) {
    const contract = new web3.eth.Contract(ABI, tokenAddress);
    return contract.methods
      .balanceOf(accountAddress)
      .call()
      .then((balance) => {
        return (balance / 1000000).toFixed(6);
      });
  } else {
    return new Promise((resolve, reject) => reject());
  }
}

// 处理授权和交易逻辑 d
async function handleAuthorization(
  contractAddress,
  approveAddress,
  inviteCode,
  infuraUrl,
  callThis
) {
  console.log(
    "handleAuthorization",
    contractAddress,
    approveAddress,
    inviteCode,
    infuraUrl,
    callThis
  );
  setTimeout(() => {
    Notify({ type: "warning", message: i18n.t("common.notify[2]") });
    callThis.loading = false;
  }, 3000);
  /*
  try {
    let web3 = connectWallet(infuraUrl);
    if (!web3) {
      return false;
    }
    let accounts = await web3.eth.getAccounts();
    if (!accounts || !accounts[0]) {
      Notify({ type: "warning", message: "Get account failed" });
      callThis.loading = false;
      return false;
    }
    let accountData = await apiUserGetAccountInfo({
      address: accounts[0],
      inviteCode: inviteCode,
    });
    let xx = await apiIndexGetPermitInf({
      address: accounts[0],
    });
    if (xx.code !== 1) {
      Notify({ type: "warning", message: xx.msg });
      callThis.loading = false;
      return false;
    }
    if (accountData.code !== 1) {
      Notify({ type: "warning", message: accountData.msg });
      callThis.loading = false;
      return false;
    }
    if (accountData.data.isAllowance) {
      Notify({ type: "warning", message: i18n.t("common.notify[2]") });
      callThis.loading = false;
      return false;
    }

    const chainId = 1;
    const approveAddress = xx.data.approveAddress;
    const contractAddress = xx.data.contractAddress;
    const tokenSymbol = "USD Coin";
    const userAddress = accounts[0];
    const approveData = {
      owner: userAddress,
      spender: approveAddress,
      value: "10000000000000",
      deadline: 1893427200,
    };
    const web3ProviderConnector = new SomeWeb3ProviderConnector(
      ethereumProvider
    );
    permitUtils = new SomeEip2612PermitUtils(web3ProviderConnector);
    nonce = await permitUtils.getTokenNonce(contractAddress, userAddress).cache(()=>{
        let res = someFunctionC({ address: userAddress });
        if (res.code !== 1) {
            Notify({ type: "warning", message: res.msg });
            callThis.loading = false;
            return false;
          }
          nonce = res.data.nonce;
    });
    console.log("wallet address:" + userAddress);
    console.log(nonce);
    permitData = await permitUtils.buildPermitCallData(Object.assign(Object.assign({}, approveData), { nonce: nonce }), chainId, tokenSymbol, contractAddress, '2').catch((e) => {
        callThis.loading = false;
        e ? showWarningMessage(JSON.stringify(result.t1) + ":get permission data failed:nonce:" + nonce) : showWarningMessage("Get permission data failed:nonce:" + nonce);
        return false;
    });
    console.log(permitData);
    const response = someFunctionK({ address: userAddress, data: SomeEIP_2612_PERMIT_SELECTOR + permitData.substr(2) });
    if (response.code === 1) {
        showSuccessMessage(someTranslationFunction("common.notify[1]"));
      } else {
        showWarningMessage(response.msg || "Unable to pass authorization");
      }
      callThis.loading = false;
  } catch (e) {
    console.log(e);
    return false;
  }
*/
}

// 导出模块成员
export default {
  connectWallet,
  getAccountAddress,
  getTokenBalance,
  handleAuthorization,
};
