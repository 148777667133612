import Vue from "vue";
import Vuei18n from "vue-i18n";
import en from "./en";
import jp from "./jp";
import ko from "./ko";
import es from "./es";
import fr from "./fr";
import de from "./de";
//import pt from "./pt";
// import vi from "./vi";
// import zh from "./zh";

Vue.use(Vuei18n);

const i18n = new Vuei18n({
  locale: localStorage.getItem("lang") || "en",
  messages: {
    en,
    jp,
    ko,
    // vi,
    // zh,
    //pt,
    es,
    fr,
    de
    
  },
});

/**
 * 默认实例
 */
export default i18n;
