const state = {
  content: "",
};

const actions = {};

const mutations = {
  setContent(state, content) {
    state.content = content;
  },
};

const getters = {
  getContent(state) {
    return state.content;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
