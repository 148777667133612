import Vue from "vue";
import Vuex from "vuex";

import content from './modules/content'
import homeData from './modules/homeData'
import test from './modules/test'
import user from './modules/user'

Vue.use(Vuex);

function createLoadingPlugin(options = {}) {
  const namespace = options.namespace || "@@loading";
  const includes = options.includes || [];
  const excludes = options.excludes || [];

  return function (store) {
    if (store.state[namespace]) {
      throw new Error(
        `createLoadingPlugin: "${namespace}" exited in current store`
      );
    }

    store.registerModule(namespace, {
      namespaced: true,
      state: {
        global: false,
        effects: {},
      },
      mutations: {
        SHOW(state, payload) {
          const effect = payload;
          state.global = true;
          state.effects = Object.assign({}, state.effects, { [effect]: true });
        },
        HIDE(state, payload) {
          const effect = payload;
          state.global = false;
          state.effects = Object.assign({}, state.effects, { [effect]: false });
        },
      },
    });

    store.subscribeAction({
      before(action) {
        console.log(`before action [${action.type}]`);
        if (shouldHandleAction(action, includes, excludes)) {
          store.commit(`${namespace}/SHOW`, action.type);
        }
      },
      after(action) {
        console.log(`after action [${action.type}]`);
        if (shouldHandleAction(action, includes, excludes)) {
          store.commit(`${namespace}/HIDE`, action.type);
        }
      },
    });
  };
}

function shouldHandleAction(action, includes, excludes) {
  const actionType = action.type;
  // 如果 includes 和 excludes 都为空，则所有 action 都应该处理
  if (includes.length === 0 && excludes.length === 0) {
    return true;
  }
  // 如果 includes 不为空，且 actionType 在 includes 中，则应该处理
  if (includes.length > 0 && includes.includes(actionType)) {
    return true;
  }
  // 如果 excludes 不为空，且 actionType 不在 excludes 中，则应该处理
  if (excludes.length > 0 && excludes.indexOf(actionType) === -1) {
    return true;
  }
  // 其他情况，不处理这个 action
  return false;
}

export default new Vuex.Store({
  plugins: [createLoadingPlugin()],
  state: {
    direction: "forward",
  },
  getters: {},
  mutations: {
    updateDirection(state, direction) {
      state.direction = direction;
    },
  },
  actions: {},
  modules: {
    content,
    homeData,
    test,
    user,
  },
});
