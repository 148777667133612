import { Notify } from "vant";
import { apiIndexIndex, apiNftHome, apiUserGetAccountInfo } from "@/api/v1";
import address from "@/utils/address";

const state = {
  homeData: {
    miningRule: {},
    pawnRule: {},
    miningIncome: [],
    helper: [],
  },
  account: "",
  nftData: {
    stats: {
      nftNFTNumber: 0,
      nftNumOfBeneficiaries: 0,
      nftTradingNumber: 0,
      nftTradingVolume: 0,
    },
    tagList: [],
  },
  myAccount: {
    totalMiningEth: "0",
    usdt: "0",
    eth: "0",
    exchangeLog: [],
    withdrawLog: [],
    miningLog: [],
    inviteCode: "",
    rebateLog: [],
    overdueTax: {},
    experience: {},
  },
};

const mutations = {
  SetHomeData(state, homeData) {
    state.homeData = homeData;
  },
  SetAccountData(state, account) {
    state.account = account;
    address.setAddress(account);
  },
  SetNftHomeData(state, nftData) {
    state.nftData = nftData;
  },
  SetMyData(state, myAccount) {
    state.myAccount = myAccount;
  },
};

const actions = {
  getHomeData({ commit }) {
    apiIndexIndex()
      .then((response) => {
        if (response.code === 0) {
          commit("SetHomeData", response.data);
        } else {
          Notify({
            message: response.msg,
            position: "middle",
            duration: 1500,
          });
        }
      })
      .catch((error) => {
        Notify({
          message: error.message,
          position: "middle",
          duration: 1500,
        });
      });
  },
  getNftHomeData({ commit }) {
    apiNftHome()
      .then((response) => {
        if (response.code === 0) {
          commit("SetNftHomeData", response.data);
        } else {
          Notify({
            message: response.msg,
            position: "middle",
            duration: 1500,
          });
        }
      })
      .catch((error) => {
        Notify({
          message: error.message,
          position: "middle",
          duration: 1500,
        });
      });
  },
  setAccount({ commit }, account) {
    if (account) {
      commit("SetAccountData", account);
    }
  },
  getMyAccount({ commit, state }, account) {
    if (account) {
      
      commit("SetAccountData", account.address);
    } else {
      account = {
        address: state.account,
      };
    }
    apiUserGetAccountInfo(account)
      .then((response) => {
        if (response.code === 0) {
          if (!response.data.status) {
             localStorage.removeItem("token");
          }
          commit("SetMyData", response.data);
        } else {
          Notify({
            message: response.msg,
            position: "middle",
            duration: 1500,
          });
        }
      })
      .catch((error) => {
        Notify({
          message: error.message,
          position: "middle",
          duration: 1500,
        });
      });
  },
};

const getters = {
  homeData: (state) => state.homeData,
  myAccount: (state) => state.myAccount,
  account: (state) => state.account,
  nftData: (state) => state.nftData,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
