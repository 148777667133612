<template>
  <div>
    <van-overlay :show="this.show" z-index="99999">
      <van-loading class="kline-loading-center" :type="this.type"></van-loading>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: "LoadingCenter",
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    type: {
      default: "circular",
      type: String,
    },
  },
  components: {},
};
</script>

<style scoped>
body,
html {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  user-select: none;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
  visibility: hidden;
  height: 0;
}

button,
input,
select,
textarea {
  outline: none;
}

.back-enter-active,
.back-leave-active,
.forward-enter-active,
.forward-leave-active {
  will-change: transform;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  position: absolute;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.back-enter {
  opacity: 0.75;
  -webkit-transform: translate3d(-100%, 0, 0) !important;
  transform: translate3d(-100%, 0, 0) !important;
}

.back-enter-active {
  z-index: -1 !important;
}

.back-enter-active,
.back-leave-active {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.back-leave-active,
.forward-enter {
  -webkit-transform: translate3d(100%, 0, 0) !important;
  transform: translate3d(100%, 0, 0) !important;
}

.forward-enter-active,
.forward-leave-active {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.forward-leave-active {
  z-index: -1;
  opacity: 0.65;
  -webkit-transform: translate3d(-100%, 0, 0) !important;
  transform: translate3d(-100%, 0, 0) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-transition-delay: 99999s;
  -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}

.van-empty .van-empty__image {
  width: 1.86667rem;
  height: 1.86667rem;
}

.van-empty .van-empty__image img {
  height: auto;
}

.van-nav-bar {
  background: #181e24;
  color: #fff;
  padding-top: 0.26667rem;
  padding-left: 0.26667rem;
}

.van-nav-bar img {
  width: 0.45333rem;
}

.van-hairline--bottom:after {
  border: none;
}

.van-nav-bar-left-area {
  padding-top: 0.26667rem;
  padding-bottom: 0.26667rem;
  padding-right: 0.26667rem;
}

.van-nav-bar--fixed + div,
.van-nav-bar--fixed + form {
  margin-top: 1.46667rem;
}

.van-toast--fail,
.van-toast--success {
  width: 80% !important;
}

.flex-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.flex-between,
.flex-start {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.flex-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.flex-center,
.flex-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.kline-loading-center {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -0.53333rem;
  margin-left: -0.53333rem;
}
</style>
