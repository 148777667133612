const state = {
  number: 1,
};

const actions = {
  onePlusAsync({ commit }, { val }) {
    return new Promise((resolve, reject) => {
      console.log("reject", reject);
      setTimeout(() => {
        commit("onePlus", val);
        resolve();
      }, 1500);
    });
  },
};

const mutations = {
  onePlus(state, payload = 1) {
    state.number += payload;
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
