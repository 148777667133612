import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import Vant from "vant";
import "vant/lib/index.css";
import SvgIcon from "./components/SvgIcon.vue";
import Clipboard from "v-clipboard";

Vue.use(Clipboard);

Vue.use(Vant);
Vue.component("svg-icon", SvgIcon);

Vue.use({
  install: function (_vue) {
    _vue.directive("preventReClick", {
      inserted: function (el, binding) {
        el.addEventListener("click", () => {
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(() => {
              el.disabled = false;
            }, binding["value"] || 2000);
          }
        });
      },
    });
  },
});

// 初始化Store
store.dispatch("homeData/getHomeData");
store.dispatch("homeData/getNftHomeData");

function gmtFormat(time) {

  let date = new Date(time*1000);  
  let year = date.getFullYear();  

  let month = date.getMonth() + 1; 
  let day = date.getDate();  
  let hours = date.getHours();  
  let minutes = date.getMinutes();  
  let seconds = date.getSeconds(); 

  month = month < 10 ? '0' + month : month;  
  day = day < 10 ? '0' + day : day;  
  hours = hours < 10 ? '0' + hours : hours;  
  minutes = minutes < 10 ? '0' + minutes : minutes;  
  seconds = seconds < 10 ? '0' + seconds : seconds;  

  return year + '-' + month + '-' + day +' ' + hours + ':' + minutes + ':' + seconds;

}

function getChange(data, symbolCode) {
  for (var i = 0; i < data.length; i++) {
    if (data[i]["symbolCode"] === symbolCode) {
      return data[i]["change"];
    }
  }
}

function getPrice(data, symbolCode) {
  for (var i = 0; i < data.length; i++) {
    if (data[i]["symbolCode"] === symbolCode) {
      return data[i]["close"];
    }
  }
}

function moneyWithUnit(num) {
  //console.log("moneyWithUnit", num);
  /*
  const locale = i18n.locale;
  const units = {
    "zh-cn": "亿",
    "en-us": "B",
  };
  */
  num = parseFloat(num);
  if (num > 100000000) {
    return (num / 100000000).toFixed(2) + "B";
  } else if (num <= 100000000 && num > 1000000) {
    return (num / 1000000).toFixed(2) + "M";
  } else {
    return num.toFixed(2);
  }
}

Vue.filter("gmtFormat", gmtFormat);
Vue.filter("getChange", getChange);
Vue.filter("getPrice", getPrice);
Vue.filter("moneyWithUnit", moneyWithUnit);

Vue.prototype.getChange = getChange;
Vue.prototype.gmtFormat = gmtFormat;
Vue.prototype.getPrice = getPrice;
Vue.prototype.moneyWithUnit = moneyWithUnit;
Vue.prototype.$baseApi = "/api";
Vue.prototype.getFullUrl = function (url) {
  if (typeof url != "string") {
    return url;
  } else {
    return url.startsWith("http") ? url : window.location.origin + "/api" + url;
  }
};
Vue.prototype.historyBack = function () {
  router.back();
};
Vue.prototype.toEle = function (id) {
  var elem = document.getElementById(id);
  elem.scrollIntoView();
};
Vue.prototype.unique = function (data) {
  if (!Array.isArray(data)) {
    return [];
  }
  let result = [];
  let cache = [];
  for (let i = 0; i < data.length; i++) {
    if (-1 === cache.indexOf(data[i]["id"])) {
      result.push(data[i]);
      cache.push(data[i]["id"]);
    }
  }
  return result;
};

//
Vue.config.productionTip = false;
Vue.config.devtools = true;

Date.prototype.format = function (format) {
  var date = {
    "M+": this.getMonth() + 1,
    "d+": this.getDate(),
    "h+": this.getHours(),
    "m+": this.getMinutes(),
    "s+": this.getSeconds(),
    "q+": Math.floor((this.getMonth() + 3) / 3),
    "S+": this.getMilliseconds(),
  };

  // 处理年份
  if (/(y+)/i.test(format)) {
    format = format.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }

  // 替换其他日期部分
  for (var k in date) {
    if (new RegExp("(" + k + ")").test(format)) {
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length == 1
          ? date[k]
          : ("00" + date[k]).substr(("" + date[k]).length)
      );
    }
  }

  return format;
};

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
